import {ApiInterfaces} from "../Interfaces";
import canvg from "canvg";
import {CaptisPayload, StorageIoTData, StorageIoTReport} from "../Components/DAS/modal";
import {GraphBuilder} from "../Components/Graphs/Default";

export namespace HelperFunctions {


    export function getAllPropertyNames(obj: any): string[] {
        const propertyNames: string[] = [];

        function traverseObject(obj: any) {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    propertyNames.push(key);
                    if (typeof obj[key] === 'object') {
                        traverseObject(obj[key]);
                    }
                }
            }
        }

        traverseObject(obj);

        return propertyNames;
    }


    type sortArg<T> = keyof T | `-${string & keyof T}`

    /**
     * Returns a comparator for objects of type T that can be used by sort
     * functions, were T objects are compared by the specified T properties.
     *
     * @param sortBy - the names of the properties to sort by, in precedence order.
     *                 Prefix any name with `-` to sort it in descending order.
     */
    export function byPropertiesOf<T extends object>(sortBy: Array<sortArg<T>>) {
        function compareByProperty(arg: sortArg<T>) {
            let key: keyof T
            let sortOrder = 1
            if (typeof arg === 'string' && arg.startsWith('-')) {
                sortOrder = -1
                // Typescript is not yet smart enough to infer that substring is keyof T
                key = arg.substr(1) as keyof T
            } else {
                // Likewise it is not yet smart enough to infer that arg is not keyof T
                key = arg as keyof T
            }
            return function (a: T, b: T) {
                const result = a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0

                return result * sortOrder
            }
        }

        return function (obj1: T, obj2: T) {
            let i = 0
            let result = 0
            const numberOfProperties = sortBy?.length
            while (result === 0 && i < numberOfProperties) {
                result = compareByProperty(sortBy[i])(obj1, obj2)
                i++
            }

            return result
        }
    }

    export function sort<T extends object>(arr: T[], ...sortBy: Array<sortArg<T>>) {
        return arr.sort(byPropertiesOf<T>(sortBy))
    }


    export function groupBy<T>(arr: T[], property: string): groupBy<T> {
        return arr.reduce(function (memo: any, x: any) {
            if (!memo[x[property]]) memo[x[property]] = []
            memo[x[property]].push(x);
            return memo;
        }, {});
    }


    export interface groupBy<T> {
        [key: string]: T[];
    }


    export function hasOwnProperty<T, K extends PropertyKey>(obj: T, prop: K): obj is T & Record<K, unknown> {
        return Object.prototype.hasOwnProperty.call(obj, prop);
    }

    export const isMobile = () => {
        return window.innerWidth <= 900
    }


    export function KeyMapGenerator<T extends Object>(data: T[], index: PropertyKey, replace: boolean, ...include: PropertyKey[]) {
        const options: { [key: string]: string } = {};
        Object.values(data).map(t => {
            if (hasOwnProperty(t, index)) {
                let value = t[index];
                let supp = '';
                if (include.length > 0) {
                    Object.values(include).forEach(x => {
                        if (hasOwnProperty(t, x)) {
                            const add = t[x] as string;
                            supp = `${add?.length > 0 ? supp + " " + add : ""}`
                        }
                    })
                }

                const k = t[index] as string;
                options[k] = replace ? `${supp.length > 0 ? supp : value}` : `${value} - ${supp}`
            }
        })
        return options
    }

    export function OptionKeyMapGenerator<T extends Object>(
        data: T[],
        idProperty: keyof T,
        nameProperty: keyof T,
        replace: boolean,
        ...include: (keyof T)[]
    ) {
        const options: { [key: string]: string } = {};
        data.forEach(t => {
            if (t.hasOwnProperty(idProperty) && t.hasOwnProperty(nameProperty)) {
                const id = t[idProperty] as unknown as string;
                const name = t[nameProperty] as unknown as string;
                let additionalInfo = '';

                include.forEach(x => {
                    if (t.hasOwnProperty(x)) {
                        const add = t[x] as unknown as string;
                        additionalInfo += add ? ` ${add}` : '';
                    }
                });

                const displayText = replace ? additionalInfo.trim() : `${name}${additionalInfo ? ` - ${additionalInfo.trim()}` : ''}`;
                options[id] = displayText;
            }
        });

        return options;
    }


    export const MetadataOptions = (input: ApiInterfaces.metadata): { [key: string]: string } => {
        const result: { [key: string]: string } = {};
        for (const key in input) if (input.hasOwnProperty(key)) result[key] = input[key].name;
        return result;
    };

    export const GraphMetadataOptions = (metadataArray: GraphBuilder.Metadata[]): { [key: string]: string } => {
        const result: { [key: string]: string } = {};
        metadataArray.forEach(metadata => {
            result[metadata.key] = metadata.key;
        });
        return result;
    }

    export function FilterObjectByValue<T>(data: T[], index: PropertyKey, filter: string | number | null | undefined) {
        return data.filter(o => {
            if (hasOwnProperty(o, index)) {
                return o[index] == filter
            }
        })

    }

    export const findCompanyNameById = (companyId: string, companies: ApiInterfaces.Company[]): string | null => {
        const company = companies.find((company) => company.id === companyId);
        return company ? company.name_Cia : null;
    };

    export const transformCompaniesToOptions = (companies: ApiInterfaces.Company[]): { [key: string]: string } => {
        return companies.reduce((result, company) => {
            const {id, name_Cia} = company;
            result[id] = name_Cia;
            return result;
        }, {} as { [key: string]: string });
    };

    export const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;

    export function isValidPassword(password: string) {
        return passwordRegex.test(password);
    }

    export function isNullOrUndefined<T>(value: T) {
        return value === undefined || value === null;
    }

    export function fixedStringLength(x: string, n: number): string {
        return x.slice(0, n).padEnd(n, '\u00A0');
    }

    export const round = (n: number, digits?: number) => Math.round((n + Number.EPSILON) * Math.pow(10, digits || 3)) / Math.pow(10, digits || 3)

    export const svgToPng = async (src: string) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) return ""
        const v = await canvg.from(ctx, src);
        await v.render()
        return canvas.toDataURL('image/png', 1);
    }

    export async function blobToBase64(blob: Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    const base64String = reader.result.split(',')[1];
                    resolve(base64String);
                } else {
                    reject(new Error('Failed to read blob as base64.'));
                }
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }


    export const objectMap = <T>(array: T[]): string[][] => {
        if (!Array.isArray(array) || array.length === 0 || typeof array[0] !== 'object') return [];
        const keys = Object.keys(array[0] || {}) as Array<keyof T>;
        return array.map(obj => keys.map(key => String(obj[key])));
    };


    export const StorageReportFunction = (objects: StorageIoTData<CaptisPayload>[]): StorageIoTReport[] => {
        return objects.filter(obj => 'distanceToWaterLevel' in obj.payload) as StorageIoTReport[];
    };

    export function devsFromTemplate(template: ApiInterfaces.Template): string[] {
        const deviceIds = new Set<string>();
        template.section.forEach(item => {
            'deviceId' in item
                ? deviceIds.add(item.deviceId)
                : item.rows.forEach(row => deviceIds.add(row.deviceId));
        });
        return Array.from(deviceIds);
    }

    export const getSummariesFromTemplate = (template: ApiInterfaces.Template): ApiInterfaces.Summary[] => {
        return template.section.filter(isSummary);
    };

    const isSummary = (section: ApiInterfaces.Summary | ApiInterfaces.DataReport): section is ApiInterfaces.Summary => {
        return (section as ApiInterfaces.Summary).rows !== undefined;
    };

    export function findDevById(devices: ApiInterfaces.Device[], deviceId: string): ApiInterfaces.Device | undefined {
        return devices.find(device => device.id === deviceId);
    }



}
import {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {IRedux} from "../../../Interfaces/redux";
import {ApiInterfaces} from "../../../Interfaces";
import {Button} from "react-bootstrap";
import {FileUpload} from 'primereact/fileupload';
import {Toast} from 'primereact/toast';
import {FileHelper} from "../../../Helper/FileHelper";
import LidStorage from "./LidStorage";
import { v4 as uuid } from 'uuid';
import {BindingHooks} from "../../../Server/Binding/bidings/BindingHook";

interface IProps extends IRedux.IAuth {
    handleEdit?: (changed: boolean) => void;
    device: Partial<ApiInterfaces.Device>;
}

function LidConfig(props: IProps): JSX.Element {
    const {device, accessToken, user} = props
    const toast = useRef<Toast>(null);
    const fileupload = useRef<FileUpload>(null);

    const { data, setData } =  BindingHooks.LidStorage(accessToken,device.id || "",user.companyId, 'lid')

    const onUpload = async (event: any) => {

        const file = event.files[0];
        const data = await FileHelper.readCSVFile(file)


        if(FileHelper.validateStorageCsvRows(data)) {
            const upload = FileHelper.csvToStorage(data, device)
            if(upload) {
                fileupload.current?.clear()
                toast.current?.show({severity: 'success', summary: 'Success', detail: 'File Uploaded, please confirm details on the form and save changes'});
                setData(state => ({...state,...upload, temp_id: uuid()}))

            }else toast.current?.show({severity: 'error', summary: 'Incorrect Device ID', detail: 'Please check device ID from CSV File'});
        }else {
            toast.current?.show({severity: 'error', summary: 'Wrong File Format', detail: 'Please use correct CSV Template for this action'});
        }
    };


    return (
        <>
            <Toast ref={toast} />
            <p className="fs-5 text-center font-weight-bold"> Configuration File  </p>
            <hr/>
            <div className="card">
                <FileUpload ref={fileupload} name="demo[]" customUpload accept=".csv" maxFileSize={5000000}
                            uploadHandler={onUpload}
                            emptyTemplate={<p className="m-0">Drag and drop Storage Configuration CSV to here to
                                upload.</p>}/>
            </div>
            <hr/>
            <div className="d-grid gap-2">
                <Button variant="outline-warning" onClick={() => FileHelper.StorageConfig(data || {}, device)}>
                    <i className="fas fa-download"></i> - {`Download ${data ? "": "Sample"} Configuration File`}
                </Button>
            </div>

            <hr/>

            <LidStorage device={device} storage={data || {}} key={data?.temp_id || data?.id}/>

        </>

    )
}

const mapStateToProps = (state: IRedux.MapState) => {
    const {user, isLoggedIn, accessToken, refreshToken, mqttToken} = state.auth;
    return {
        user,
        isLoggedIn,
        accessToken,
        refreshToken,
        mqttToken
    };
};

const connector = connect(mapStateToProps);

export default connector(LidConfig);
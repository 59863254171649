import { KeyMap } from "../../../Helper/Core/interface";
import { Events } from "../../Events/Events";
import { SubDataSet } from "../SubDataSet";
import * as Company from "../bidings/Company"

const type = 'device.list'
export type DataType = Events['device.list']['response']['data'][number];


export class Binding extends SubDataSet<DataType, Company.DataType> {
    public constructor(private skip:boolean, private eventSetData: Company.Binding, cb?: (data: KeyMap<DataType>) => void, parentSetFilter?: (item: Company.DataType) => boolean, awaitFirstLoad?: boolean) {
        super(eventSetData, false, i => i.id, i => i.id, cb, parentSetFilter, awaitFirstLoad);
    }
    protected async bindToBaseId(companyId: string, storeData: (data: DataType[] | undefined) => void) {
        const simRequest: Events['device.list']['response'] = {type: 'device.list', data: this.eventSetData.getData()[companyId].Devices || []}
        return await this.manager.bind('device.list', { companyId,  topic: `${companyId}/device` ,skipBinding: this.skip ? simRequest : undefined }, (res) => {
            if (res.type === type) {
                storeData(res.data);
            } else {
                storeData(undefined);
            }
        });
    }
}

import { ApiInterfaces } from "../Interfaces";
import { Chirpstack } from "../Interfaces/Lora";

export namespace LoraHelper {
    export function decodeBase64(base64String: string): string {
        return window.atob(base64String);
    }

    export const findLoraNodesByIds = (loraNodes: ApiInterfaces.LoraNode[], ids: string[]): ApiInterfaces.LoraNode[] => {
        const nodeMap: Map<string, ApiInterfaces.LoraNode> = new Map();

        // Create a map of LoraNode objects with IDs as keys for efficient lookup
        loraNodes.forEach((node) => {
            nodeMap.set(node.id, node);
        });

        const foundLoraNodes: ApiInterfaces.LoraNode[] = ids.map((id) => nodeMap.get(id)).filter(Boolean) as ApiInterfaces.LoraNode[];
        return foundLoraNodes;
    };






}

////**************DECODED HELPERS ******************* */



export namespace LoraDecodedHelper {


    export function AgmacTimer(data: string) {
        const metadata: Chirpstack.metadata = {}
        const numbers = data.match(/-?\d+/g);
        const Gate: Record<string, string> = {
            '1': 'Gate Error',
            '2': 'Open Starting',
            '4': 'Gate Opening',
            '8': 'Gate Open',
            '16': 'Close Starting',
            '32': 'Gate Closing',
            '64': 'Gate Closed',
            '128': 'Open Barrier Detected',
            '256': 'Close Barrier Detected'
        };
        if (numbers) {
            metadata['Battery'] = {
                name: 'Battery',
                unit: 'v',
                value: Number(numbers[5]) / 10
            }
            metadata['Solar'] = {
                name: 'Solar',
                unit: 'v',
                value: Number(numbers[6]) / 10
            }
            metadata['Charging'] = {
                name: 'Charging',
                unit: 'mA',
                value: numbers[7]
            }
            metadata['Signal'] = {
                name: 'Signal',
                unit: 'db',
                value: numbers[8]
            }
            metadata['Gate Status'] = {
                name: 'Gate Status',
                unit: '',
                value: Gate[numbers[4] as keyof typeof Gate] ?? 'Undefined'
            }

        }
        return metadata
    }
}
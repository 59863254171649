import {ApiInterfaces} from "../Interfaces";
import fakeStorage from "./Faker/Storage";
import {HelperFunctions} from "./functions";

export namespace FileHelper {

    export function downloadCsv(csv: string, filename: string): void {
        // Create a Blob object containing the CSV data
        const blob = new Blob([csv], {type: 'text/csv'});
        // Create a URL for the Blob object
        const url = window.URL.createObjectURL(blob);
        // Create a temporary anchor element
        const a = document.createElement('a');
        a.href = url;
        // Set the download attribute to specify the file name
        a.download = filename || 'data.csv';
        // Simulate a click event on the anchor element to trigger the download
        document.body.appendChild(a);
        a.click();
        // Clean up by revoking the URL to release memory
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    export function parseCsvString(csvString: string): string[][] {
        // eslint-disable-next-line eqeqeq
        return csvString.trim().split('\n').map(line => line.replace(/\r/g, '')).map(row => row.split(','));
    }

    export async function readCSVFile(file: File): Promise<string[][]> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (e: any) {
                const csvString = e.target.result;
                const csvData = parseCsvString(csvString);
                const filter = csvData.map(i => i.filter(value => value !== '')).filter(i => i.length > 0);
                resolve(filter);
            };
            reader.onerror = function (error) {
                reject(error);
            };
            reader.readAsText(file);
        });
    }

    export function StorageConfig(s: Partial<ApiInterfaces.LIDStorage>, device: Partial<ApiInterfaces.Device>) {
        const data = s.id ? s : fakeStorage
        const {id, deviceId, storageCurve, ...storage} = data
        const csv = [
            ['Device Name', 'Device Id',],
            [`${device.name}`, `${device.id}`],
            Object.keys(storage),
            Object.values(storage),
            Object.keys(storageCurve ? storageCurve[0] : []),
            ...HelperFunctions.objectMap(storageCurve || []) as any
        ];
        downloadCsv(csv.map(row => row.join(',')).join('\n'), `${device.name}-StorageData`)
    }

    function areTwoStringArraysEquals(str1: string[], str2: string[]) {
        return str1.toString().toLocaleLowerCase() === str2.toString().toLocaleLowerCase()
    }

    export function validateStorageCsvRows(csvData: string[][]) {
        const {id, deviceId, storageCurve, ...storage} = fakeStorage
        // Define the expected rows
        const header = [
            ['Device Name', 'Device Id'],
            Object.keys(storage),
            Object.keys(storageCurve ? storageCurve[0] : []),
        ];
        return areTwoStringArraysEquals(header[0], csvData[0])
            && areTwoStringArraysEquals(header[1], csvData[2])
            && areTwoStringArraysEquals(header[2], csvData[4])
    }

    export function csvToStorage(csvData: string[][], device: Partial<ApiInterfaces.Device>) {
        if(csvData[1][1] !== device.id) return undefined
        const storageRow = csvData[3]
        const curve = csvData.slice(5)

        const storageCurve: ApiInterfaces.LIDStorage['storageCurve'] = [];
        curve.forEach(c=> {
            storageCurve.push({
                elevation: Number(c[0]),
                area: Number(c[1]),
                volume: Number(c[2])
            })
        })

        return  {
            Area: Number(storageRow[0]),
            Volume: Number(storageRow[1]),
            Bottom_mHAD: Number(storageRow[2]),
            Top_mHAD: Number(storageRow[3]),
            Depth: Number(storageRow[4]),
            LOT: storageRow[5],
            ESID: storageRow[6],
            TopBank: Number(storageRow[7]),
            TopWaterHeight: Number(storageRow[8]),
            Walkway: Number(storageRow[9]),
            Height: Number(storageRow[10]),
            storageCurve

        } as ApiInterfaces.LIDStorage

    }

}
import React from 'react';

interface LoaderProps {
    loading: boolean;
}

const Loader: React.FC<LoaderProps> = ({ loading }) => {
    return (

        <div id="page" className={`h-100 fade show ${!loading ? 'd-none' : ''}`}   >
            <img id="loading-image" src="/logos/loading.gif" alt="Loading..."  style={{ width: '60px', height: '60px' }} /> <br />
        </div>

    );
};

export default Loader;
import {  REFRESH_TOKEN, LOGOUT, LOGIN_SUCCESS, LOGIN_FAIL, UPDATE_COMPANY_ID } from './actionInterface'
import { showError } from './alertsAction';
import AuthService from '../services/authService';
import {  useNavigate } from 'react-router-dom';

export const logout = () => (dispatch: any) => {
    dispatch({
        type: LOGOUT,
    });
};


export const refreshAuthTokens = (accessToken: string, refreshToken: string, mqttToken: string) => (dispatch: any) => {
    dispatch({
        type: REFRESH_TOKEN,
        payload: {
            accessToken: accessToken,
            refreshToken: refreshToken,
            mqttToken: mqttToken
        },
    })
}

export const updateCompanyId = (companyId: string) => {
    return {
        type: UPDATE_COMPANY_ID,
        payload: companyId,
    };
};


export const login = (username: string, password: string) => (dispatch: any) => {
    return AuthService.login(username, password).then(
        (data: any) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: data,
            });
            useNavigate();
            return Promise.resolve();
        },
        (error: any) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(showError(message + " - Credentials not found"))

            dispatch({
                type: LOGIN_FAIL,
            });

            return Promise.reject();
        }
    );
};
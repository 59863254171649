import React from 'react';

import { Input, Group, Row, Section, Collection, InputValue, getValueType, coerceToType, List, BaseFormAction, FormScaffold } from './Interface';
import * as C from './Control';
import * as G from './Group';
import * as R from './Row';
import * as C2 from './Collection';
import * as S from './Section';
import * as L from './List';

export interface StateControl {
  value: InputValue,
  valid: boolean,
  setValue: (value: InputValue) => void,
  setValid: (valid: boolean, message?: string) => void,
  setDisabled: (disabled: boolean) => void,
  setHidden: (hidden: boolean) => void,
  setColor: (color: string) => void,
  setLabel: (label: string) => void,
  setFeedbackMessage: (feedback: string) => void,
  setIsInvalid: (isInvalid: boolean) => void,
  setOptional: (optional: boolean) => void,
  setOptions: (options: { [key: string]: InputValue }) => void,
  setProcessing: (processing: boolean) => void,
}

export const isGroupType = (data: (Input | Group | Row | Section | List | Collection<Input | Group | Row | Section | List>)): data is (Group | Row | Section | List | Collection<Input | Group | Row | Section | List>) => {
  return data.type === 'row' || data.type === 'group' || data.type === 'section' || data.type === 'list' || data.type === 'collection';
};

export const generateFormElement = (
  element: Input | Group | Row | Section | List | Collection<Input | Group | Row | Section | List>,
  actions: {
    valueChanged: (id: string, value: InputValue) => void,
    validChanged: (id: string, valid: boolean) => void,
    clicked: (id: string, action: string) => void,
  },
  stateMap: { [key: string]: StateControl },
  valueBind: (keyPath: string[]) => { value: InputValue, changeValue: (newValue: InputValue) => void },

  stateAccess: BaseFormAction,
  idPrefix: string | undefined,
  index: number,
): FormScaffold => {
  const id = idPrefix ? `${idPrefix}.${element.id || ''}` : (element.id || '');
  const reactId = idPrefix ? `${idPrefix}.${element.id || ''}` : (element.id || '');
  const reactKey = idPrefix ? `${idPrefix}.${element.id || `_.${index}`}` : (element.id || `_.${index}`);
  const state: StateControl = {
    value: undefined,
    valid: true,
    setValue: (value: InputValue) => { },
    setValid: (valid: boolean, message?: string) => { },
    setDisabled: (disabled: boolean) => { },
    setColor: (color: string) => { },
    setLabel: (label: string) => { },
    setFeedbackMessage: (feedback: string) => { },
    setHidden: (hidden: boolean) => { },
    setIsInvalid: (isInvalid: boolean) => { },
    setOptional: (optional: boolean) => { },
    setOptions: (options: { [key: string]: InputValue }) => { },
    setProcessing: (processing: boolean) => { },
  };
  stateMap[id] = state;
  let bound = false;

  const stateHelper = {
    valueChanged: (value: InputValue) => actions.valueChanged(id, value),
    validChanged: (valid: boolean) => actions.validChanged(id, valid),
    clicked: (action: string) => actions.clicked(id, action),
    setValue: (cb: ((value: InputValue) => void), initial: InputValue) => {
      state.setValue = (value: InputValue) => {
        state.value = value;
        cb(value);
      };
      state.value = initial;
    },
    setValid: (cb: (valid: boolean, message?: string) => void, initial: boolean) => {
      state.setValid = (valid: boolean) => {
        state.valid = valid;
        cb(valid);
      };;
      state.valid = initial;
    },
    setDisabled: (cb: (disabled: boolean) => void) => { state.setDisabled = cb },
    setColor: (cb: (color: string) => void) => { state.setColor = cb },
    setLabel: (cb: (label: string) => void) => { state.setLabel = cb },
    setFeedbackMessage: (cb: (label: string) => void) => { state.setFeedbackMessage = cb },
    setIsInvalid: (cb: (isInvalid: boolean) => void) => { state.setIsInvalid = cb },
    setHidden: (cb: (hidden: boolean) => void) => { state.setHidden = cb },
    setOptional: (cb: (optional: boolean) => void) => { state.setOptional = cb },
    setOptions: (cb: (options: { [key: string]: InputValue }) => void) => { state.setOptions = cb },
    setProcessing: (cb: (processing: boolean) => void) => { state.setProcessing = cb },

    isBound: () => {
      if (bound) {
        return true;
      }
      bound = true;
      stateMap[id] = state;
      return false;
    }
  }
  let result: JSX.Element;
  const children = (isGroupType(element) ? element.items : [] as any[]).map((child: any, index: any) => generateFormElement(child, actions, stateMap, valueBind, stateAccess, idPrefix, index))
  const bindValue = (element: Input | List): InputValue => {
    let value = element.value;

    if (typeof value === 'string' && value[0] === '[' && value[value.length - 1] === ']') {
      const bind = valueBind(value.slice(1, value.length - 1).split('.'));
      value = bind.value;
      (element as any).setBindingValue = bind.changeValue;
      const bindingType = element.valueType === undefined ? getValueType(value) : element.valueType;
      stateHelper.valueChanged = (value) => {
        const v = coerceToType(value, bindingType);
        bind.changeValue(v);
        actions.valueChanged(id, v);
      }
    }
    return value;
  }

  if (element.type === 'section') {
    result = <S.Section
      {...element}
      id={reactId}
      key={reactKey}
      state={stateHelper}
      stateAccess={stateAccess}
    >
      {children.map((c: any) => c.node)}
    </S.Section>;
  } else if (element.type === 'row') {
    result = <R.Row
      {...element}
      id={reactId}
      key={reactKey}
      state={stateHelper}
      stateAccess={stateAccess}
    >
      {children.map((c: any) => c.node)}
    </R.Row>;
  } else if (element.type === 'group') {
    result = <G.Group
      {...element}
      id={reactId}
      key={reactKey}
      state={stateHelper}
      stateAccess={stateAccess}
      childItems={children.map((c: any) => c.node)}
    >
    </G.Group>;
  } else if (element.type === 'list') {
    let value = bindValue(element);
    result = <L.List
      {...element}
      value={value}
      id={reactId}
      key={reactKey}
      state={stateHelper}
      stateAccess={stateAccess}
    >
    </L.List>
  } else if (element.type === 'collection') {
    result = <C2.Collection
      {...element}
      id={reactId}
      key={reactKey}
      state={stateHelper}
      stateAccess={stateAccess}
    >
      {children.map((c: any) => c.node)}
    </C2.Collection>;
  } else {
    let value = bindValue(element);
    result = <C.Control
      {...element}
      key={reactKey}
      value={value}
      id={reactId}
      state={stateHelper}
      stateAccess={stateAccess}
    />;
  }
  const res = {
    node: result,
    children,
    id,
    element,
  };
  children.forEach((c: any) => {
    c.parent = res;
  });
  return res;
}
import React, { useEffect, useState } from 'react'
import { Button, Col, Tab, Tabs, Card } from 'react-bootstrap'
import { connect } from 'react-redux'
import { HelperFunctions } from '../../Helper/functions'
import { ApiInterfaces } from '../../Interfaces'
import { IRedux } from '../../Interfaces/redux'
import { Panel, PanelBody } from '../panel/panel'
import DevicePanel from './main'
import { SVG, SvgIcon } from '../Utils/SVG'
import ChartModal from '../Graphs/ChartModal'

type IProps = {
    devices: HelperFunctions.groupBy<ApiInterfaces.Device>,
    reload?: boolean
}

const DeviceIndex = (props: IProps) => {
    const [devices, setDevices] = useState<HelperFunctions.groupBy<ApiInterfaces.Device>>({})
    const [reload, setReload] = useState<boolean>(false)
    const [showModal, setShowModal] = useState(false);
    const [modal, setModal] = useState<ApiInterfaces.Device | undefined>(undefined)

    useEffect(() => {
        setDevices(props.devices)
    }, [props]);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const showTrend = (show: boolean, device: ApiInterfaces.Device) => {
        setShowModal(show)
        setModal(device)
    }

    function renderPanels() {
        return Object.entries(devices).map(([k, devices]) => {
            return (
                <>
                    {devices.map((d) => (
                        <Col key={d.id} xs={12} md={6} lg={4} xl={3}>
                            <Card className="mb-4" style={{ border: '1px solid #555', borderRadius: '8px' }}>
                                <Card.Header className="text-center" style={{ backgroundColor: '#000', color: '#fff', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                                    <h5 className="mb-1" style={{ fontSize: '1.0rem' }}><strong>{d.name}</strong></h5>
                                </Card.Header>
                                <Card.Body className="text-center">
                                    <Button variant="outline-info" size="sm" className="w-75 mb-3" onClick={() => showTrend(true, d)}>
                                        <i className="fas fa-chart-line"></i> Trends
                                    </Button>
                                    <div className="mb-3">
                                        {SVG.Icon.hasOwnProperty(d.class) && <SvgIcon height={100} width={100} element={SVG.Icon[d.class]} />}
                                    </div>
                                    <DevicePanel device={d} key={d.id} />
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </>
            )
        })
    }

    return (
        devices ?
            <>
                <ChartModal show={showModal} handleClose={handleCloseModal} device={modal} key={modal?.id} />
                {renderPanels()}
            </> : <></>
    );
};

export default DeviceIndex
import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApiInterfaces } from "../../Interfaces";
import { IRedux } from "../../Interfaces/redux";
import Forecast from "./Forecast";
import DeviceIndex from "../Devices/index";
import NotificationPanel from "../UserNotification/NotificationPanel";
import NodeIndex from "../Nodes/index";
import { HelperFunctions } from "../../Helper/functions";
import { Panel, PanelBody } from "../panel/panel";
import SystemPanel from "../Devices/SystemPanel";
import { ReduxActions } from "../../redux/actions";
import DeviceMapContainer from "../Maps/DeviceMapWrapper";
import { GetMqtt } from "../../Server/Api/MqttManager";
import { BindingSet } from "../../Server/Binding/Binding";
import { Bindings } from "../../Server/Binding/bidings";
import LevelStorage from "../DAS/Storage";
import APIManager from "../../Server/Api/APIInterface";

const bindings: BindingSet = new BindingSet({});

const Home: React.FC = () => {
    const [company, setCompany] = useState<ApiInterfaces.Company | undefined>(undefined);
    const [cameras, setCameras] = useState<ApiInterfaces.VpnCamera[] | undefined>(undefined);
    const { user, accessToken } = useSelector((state: IRedux.MapState) => state.auth)

    const [notification, setNotification] = useState<ApiInterfaces.Notification[] | undefined>(undefined);
    const [devices, setDevices] = useState<HelperFunctions.groupBy<ApiInterfaces.Device> | undefined>(undefined);
    const [storage, setStroage] = useState<ApiInterfaces.Device[] | undefined>(undefined);
    const [nodes, setNodes] = useState<ApiInterfaces.LoraNode[] | undefined>(undefined);
    const [lock, setLock] = useState<boolean>(true);


    useEffect(() => {
        bindings.bind()

        ReduxActions.ShowLoader();
        const instance = GetMqtt()

        function CreateBinding() {
            const company = (new Bindings.Company.Binding(accessToken, user.companyId,
                (data) => {
                    setCompany(Object.values(data)[0])
                    ReduxActions.HideLoader();
                }
            ));
            const device = (new Bindings.RefDevice.Binding(true, company,
                (data) => {
                    const dev = Object.values(data)
                    const storage = dev.filter(y => y.class === 'Storage')
                    setStroage(storage.length === 0 ? undefined : storage)
                    setDevices(HelperFunctions.groupBy(dev.filter(x => x.class !== 'Storage') || [], 'class'))
                }
            ));

            const notifications = (new Bindings.RefNotification.Binding(true, company,
                (data) => setNotification(Object.values(data))
            ));
            const nodes = (new Bindings.RefNodes.Binding(true, company,
                (data) => setNodes(Object.values(data))
            ));
            const nodeCameras = (new Bindings.RefCamera.Binding(true, company,
                (data) => {
                    const d = Object.values(data)
                    if (d.length > 0) {
                        const cameras: { StreamName: string; StreamUrl: string; }[] = []
                        d.forEach(x => cameras.push({ StreamName: x.streamName, StreamUrl: x.streamUrl }))
                        instance.sendMessage(`${user.companyId}/GetStreamInfo/`, JSON.stringify({ cameras }))
                    }
                    setCameras(Object.values(data))
                }
            ));

            bindings.add('company', company);
            bindings.add('device', device);
            bindings.add('notifications', notifications);
            bindings.add('nodes', nodes);
            bindings.add('cameras', nodeCameras);
        }

        async function createExpo() {
            const expoToken = window.localStorage.getItem('expo');
            if (expoToken) await APIManager.createExpoUser(accessToken, user.id, expoToken)
        }

        CreateBinding()
        createExpo()


        return () => {
            bindings.unbind()
            instance.unsubscribeAll()
        };
    }, []);


    const render_System = (system: ApiInterfaces.Device[]) => {
        return (
            <Card className="mb-2" style={{ border: '1px solid #555', borderRadius: '8px' }}>
                <Card.Header className="text-center" style={{ backgroundColor: '#000', color: '#fff', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h5 className="mb-1" style={{ fontSize: '1.0rem', flex: 1 }}><strong>{`${system[0].name}`}</strong></h5>
                    <Button
                        variant={`${lock ? 'danger' : 'outline-success'}`}
                        size="sm"
                        onClick={() => setLock(!lock)}
                    >
                        <i className={`fas fa-${lock ? 'lock' : 'unlock'}`}></i>
                    </Button>
                </Card.Header>
                <Card.Body className="text-center colheight overflow-auto">
                    <SystemPanel device={system[0]} lock={lock} />
                </Card.Body>
            </Card>

            // <Panel theme={'inverse'} reload={false}>
            //     <PanelBody className="p-0">
            //         <Tabs
            //             defaultActiveKey={`${system[0].id}`}
            //             id={`${system[0].id}`}
            //             className="mb-3"
            //             fill
            //             justify
            //         >
            //             {system.map((x) => (
            //                 <Tab
            //                     key={`${x.id}`}
            //                     eventKey={`${x.id}`}
            //                     className="colheight"
            //                     title={
            //                         <span>
            //                             {`${x.name} - Control `}
            //                             <Button
            //                                 variant={`${lock ? 'danger' : 'outline-success'}`}
            //                                 size="sm"
            //                                 style={{float: 'right'}}
            //                                 onClick={() => setLock(!lock)}
            //                             >
            //                                 <i className={`fas fa-${lock ? 'lock' : 'unlock'}`}></i>
            //                             </Button>
            //                         </span>
            //                     }
            //                 >
            //                     <SystemPanel device={x} lock={lock}/>
            //                 </Tab>
            //             ))}
            //         </Tabs>
            //     </PanelBody>
            // </Panel>
        );
    };


    return (
        <>
            {company && (
                <div style={{ padding: '5px' }}>
                    <Row>
                        <Col xs={12}>
                            <Panel theme={'inverse'} reload={false} style={{ marginBottom: '0rem' }}>
                                <PanelBody className="p-6">
                                    <h3 className="text-center"> {company.name_Cia} </h3>
                                </PanelBody>
                            </Panel>
                        </Col>
                    </Row>
                    <Row>
                        {devices && devices['System'] && (
                            <Col xs={12} md={12} lg={4} xl={4}>
                                {render_System(devices['System'])}
                            </Col>
                        )}
                        <Col xs={'12'} lg={devices?.System || storage ? '8' : '12'}>
                            <Panel theme={'inverse'} reload={false} style={{ marginBottom: '0rem' }}>
                                <Forecast reload={false} company={company} key={company.id} />
                                <PanelBody className="p-4">
                                    <DeviceMapContainer
                                        devices={company.Devices}
                                        cameras={cameras}
                                        nodes={nodes}
                                        height={"350px"}
                                        width={"100%"}
                                        center={{
                                            lat: Number(company.lat),
                                            lng: Number(company.lng),
                                        }} />
                                </PanelBody>
                            </Panel>
                        </Col>
                        {storage && (
                            <LevelStorage device={storage[0]} />
                        )}

                    </Row>

                    <Row>
                        {devices && <DeviceIndex devices={devices} />}
                        {nodes && <NodeIndex nodes={nodes} />}
                        {notification && (
                            <NotificationPanel table={notification} />
                        )}
                    </Row>
                </div>
            )}
        </>
    );
};


export default Home

import React, { useEffect, createRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRedux } from '../../Interfaces/redux';
import { Button, Col, Container, Form, ListGroup, Row, Table, Card } from 'react-bootstrap';
import { BindingHooks } from "../../Server/Binding/bidings/BindingHook";
import { useParams, useNavigate } from "react-router-dom";
import { FiCalendar, FiClock, FiRefreshCw, FiArrowRight, FiDownload, FiArrowLeft } from 'react-icons/fi'
import { HelperFunctions } from "../../Helper/functions";
import { IoT } from "../../Helper/IoT";
import { ApiInterfaces } from "../../Interfaces";
import { ReduxActions } from "../../redux/actions";
import { processReportTemplate } from "../../Helper/Calculator";
import { HelperTime } from "../../Helper/Time";
import Chart from "../Graphs/Charts";
import section from "./Section";
import SummarySection from "./SummarySection";
import DataTableSection from "./DataSection";
import GraphSection from "./GraphSection";
import { GrDocumentPdf } from 'react-icons/gr';
import ReportTemplatePDF from '../Utils/PDF/TemplateBuilder';

const TemplateView: React.FC = () => {
    const { user } = useSelector((state: IRedux.MapState) => state.auth)
    const { id } = useParams();
    const navigate = useNavigate();
    const { data } = BindingHooks.GetTemplate(id || "", user.companyId, 'Templates', true)
    const { data: devices } = BindingHooks.Device(user.companyId, 'Devs')
    const ids: string[] = data ? HelperFunctions.devsFromTemplate(data) : []
    const [dataMap, setDataMap] = useState<{ [key: string]: ApiInterfaces.DeviceData[] }>({});
    const [opMap, setOpMap] = useState<{ [deviceId: string]: { [key: string]: { [operation: string]: number } } }>({})

    const summaries = data ? HelperFunctions.getSummariesFromTemplate(data) : []

    const graphRefs: { [key: string]: React.RefObject<HTMLDivElement> } = {};
    data?.section.forEach(s => {
        if ("type" in s && s.type === 'graph') {
            graphRefs[s.id] = createRef()
        }
    })

    console.count("RENDER BOY")

    const [dateRange, setDateRange] = useState({ start: '', end: '' });
    const today = new Date().toISOString().split('T')[0];

    useEffect(() => {
        setQuickDateRange(0)
    }, [devices,data])

    const setQuickDateRange = async (days: number) => {
        const end = new Date();
        const start = new Date();
        days > 0 && start.setDate(end.getDate() - days);
        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999);
        setDateRange({
            start: HelperTime.FixTimeForInput(start),
            end: HelperTime.FixTimeForInput(end)
        });
        await updateDataMap(start, end)
    };
    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setDateRange(prev => ({ ...prev, [name]: value }));
    };

    const updateDataMap = async (start: Date, end: Date) => {
        const maps: { [key: string]: ApiInterfaces.DeviceData[] } = {}
        // console.log(new Date(dateRange.start), new Date(dateRange.end))
        ReduxActions.ShowLoader()
        for (const id of ids) {
            try {
                const items = await IoT.getIdTimeSeriesData(id, start, end);
                maps[id] = items ? items.data : []
            } catch (error) {
                console.error(`Error fetching data for ID ${id}:`, error);
            }
        }

        const results = processReportTemplate(maps, summaries);
        ReduxActions.HideLoader()
        setDataMap(maps)
        setOpMap(results)
    }

    return (
        id && data ? <>
            <Row className="justify-content-center mt-3">
                <Col xs={12} md={12} xl={8} className={"mb-2"}>
                    <Card style={{ border: '1px solid #555', borderRadius: '8px' }} className="mb-1">
                        <Card.Body className="p-6">
                            <div className="d-flex flex-column flex-md-row align-items-stretch align-items-md-center">
                                <div className="d-flex flex-wrap mb-2 mb-md-0 gap-2">
                                    <Button variant="outline-warning" size="sm" className="d-flex align-items-center justify-content-center flex-grow-1 flex-md-grow-0" onClick={() => setQuickDateRange(0)}>
                                        <FiCalendar size={18} className="me-2" /> Today
                                    </Button>
                                    <Button variant="outline-warning" size="sm" className="d-flex align-items-center justify-content-center flex-grow-1 flex-md-grow-0" onClick={() => setQuickDateRange(1)}>
                                        <FiClock size={18} className="me-2" /> Last 24 Hours
                                    </Button>
                                    <Button variant="outline-warning" size="sm" className="d-flex align-items-center justify-content-center flex-grow-1 flex-md-grow-0" onClick={() => setQuickDateRange(7)}>
                                        <FiRefreshCw size={18} className="me-2" /> Last 7 Days
                                    </Button>
                                </div>
                                <div className="d-flex align-items-center ms-md-auto mt-2 mt-md-0 gap-2">
                                    <Form.Control
                                        type="date"
                                        name="start"
                                        value={dateRange.start}
                                        onChange={handleDateChange}
                                        max={today}
                                        size="sm"
                                        className="flex-grow-1"
                                    />
                                    <span className="mx-2">to</span>
                                    <Form.Control
                                        type="date"
                                        name="end"
                                        value={dateRange.end}
                                        onChange={handleDateChange}
                                        min={dateRange.start}
                                        max={today}
                                        size="sm"
                                        className="flex-grow-1"
                                    />
                                    <Button className="d-flex align-items-center justify-content-center" variant="outline-success" size="sm" onClick={() => {
                                        updateDataMap(new Date(dateRange.start), new Date(dateRange.end));
                                    }}>
                                        <FiArrowRight size={18} className="ml-2" />
                                    </Button>
                                </div>
                            </div>
                            <hr />

                            <div className="d-flex justify-content-between align-items-center position-relative">
                                <Button variant="outline-secondary" size="sm" className="d-flex align-items-center" onClick={() => navigate('/reportBuilder')}>
                                    <FiArrowLeft size={18} className="me-2" /> Back
                                </Button>
                                <h3 className="text-center position-absolute w-100 mb-0" style={{ pointerEvents: 'none' }}> {data.title} </h3>
                                <Button variant="danger" size="sm" className="d-flex align-items-center justify-content-center ms-auto" style={{ zIndex: 1 }} onClick={() => ReportTemplatePDF(data, data.title, devices || [], dataMap, opMap, graphRefs)}>
                                    <GrDocumentPdf size={18} className="me-2" /> Export
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="justify-content-center">
                {data.section.map((section,) => (
                    <Col xs={12} md={12} xl={8} className={"mb-2"} key={section.id}>
                        <Card className="mb-1"  style={{ border: '1px solid #555', borderRadius: '8px' }}>
                            <Card.Body className="p-6">
                                <h4 className="text-center">{section.title}</h4>
                                <hr />
                                {("rows" in section) && <SummarySection section={section} devices={devices || []} opMap={opMap} key={section.id} />}
                                {("type" in section) && section.type === 'table' && <DataTableSection section={section} devices={devices || []} dataMap={dataMap} />}
                                {("type" in section) && section.type === 'graph' && <GraphSection section={section} devices={devices || []} forwardedRef={graphRefs[section.id]} dataMap={dataMap} />}
                                <hr />
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </> : <></>
    );
}

export default TemplateView;
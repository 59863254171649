import { KeyMap } from "../../../Helper/Core/interface";
import { Events } from "../../Events/Events";

import { DataSet } from "../DataSet";

export type DataType = Events['template.get']['response'];

export class Binding extends DataSet<DataType> {
    public constructor(private id:string,private companyId: string, cb?: (data: KeyMap<DataType>) => void) {
        super(i => i.id, cb);
    }
    public async bind() {
        super.bind();

        this.registerBindingKey(await this.manager.bind('template.get', {
            id: this.id,
            companyId:this.companyId,
            topic: `${this.companyId}/template`,
        }, async (res) => {
            if (res.type === 'template.get') {
                this.setData([res]);
            } else {
                this.setLoaded();
            }
        }));
    }
}
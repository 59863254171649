import { ApiInterfaces } from "../../Interfaces";
import { IRedux } from "../../Interfaces/redux";

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  REFRESH_TOKEN,
  UPDATE_COMPANY_ID
} from "../actions/actionInterface";


const initState: Partial<IRedux.IAuth> = { isLoggedIn: false };

export default function (state: Partial<IRedux.IAuth> = initState, action: IRedux.Action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      // APIInterface.login(payload.user.username, payload.user.department, payload.user.level, payload.accessToken, payload.refreshToken)
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        mqttToken: payload.mqttToken,
      };

    case REFRESH_TOKEN:
      return {
        ...state,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        mqttToken: payload.mqttToken,
      };


    case UPDATE_COMPANY_ID:
      return {
        ...state,
        user: {
          ...state.user,
          companyId: payload,
        },
      };


    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: undefined,
        accessToken: undefined,
        refreshToken: undefined,
        mqttToken: undefined,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: undefined,
        accessToken: undefined,
        refreshToken: undefined,
        mqttToken: undefined,
      };

    default:
      return state;
  }
}
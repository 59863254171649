import mqtt, { MqttClient } from "mqtt";
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';

type msgBody = { [key: string]: any };
type msgHandler = (message: msgBody) => void;
type handlerMap = { [key: string]: { handler: msgHandler, selector?: (message: msgBody) => boolean } };

class MqttService {
    private mqttClient?: MqttClient;
    private mqttSubjects: Map<string, Subject<string>> = new Map();
    private readonly mqttUrl: string;
    private token: string;
    private readonly subscribedTopics: string[] = [];
    private readonly bindTopics: string[] = [];
    private readonly msgHandlers: handlerMap;


    constructor(mqttUrl: string, token: string) {
        this.msgHandlers = {};
        this.mqttUrl = mqttUrl;
        this.token = token;
    }

    public connect(): void {
        if (!this.mqttClient) {

            this.mqttClient = mqtt.connect(this.mqttUrl, {
                username: this.token,
                password: 'seucu',
                protocolVersion: 5,
                clean: true,
            });
            this.mqttClient.on('connect', () => {
                console.log(`Connected to MQTT broker at ${this.mqttUrl}`, this.subscribedTopics, this.bindTopics, this.mqttClient);
                if (this.subscribedTopics.length > 0) {
                    this.subscribedTopics.forEach(topic => {
                        this.mqttClient?.subscribe(topic);
                    });
                }
                if (this.bindTopics.length > 0) {
                    this.bindTopics.forEach(topic => {
                        this.mqttClient?.subscribe(topic);
                    });
                }
            });

            this.mqttClient.on('disconnect', () => {
                console.log(`Disconnect to MQTT broker at ${this.mqttUrl}`);
            });

            this.mqttClient.on('message', (topic: string, message: Buffer) => {
                const subject = this.mqttSubjects.get(topic);
                const bind = this.bindTopics.includes(topic)
                const id = topic.split("/")[0];
                if (id.match(/^[0-9a-fA-F]{24}$/) && this.mqttClient) this.mqttClient.publish(`${id}/clientConnected/`, '1')
                subject && subject.next(message.toString());
                bind && this.messageHandler(message)

            });

            this.mqttClient.on('error', (err) => {
                console.log(err)
            })
        }


    }


    public disconnect(): void {
        if (this.mqttClient) {
            this.mqttClient.end();
            this.mqttClient = undefined;
            this.mqttSubjects.clear();
        }
    }


    public reconnect(token: string): void {
        if (this.mqttClient) {
            console.log("Closing Connection")
            this.mqttClient.end();
            this.mqttClient = undefined;
            // this.mqttSubjects.clear();
        }

        this.token = token
        this.connect()

    }

    public isConnected(): boolean {
        return this.mqttClient ? this.mqttClient.connected : false;
    }


    public sendMessage(topic: string, message: string): void {
        if (this.mqttClient) {
            this.mqttClient.publish(topic, message);
        }
    }

    public getMessage(topic: string): Subject<string> {
        if (!this.mqttSubjects.has(topic)) {
            this.mqttSubjects.set(topic, new Subject<string>());
            if (this.mqttClient) {
                this.mqttClient.subscribe(topic);
                this.subscribedTopics.push(topic);
                const id = topic.split("/")[0];
                if (id.match(/^[0-9a-fA-F]{24}$/)) this.mqttClient.publish(`${id}/clientConnected/`, '1')
                console.log(`Subscribed to topic: ${topic}`);
            }
        }
        return this.mqttSubjects.get(topic)!;
    }


    public bind(topic: string) {
        const bind = this.bindTopics.includes(topic);
        if (bind) return
        if (this.mqttClient) {
            this.mqttClient.subscribe(topic);
            this.bindTopics.push(topic)
        }
    }

    public unbind(topic: string) {
        const bind = this.bindTopics.includes(topic);
        if(topic === undefined) return
        if (!bind) return
        if (this.mqttClient) {
            this.mqttClient.unsubscribe(topic);
            this.bindTopics.splice(this.bindTopics.indexOf(topic), 1);
        }
    }





    public unsubscribe(topic: string): void {
        if (this.mqttClient && this.mqttSubjects.has(topic)) {
            this.mqttClient.unsubscribe(topic);
            this.mqttSubjects.delete(topic);
            this.subscribedTopics.splice(this.subscribedTopics.indexOf(topic), 1);
        }
    }

    public unsubscribeAll(): void {
        if (this.mqttClient) {
            this.mqttSubjects.forEach((_, topic) => {
                console.log(_)
                this.mqttClient?.unsubscribe(topic);
            });
            this.mqttSubjects.clear();
            this.subscribedTopics.length = 0;
        }
    }

    public addHandler(handler: msgHandler, selector?: (msg: msgBody) => boolean): string {
        const id = uuid();
        this.msgHandlers[id] = { handler, selector };
        return id;
    }

    public removeHandler(id: string) {
        delete this.msgHandlers[id];
    }

    private messageHandler(message: string | Buffer | ArrayBuffer) {
        // console.log('Messages being received WebSocket ', message)
        const m = JSON.parse((message || '').toString());
        Object.values(this.msgHandlers).forEach(({ handler, selector }) => {
            try {
                if (selector === undefined || selector(m)) {
                    handler(m);
                }
            } catch (err) {
                console.log('Handler failed with error:', err);
            }
        });
    }








    // public bind(topic)

}

export default MqttService;

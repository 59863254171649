import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist';
import reducers from "./redux/reducers";




const persistConfig = {
  key: 'root',
  storage,
}

// const store = configureStore(
//   persistReducer(persistConfig, rootReducer),
//   // rootReducer,
//   composeWithDevTools(applyMiddleware(...middleware))
// );


const store = configureStore({
  reducer: persistReducer(persistConfig, reducers),
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});


const persistor = persistStore(store);

export { store, persistor };
import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting'
import ExportData from 'highcharts/modules/export-data'
import noDataToDisplay from 'highcharts/modules/no-data-to-display';
import {GraphBuilder} from './Default';

type IProps = {
    name:string,
    series:Series,
    forwardedRef?: React.RefObject<any>;
    type: "area" | "line" | "bar" | "pie" | "donut" | "radialBar" | "scatter" | "bubble" | "heatmap" | "candlestick" | "boxPlot" | "radar" | "polarArea" | "rangeBar" | "treemap" | "histogram" ,
    noTime?: boolean,
    labelX?:string,
    labelY?:string,
    plotlineY?: GraphBuilder.Plotline[],
    plotlineX?: GraphBuilder.Plotline[],
}

if (typeof Highcharts === 'object') {
    HighchartsExporting(Highcharts)
    noDataToDisplay(Highcharts)
    ExportData(Highcharts)
}

type Options = Highcharts.Options;
type Series = Highcharts.SeriesOptionsType[];


export const PlainChart: React.FC<IProps> = (props: IProps) => {
    const {type, series, name, noTime, labelX, labelY, plotlineY, forwardedRef} = props

    const options: Options =
        {
            ...GraphBuilder.Options(name, type === "line",undefined,noTime, labelY, labelX, plotlineY),
            series: series,
            exporting: {
                enabled: true,
                csv: {itemDelimiter: ';'},
                buttons: {
                    contextButton: {enabled: false},
                    ExportButton: {
                        text: `<i class="fas fa-bars" ></i>`,
                        x: -10,
                        menuItems: ['viewFullscreen', 'exitFullscreen', "separator", 'downloadPNG', 'downloadPDF', 'downloadCSV']
                    }
                },
            }
        }

    return (
        <div>
            <HighchartsReact
                ref={forwardedRef}
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}




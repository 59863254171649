import React, { useState, ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';

type TimeSelectProps = {
    onTimeSelect: (hour: string, minute: string) => void;
};

const TimeSelect: React.FC<TimeSelectProps> = ({ onTimeSelect }) => {
    const [selectedHour, setSelectedHour] = useState<string>('');
    const [selectedMinute, setSelectedMinute] = useState<string>('');

    const handleHourChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const hour = e.target.value;
        setSelectedHour(hour);
        onTimeSelect(hour, selectedMinute);
    };

    const handleMinuteChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const minute = e.target.value;
        setSelectedMinute(minute);
        onTimeSelect(selectedHour, minute);
    };

    const renderOptions = (start: number, end: number): JSX.Element[] => {
        const options: JSX.Element[] = [];
        for (let i = start; i <= end; i++) {
            options.push(<option key={i} value={i.toString()}>{i}</option>);
        }
        return options;
    };

    return (
        <Form.Group controlId="timeSelect" style={{ width: '200px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Form.Select style={{ marginRight: '10px' }} onChange={handleHourChange} value={selectedHour}>
                    <option value="">Hour</option>
                    {renderOptions(0, 23)}
                </Form.Select>
                :&nbsp;
                <Form.Select onChange={handleMinuteChange} value={selectedMinute}>
                    <option value="">Minute</option>
                    {renderOptions(0, 59)}
                </Form.Select>
            </div>
        </Form.Group>
    );
};

export default TimeSelect;
import jsPDF from "jspdf";
import {PDF_Template} from "./Template";
import {ApiInterfaces} from "../../../Interfaces";
import {HelperFunctions} from "../../../Helper/functions";
import {PDF_type} from "./Generic";
import {HelperTime} from "../../../Helper/Time";
import autoTable from "jspdf-autotable";
import React from "react";
import { GraphBuilder } from "../../Graphs/Default";

export default async function ReportTemplatePDF(template: ApiInterfaces.Template, Name: string, devices: ApiInterfaces.Device[], dataMap: { [key: string]: ApiInterfaces.DeviceData[] }, opMap: { [deviceId: string]: { [key: string]: { [operation: string]: number } } }, graphRefs: { [key: string]: React.RefObject<HTMLDivElement> }) {

    const pdf = new jsPDF('p', 'pt', 'a4', true)
    PDF_Template.blankPage(pdf, 1, Name, 'portrait')
    PDF_Template.SetTitle(pdf, `${Name} Report`)
    const fileName = `${Name}-${HelperTime.DateBeautify(new Date())}.pdf`

    console.log(template)

    let currentY = PDF_Template.Title.Y + 5; // Initial Y position

    for (const s of template.section) {
        if ("rows" in s) {
            currentY = TemplateSummary(pdf, s, devices, opMap, currentY);
        } else if ("include" in s && s.type === 'table') {
            currentY = TemplateDataTable(pdf, s, devices, dataMap, currentY);
        } else if (s.type === 'graph' && graphRefs[s.id]) {
            currentY = await TemplateGraph(pdf, s, graphRefs[s.id], currentY);
        }
    }

    if ((window as any).ReactNativeWebView) {
        (window as any).ReactNativeWebView.postMessage(JSON.stringify({
            base64: await HelperFunctions.blobToBase64(new Blob([pdf.output('blob')], {type: 'application/pdf'})),
            fileName: `${Name}-${new Date()}.pdf`
        }))
    }else pdf.save(fileName);
}

export function TemplateSummary(pdf: jsPDF, section: ApiInterfaces.Summary, devices: ApiInterfaces.Device[], opMap: { [deviceId: string]: { [key: string]: { [operation: string]: number } } }, startY: number) {

    const body: string[][] = []
    section.rows.forEach((row: ApiInterfaces.SummaryRow, index) => {
        const device = HelperFunctions.findDevById(devices, row.deviceId)
        const {data, operation, deviceId} = row;
        body[index] = [`${device?.name}`, `${device?.metadata[data]?.name}`, operation, `${opMap[deviceId]?.[data]?.[operation] != null ? opMap[deviceId][data][operation] : 'N/A'} ${device?.metadata[data]?.unit}`]
    })

    PDF_Template.SectionTitle(pdf, `${section.title}`, startY)
    autoTable(pdf, {
        theme: 'grid',
        startY: startY + 50,
        head: [['Device Name', 'Data Type', 'OP', 'Value']],
        body,
        showHead: true,
        columnStyles: {
            0: {fillColor: [233, 236, 239], textColor: [0, 0, 0], fontStyle: 'bold', lineWidth: 0.1},
            2: {fillColor: [233, 236, 239], textColor: [0, 0, 0], fontStyle: 'bold', lineWidth: 0.1},
        },
        headStyles: {fillColor: [233, 236, 239], textColor: [0, 0, 0], fontStyle: 'bold', lineWidth: 0.1},
    });

    return (pdf as any).lastAutoTable.finalY + 10; // Return the updated Y position
}

export function TemplateDataTable(pdf: jsPDF, section: ApiInterfaces.DataReport, devices: ApiInterfaces.Device[], dataMap: { [key: string]: ApiInterfaces.DeviceData[] }, startY: number) {
    const device = HelperFunctions.findDevById(devices || [], section.deviceId);
    const body: any[] = [];

    dataMap[section.deviceId]?.forEach(item => {
        const row: any[] = [HelperTime.DateBeautify(item.timestamp)];
        const rowValues: any[] = [];
    
        section.include.forEach(header => {
            let value = '';
            const metadata = device?.graph_metadata?.find(meta => meta.key === header);
            if (metadata) {
                const context = metadata.context;
                if (item.payload.hasOwnProperty(context)) value = item.payload[context];
            }
            rowValues.push(value);
        });
    
        // Check if all values in the row are empty
        const isEmptyRow = rowValues.every(value => value === '');
    
        if (!isEmptyRow) {
            body.push([...row, ...rowValues]);
        }
    });

    const header = ["Date", ...section.include.map(h => {
        const withoutParens = h.replace(/\((.*?)\)/g, '');
        return h.replace(/\((.*?)\)/g, (match, p1) => withoutParens.includes(p1) ? '' : match)
                .replace('(', ' ')
                .replace(/[()]/g, '');
    })];
    PDF_Template.SectionTitle(pdf, `${section.title}`, startY);
    autoTable(pdf, {
        theme: 'grid',
        startY: startY + 50,
        head: [header],
        body,
        showHead: true,
        tableWidth: 'auto', // Ensure the table fits within the page width
        columnStyles: {
            0: { fillColor: [233, 236, 239], textColor: [0, 0, 0], fontStyle: 'bold', lineWidth: 0.1, halign: 'center' },
            // Set a fixed width for each column
        },
        headStyles: { fillColor: [233, 236, 239], textColor: [0, 0, 0], fontStyle: 'bold', lineWidth: 0.1, halign: 'center' },
        styles: {
            overflow: 'linebreak', // Ensure text does not overflow
            cellWidth: 'auto', // Wrap text within cell
            halign: 'center', // Center align text
        },
    });
    return (pdf as any).lastAutoTable.finalY + 10; // Return the updated Y position
}


export async function TemplateGraph(pdf: jsPDF, section: ApiInterfaces.DataReport, graphRef: React.RefObject<HTMLDivElement>, startY: number) {
    
    // Capture the graph as a base64 image
    const modal = graphRef.current as any;
    if (!modal) return startY;

    const imgData = await GraphBuilder.getBase64(GraphBuilder.switchToLightModeAndExportSVG(modal));
    GraphBuilder.switchToBlack(modal)
    if (imgData) {
        const pageHeight = pdf.internal.pageSize.height;
        const marginBottom = 20; // Bottom margin
        const availableHeight = pageHeight - startY - marginBottom;
        console.log(pageHeight,availableHeight)
        const requiredHeight = 390; // Height of the image
        let heightToUse = availableHeight < 390 ? (availableHeight - 50) : (requiredHeight-50);

        if (availableHeight < 300) {
            pdf.addPage();
            startY = 10; // Reset startY for the new page
            heightToUse = 340
        }

        PDF_Template.SectionTitle(pdf, `${section.title}`, startY);
        const size = pdf.internal.pageSize.width - 2 * PDF_Template.line.X;
        pdf.addImage(imgData, 'SVG', PDF_Template.line.X, startY + 50, size, heightToUse);
        return startY + heightToUse + 60; // Return the updated Y position
    }

    return startY; // Return the updated Y position
}


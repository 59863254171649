import React from 'react';
import {Table} from 'react-bootstrap';
import {HelperFunctions} from "../../Helper/functions";
import {ApiInterfaces} from "../../Interfaces";
import {HelperTime} from "../../Helper/Time";
import Chart from "../Graphs/Charts";
import {GraphBuilder} from "../Graphs/Default"; // Assuming you're using react-bootstrap

interface DataTableSectionProps {
    section: ApiInterfaces.DataReport;
    devices: ApiInterfaces.Device[];
    forwardedRef: React.RefObject<any>;
    dataMap: { [key: string]: ApiInterfaces.DeviceData[] }
}

const GraphSection: React.FC<DataTableSectionProps> = ({section, devices, dataMap, forwardedRef}) => {
    const device = HelperFunctions.findDevById(devices || [], section.deviceId);
    const customMetadata = device?.graph_metadata?.filter(item => section.include.includes(item.key)) || [];
    return (
        <div>
            {dataMap[section.deviceId] && device && <Chart forwardedRef={forwardedRef} device={device} key={device.id} type={"line"}
                                                           customMetadata={customMetadata} shared={true}
                                                           series={GraphBuilder.generateHighChartsWithMetadata(dataMap[section.deviceId], customMetadata, 'line', !device.aggregate)}/>}
        </div>
    );
};

export default GraphSection;
import { KeyMap } from "../../../Helper/Core/interface";
import { Events, Filters } from "../../Events/Events";

import { DataSet } from "../DataSet";

export type DataType = Events['management.get']['response'];

export class Binding extends DataSet<DataType> {
    public constructor(private accessToken: string, private managementId: string, cb?: (data: KeyMap<DataType>) => void) {
        super(i => i.id, cb);
    }
    public async bind() {
        super.bind();

        this.registerBindingKey(await this.manager.bind('management.get', {
            accessToken: this.accessToken,
            id: this.managementId,
            topic: `${this.managementId}/management`,
        }, async (res) => {
            if (res.type === 'management.get') {
                this.setData([res]);
            } else {
                this.setLoaded();
            }
        }));
    }
}
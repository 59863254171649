import { KeyMap } from '../../Helper/Core/interface';
import { DataSet } from './DataSet';

export class BindingSet {
  private bound: boolean = false;
  public constructor(private bindings: KeyMap<DataSet<any>> = {}) {

  }

  public allLoaded() {
    return Object.values(this.bindings).filter(b => !b.hasLoaded()).length === 0;
  }
  public get(id: string): DataSet<any> | undefined {
    return this.bindings[id];
  }


  public getAll() {
    return Object.values(this.bindings)
  }


  public add(id: string, binding: DataSet<any>) {
    this.bindings[id] = binding;
    if (this.bound) {
      binding.bind();
    }
  }

  public remove(id: string) {
    const binding = this.bindings[id];
    delete this.bindings[id];
    if (this.bound && binding) {
      binding.unbind();
      delete this.bindings[id]
    }
  }

  public removeAll() {
    Object.entries(this.bindings).forEach(([id, x]) => {
      delete this.bindings[id]
      if (this.bound && x) {
        x.unbind()
      }
    })
  }

  public bind() {
    if (this.bound) {
      return;
    }
    this.bound = true;
    Object.values(this.bindings).forEach((binding) => binding.bind());
  }

  public unbind() {
    if (!this.bound) {
      return;
    }
    this.bound = false;
    Object.entries(this.bindings).forEach(([id, binding]) => {
      binding.unbind();
      delete this.bindings[id]

    }

    );
  }
}
import React, { useState } from 'react';
import { Dropdown, DropdownButton, Form } from 'react-bootstrap';

export type MultiSelectOption = {
    value: string;
    label: string;
};

type MultiSelectDropdownProps = {
    options: MultiSelectOption[];
    defaultTitle?: string;
    onSelect: (selectedOptions: MultiSelectOption[]) => void;
};

const MAX_DISPLAYED_OPTIONS = 3;

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
    options,
    defaultTitle = 'Select Options',
    onSelect,
}) => {
    const [selectedOptions, setSelectedOptions] = useState<MultiSelectOption[]>([]);

    const handleOptionToggle = (option: MultiSelectOption) => {
        const isSelected = selectedOptions.some((selectedOption) => selectedOption.value === option.value);
        let updatedOptions: MultiSelectOption[];

        if (isSelected) {
            updatedOptions = selectedOptions.filter((selectedOption) => selectedOption.value !== option.value);
        } else {
            updatedOptions = [...selectedOptions, option];
        }

        setSelectedOptions(updatedOptions);
        onSelect(updatedOptions);
    };

    const handleSelectAll = () => {
        setSelectedOptions(options);
        onSelect(options);
    };

    const handleClearAll = () => {
        setSelectedOptions([]);
        onSelect([]);
    };

    const selectedOptionsTitle =
        selectedOptions.length > 0
            ? selectedOptions
                .slice(0, MAX_DISPLAYED_OPTIONS)
                .map((option) => option.label)
                .join(', ') +
            (selectedOptions.length > MAX_DISPLAYED_OPTIONS ? '...' : '')
            : defaultTitle;

    return (
        <Dropdown>
            <Dropdown.Toggle variant="outline-warning" id="dropdown-multi-select">
                {selectedOptionsTitle}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ minWidth: '200px' }}>
                <Dropdown.Item onClick={handleSelectAll}>Select All</Dropdown.Item>
                <Dropdown.Item onClick={handleClearAll}>Clear All</Dropdown.Item>
                <Dropdown.Divider />
                <div className="px-3 py-2">
                    {options.map((option) => (
                        <Form.Check
                            key={option.value}
                            type="checkbox"
                            id={option.value}
                            label={option.label}
                            checked={selectedOptions.some((selectedOption) => selectedOption.value === option.value)}
                            onChange={() => handleOptionToggle(option)}
                        />
                    ))}
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default MultiSelectDropdown;

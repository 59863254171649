import React from 'react';
import { Navbar, Button } from 'react-bootstrap';
import { GrMenu} from "react-icons/gr";

interface IHeaderProps {
    toggleSidebar: () => void;
    show: boolean
}

const Header: React.FC<IHeaderProps> = ({show, toggleSidebar}) => {
    return (
        <>
            <Navbar bg="dark" variant="dark" expand="lg" sticky="top" className="d-flex justify-content-center navbarContainer">
                <Button
                    className={'position-absolute start-0 border-0 align-items-center m-5'}
                    variant="outline-light"
                    onClick={toggleSidebar}
                    disabled={show && (window.innerWidth < 1200)}
                    active={show}
                >
                    {<GrMenu size={20} />}
                </Button>
                <Navbar.Brand className="ms-4 justify-content-center " href="/">
                    <img
                        src="/images/SierraNetLogo.png"
                        alt="SierraNet Logo"
                        className="bnav-bee"
                        style={{ maxWidth: '260px', height: 'auto', maxHeight: '220px' }}
                    />
                </Navbar.Brand>
            </Navbar>
        </>
    );
};


export default Header;
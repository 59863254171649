import { KeyMap } from "../../../Helper/Core/interface";
import { Events } from "../../Events/Events";
import { SubDataSet } from "../SubDataSet";
import * as Management from "../bidings/Management"


const type = 'company.list'
export type DataType = Events['company.list']['response']['data'][number];


export class Binding extends SubDataSet<DataType, Management.DataType> {
    public constructor(private skip:boolean, private eventSetData: Management.Binding, cb?: (data: KeyMap<DataType>) => void, parentSetFilter?: (item: Management.DataType) => boolean, awaitFirstLoad?: boolean) {
        super(eventSetData, false, i => i.id, i => i.id, cb, parentSetFilter, awaitFirstLoad);
    }
    protected async bindToBaseId(managementsId: string, storeData: (data: DataType[] | undefined) => void) {
        const simRequest: Events['company.list']['response'] = {type, data: this.eventSetData.getData()[managementsId].companies || []}
        return await this.manager.bind(type, { managementsId,  topic: `${managementsId}/company` ,skipBinding: this.skip ? simRequest : undefined }, (res) => {
            if (res.type === type) {
                storeData(res.data);
            } else {
                storeData(undefined);
            }
        });
    }
}

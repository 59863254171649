import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {ApiInterfaces} from '../../Interfaces';
import DevicePanel from '../Devices/main';
import APIManager from '../../Server/Api/APIInterface';
import {IRedux} from '../../Interfaces/redux';
import {connect} from 'react-redux';

import CameraPlayer from './Camera';
import AgmacTimers from '../Nodes/AgmacTimers';
import {GetMqtt} from '../../Server/Api/MqttManager';


interface MarkerData {
    title: string;
    data: ApiInterfaces.Device | ApiInterfaces.VpnCamera | ApiInterfaces.LoraNode;
}

type Props = MarkerModalProps & IRedux.IAuth;


interface MarkerModalProps {
    show: boolean;
    handleClose: () => void;
    markerData: MarkerData | undefined;
}

function MarkerModal(props: Props) {
    const {show, handleClose, markerData} = props;
    const [hls, setHls] = useState<string | undefined>(undefined)

    // const instance = MqttManager.getInstance(props.mqttToken || '').getMqttService()

    useEffect(() => {

        const getLiveFeed = async (data: ApiInterfaces.VpnCamera) => {
            GetMqtt().sendMessage(`${props.user.companyId}/startLiveStream/`, '1')
            const res = await APIManager.ApiRequest('camera.live', {
                accessToken: props.accessToken,
                streamName: data.streamName
            })
            if (res.type === 'camera.live') setHls(res.live)
        }

        markerData && 'streamName' in markerData.data && getLiveFeed(markerData.data)

    }, []);

    function RenderModalBody(data: ApiInterfaces.Device | ApiInterfaces.VpnCamera | ApiInterfaces.LoraNode) {

        if ('DEVEUI' in data) {
            return <AgmacTimers node={data} expanded={true} key={data.id}/>;
        } else if (hls && 'streamName' in data) {
            return <CameraPlayer hls={hls} camera={data} key={data.id}/>;
        } else if ('metadata' in data) {
            return <DevicePanel device={data} key={data.id} expanded={true}/>
        }
    }


    return (
        markerData ? <Modal show={show} onHide={handleClose} className="qc_modal">
                <Modal.Header closeButton>
                    <Modal.Title>{markerData.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {RenderModalBody(markerData.data)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='danger' onClick={handleClose}>  &nbsp; <i className="far fa-times-circle"></i> &nbsp;
                    </Button>
                </Modal.Footer>
            </Modal>
            : <></>


    );
}

const mapStateToProps = (state: IRedux.MapState) => {
    const {user, isLoggedIn, accessToken, refreshToken, mqttToken} = state.auth;
    return {
        user,
        isLoggedIn,
        accessToken,
        refreshToken,
        mqttToken
    };
};

const connector = connect(mapStateToProps);

export default connector(MarkerModal);
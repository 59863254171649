
import { KeyMap } from '../../Helper/Core/interface';
import MqttManager from '../Api/MqttManager';
import { DataSet } from './DataSet';

// A template for binding a set of request to each object in a set of objects and maintaining the set as it evolves
export abstract class SubDataSet<T, PT> extends DataSet<T> {
  private boundObjects: KeyMap<{ bindingIds: string[] | undefined, data: T[], loaded: boolean }> = {};
  private loaded: boolean;
  public constructor(
    private parentSet: DataSet<PT>,
    private manageParentSet: boolean,
    getParentKey: (item: PT) => string,
    getKey: (item: T) => string,
    cb?: (data: KeyMap<T>) => void,
    private parentSetFilter?: (item: PT) => boolean,
    private awaitFirstLoad: boolean = false,
  ) {
    super(getKey, cb);
   
    // register for updates to the set of parent objects from the base binding set
    this.loaded = !awaitFirstLoad;
    this.parentSet.register((objects) => {
      const unused: KeyMap<boolean> = {};
      Object.keys(this.boundObjects).forEach((id) => unused[id] = false);
      // ensure all object ids in the response set are bound
      Object.values(objects).forEach((object) => {
        if (this.parentSetFilter && !this.parentSetFilter(object)) {
          return;
        }
        const id = getParentKey(object);
        unused[id] = true;
        this.bindToObject(id, !(this.loaded));
      });
      this.loaded = true;
      // release any bound objects the did not appear in the set 
      Object.entries(unused).forEach(([id, exists]) => {
        if (!exists) {
          (this.boundObjects[id].bindingIds || []).forEach((bindingId) => {
            if (bindingId !== undefined) {
              this.releaseBindingKey(bindingId);
            }
          });
          delete this.boundObjects[id];
        }
      });
      if (Object.keys(objects).length === 0) {
        this.setData([]);
      }
    });
  }

  public bind() {
    if (this.manageParentSet && !this.isBound()) {
      this.parentSet.bind();
    }
    super.bind();
    Object.keys(this.boundObjects).forEach((serviceId) => {
      this.bindToObject(serviceId, this.loaded);
    });
    this.loaded = true
  }
  public unbind() {
    if (this.manageParentSet && this.isBound()) {
      this.parentSet.unbind();
    }
    super.unbind();
    Object.values(this.boundObjects).forEach(data => {
      data.bindingIds = undefined;
    });
  }

  public getParentData() {
    return this.parentSet.getData();
  }

  // implement this to bind to individual object ids, call storeData as the data set for that id updates
  protected abstract bindToBaseId(objectId: string, storeData: (data: T[] | undefined) => void, bindAdditionalId: (id: string) => void, data?:T[]): Promise<string>;


  // Helper method for binding to objects as they come and go
  private async bindToObject(objectId: string, loaded: boolean) {
    if (this.boundObjects[objectId] === undefined) {
      this.boundObjects[objectId] = { bindingIds: undefined, data: [], loaded: loaded };
    }
    if (this.isBound() && this.boundObjects[objectId].bindingIds === undefined) {
      const ids: string[] = [];
      this.boundObjects[objectId].bindingIds = ids;
      const id = await this.bindToBaseId(objectId, (data) => {
        this.boundObjects[objectId].data = data || [];
        this.boundObjects[objectId].loaded = true;

        if (this.awaitFirstLoad) {
          if (Object.values(this.boundObjects).filter(i => !i.loaded).length > 0) {
            return;
          }
          this.awaitFirstLoad = false;
        }
        if (data === undefined) {
          this.setLoaded();
        } else {
          this.setData(Object.values(this.boundObjects).reduce((p, c) => p.concat(c.data), [] as T[]));
        }
      }, (id) => {
        ids.push(id);
        this.registerBindingKey(id);
      });
      ids.push(id);
      this.registerBindingKey(id);
    }
  }
}
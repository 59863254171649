import React, { Component } from "react";
import { FadingAlert } from './Alert';
import { connect } from "react-redux";
import { clear } from "../redux/actions/alertsAction";
import { IRedux } from "../Interfaces/redux";




class BaseAlert extends React.Component<IRedux.IAlert & { dispatch: any }, {}> {
    constructor(props: IRedux.IAlert & { dispatch: any }) {
        super(props);
        this.state = {
            messages: null,
        }

    }
    render() {
        const { id, type, messagealert, dispatch } = this.props;
        if (messagealert != null) {
            return <div className="alertDiv">
                <FadingAlert
                    timeout={4000}
                    variant={type}
                    id={id}
                    fadedOut={() => {
                        //const messages = { ...this.state.messages };
                        dispatch(clear())
                        // this.setState({ messages: null });
                    }}
                    msg={messagealert}
                />
            </div>;
        } return <></>
    }
}
function mapStateToProps(state: { alert: IRedux.IAlert; }) {
    const { messagealert, type, id } = state.alert;
    //
    return {
        messagealert,
        type,
        id
    };
}

export default connect(mapStateToProps)(BaseAlert)
import React from "react";
import { Accordion, Button, Col, Row, Tab, Table, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import { ApiInterfaces } from "../../Interfaces";
import { IRedux } from "../../Interfaces/redux";
import { showError } from "../../redux/actions/alertsAction";
import { store } from "../../store";
import AgmacTimers from "./AgmacTimers";
import { Panel, PanelBody } from "../panel/panel";
import TimersModal from "./TimersModal";
import { SVG, SvgIcon } from "../Utils/SVG";


//#302e2e

export interface IProps extends IRedux.IAuth {
    nodes: ApiInterfaces.LoraNode[]
}


export interface IState {
    nodes: ApiInterfaces.LoraNode[] | undefined
    show: boolean
    expand: boolean

}



class NodeIndex extends React.Component<IProps, IState>  {
    constructor(props: IProps) {
        super(props);
        this.state = {
            nodes: undefined,
            show: false,
            expand: false

        };

    }

    async componentDidMount() {
        const { nodes } = this.props
        try {
            // const res = await APIManager.ApiRequest('node.list', { accessToken, companyId: user.companyId })
            // (res)
            // res.type === 'node.list' && this.setState({ nodes: res.data })

            this.setState({ nodes })

        } catch (error) {
            store.dispatch(showError('Unable to Load Resources please contact SierraTek For Support'))
        }

    }



    renderAgmacs() {
        const { nodes, expand } = this.state;
        const timers = nodes?.filter(x => x.class === 'AgmacTimer');
        return (
            timers && timers.length > 0 && (
                <Col xs={12} md={12} lg={4} xl={4} xxxl={3}>
                    <Panel theme={'inverse'} reload={false}>
                        <PanelBody className="p-0">
                            <Tabs activeKey={'AgmacTimer'} className="mb-4" fill justify >
                                <Tab eventKey="AgmacTimer" title={`AgmacTimers`} className="mb-3">

                                    <div className="d-flex justify-content-center">
                                        <Button variant="outline-info" size="sm" className="w-75 p-4 " onClick={() => this.setState({ show: true })}>
                                            <i className="fas fa-calendar-check"></i> - Schedule
                                        </Button>
                                    </div>
                                    <hr />
                                    <div className="d-flex justify-content-center p-4">
                                        {SVG.Icon.hasOwnProperty(timers[0].class) && <SvgIcon height={100} width={100} element={SVG.Icon[timers[0].class]} />}
                                    </div>
                                    <hr />
                                    <Accordion activeKey={expand ? "0" : undefined}>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header onClick={() => (this.setState({ expand: !expand }))}>
                                                {expand ? 'Hide' : 'Expand'} Nodes
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                {timers.map((timer, index) => {
                                                    return <AgmacTimers key={index} node={timer} />;
                                                })}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>


                                    {/* <DevicePanel device={x} />


                                    <Table hover responsive="lg">
                                        <tbody>
                                            <tr>
                                                <td colSpan={2} className="text-center">
                                                    <div className="d-grid gap-2">
                                                        <Button variant="outline-info" size="sm" onClick={() => this.setState({ show: true })}>
                                                            <i className="fas fa-calendar-check"></i> - Schedule
                                                        </Button>
                                                    </div>
                                                </td>
                                            </tr>
                                            {timers.map((timer, index) => {
                                                return <AgmacTimers key={index} node={timer} />;
                                            })}
                                        </tbody>
                                    </Table> */}
                                </Tab>
                            </Tabs >
                        </PanelBody>
                    </Panel >
                </Col >
            )
        );



    }

    render() {
        const { show } = this.state;
        return (
            <>
                <TimersModal show={show} handleClose={() => this.setState({ show: false })} />
                {this.renderAgmacs()}
            </>
        );
    }

}






const mapStateToProps = (state: IRedux.MapState) => {
    const { user, isLoggedIn, accessToken, refreshToken, mqttToken } = state.auth;
    return {
        user,
        isLoggedIn,
        accessToken,
        refreshToken,
        mqttToken
    };
};

const connector = connect(mapStateToProps);

export default connector(NodeIndex);
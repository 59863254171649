import { IRedux } from "../../Interfaces/redux";
import { store } from "../../store";
import { HIDE_LOADER, SHOW_LOADER } from "./actionInterface";
import { showError, showSuccess } from "./alertsAction";
import {connect} from "react-redux";

export namespace ReduxActions {


    export const ShowLoader = () => dispatchHelper({type:SHOW_LOADER})
    export const HideLoader = () => dispatchHelper({type:HIDE_LOADER})
    export const ShowError = (message: string) => dispatchHelper(showError(message))
    export const ShowSuccess = (message: string) => dispatchHelper(showSuccess(message))

    const mapStateToProps = (state: IRedux.MapState) => {
        const {user, isLoggedIn, accessToken, refreshToken, mqttToken} = state.auth;
        return {
            user,
            isLoggedIn,
            accessToken,
            refreshToken,
            mqttToken
        };
    };
    export const connector = connect(mapStateToProps);





    function dispatchHelper(action: IRedux.Action) {
        return store.dispatch(action)
    }



}
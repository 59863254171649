import { KeyMap } from "../../../Helper/Core/interface";
import APIManager from "../../Api/APIInterface";
import { Events } from "../../Events/Events";

import { DataSet } from "../DataSet";

export type DataType = Events['user.list']['response']['data'][number];

export class Binding extends DataSet<DataType> {
  public constructor(private accessToken: string, cb?: (data: KeyMap<DataType>) => void) {
    super(i => i.id, cb);
  }
  public async bind() {
    super.bind();
    this.registerBindingKey(await this.manager.bind('user.list', { accessToken: this.accessToken }, (res) => {
        if (res.type === 'user.list') {
          this.setData(res.data);
        } else {
          this.setLoaded();
        }
      }));
  }
}
import * as yup from "yup";

export const UserCreation = yup.object().shape({
    name: yup.string().required('Name is required.'),
    email: yup.string().required('Email is required.').email('Invalid email format.'),
    company: yup.string().required('Please select a company.'),
    password: yup.string()
        .required('Password is required.')
        .min(8, 'Password must be at least 8 characters long.')
        .matches(
            /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
            'Password must contain at least one letter, one number, and one symbol.'
        ),
    confirmpassword: yup.string()
        .required('Confirm Password is required.')
        .test('password-match', 'Passwords must match.', function (value) {
            return value === this.resolve(yup.ref('password'));
        }),
});

export const FormTemplate = yup.object().shape({
    title: yup.string().required('Title is required.'),
});

export const SectionDataValidation = yup.object().shape({
    title: yup.string().required('Title is required.'),
    deviceId: yup.string().required('DeviceId is required.'),
});

import { Navigate, Outlet } from 'react-router-dom'

const PublicRoute = ({
    isAllowed,
    children,
}) => {
    // const searchParams = new URLSearchParams(window.location.search);
    if (!isAllowed) {
        return <Navigate to={{ pathname: '/' /*, search: searchParams.toString() */ }} replace />;
    }

    return children ? children : <Outlet />;
};


export default PublicRoute
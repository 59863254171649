import React, { Component } from "react";
import Alert, { AlertProps } from 'react-bootstrap/Alert';
import Collapse from 'react-bootstrap/Collapse';

export type AlertType = AlertProps['variant'];

export class FadingAlert extends Component<{ id: string, msg: string, fadedOut: () => void, timeout?: number } & AlertProps, { display: boolean }> {
  constructor(props: { id: string, msg: string, fadedOut: () => void, timeout: number, variant: string } & AlertProps) {
    super(props);
    this.state = {
      display: true,
    }
  }
  render() {
    const { id, msg, variant, fadedOut, timeout = 4000, ...props } = this.props;
    if (variant) {
      return <Collapse key={id} appear={true} in={this.state.display} onExited={() => setTimeout(() => this.props.fadedOut(), 100)} onEnter={() => setTimeout(() => { this.setState({ display: false }) }, timeout)} >
        <div><Alert key={id} variant={variant} {...props} style={Object.assign({ textAlign: 'center' }, props.style || {})}>{msg}</Alert></div>
      </Collapse>;
    } return <></>

  }
}
import { MultiSelectOption } from "../Components/Utils/DropdownSelector";
import { ApiInterfaces } from "../Interfaces";
import APIManager from "../Server/Api/APIInterface";
import { showError, showSuccess } from "../redux/actions/alertsAction";
import { store } from "../store";
import axios, { AxiosResponse } from 'axios';


export interface CronExpression {
    CronExpression: string;
    LORA_COMMAND: string;
    Duration: number;
    ID: number;
    CronExpressionName: string;
}

export interface DaySchedule {
    start: number;
    end: number;
    dayOfWeek: string;
    CronjobAssociated?: string;
}

export type weekday = 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN'






export namespace AGMAC_Timers {
    export type TimerMetadata = {
        WeekDaysArray: DaySchedule[];
        CronExpression: CronExpression[];
    }

    export const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];


    export const weekOptions: MultiSelectOption[] = [
        { value: 'SUN', label: 'Sunday' },
        { value: 'MON', label: 'Monday' },
        { value: 'TUE', label: 'Tuesday' },
        { value: 'WED', label: 'Wednesday' },
        { value: 'THU', label: 'Thursday' },
        { value: 'FRI', label: 'Friday' },
        { value: 'SAT', label: 'Saturday' }
    ];

    export async function updateTimer(nodes: ApiInterfaces.LoraNode[], weekdays: weekday[], start: string, duration: number, accessToken: string) {

        const weeklyTime = generateWeeklyTime(start, duration, weekdays)
        const lora_command: string = `S:2:${duration}:${duration + 2};`;

        const cronTime: string[] = start.split(':');
        const implodedWeek: string = weekdays.join(',');
        const cronExpression: string = `0 ${cronTime[1]} ${cronTime[0]} ? * ${implodedWeek} *`;

        await Promise.all(nodes.map(async (node) => {
            try {
                const metadata = addPropertiesIfNotExists(node.metadata as TimerMetadata)
                const { WeekDaysArray, CronExpression } = metadata
                let conflict = false
                WeekDaysArray.forEach(el => {
                    conflict = checkForTimeConflicts(weeklyTime, el)
                });
                if (conflict) {
                    store.dispatch(showError('Time conflict found please revise your schedule'))
                    return undefined
                }
                CronExpression.push({
                    CronExpression: cronExpression,
                    CronExpressionName: `${node.name} - ${new Date().getTime()}`,
                    ID: new Date().getTime(),
                    LORA_COMMAND: lora_command,
                    Duration: duration
                })
                WeekDaysArray.push(...weeklyTime)
                await updateCron(node, cronExpression, lora_command, duration)
                await APIManager.ApiRequest('node.edit', { accessToken, id: node.id, metadata: metadata })
                store.dispatch(showSuccess('Schedule Updated'))
            } catch (error) {
                store.dispatch(showError('Unable to update schedulers, please contact support'))

            }
        }));
    }

    export function updateTimersGraph(nodes: ApiInterfaces.LoraNode[], day: 0 | 1 | 2 | 3 | 4 | 5 | 6) {

        const weekDay = daysOfWeek[day];

        let apexChart: any[] = [];

        nodes.forEach(node => {
            const metadata = node.metadata as TimerMetadata
            const jsonTimers: any[] = [];
            metadata.WeekDaysArray.forEach(day => {
                if (weekDay === day.dayOfWeek) jsonTimers.push({
                    x: node.name,
                    y: [day.start, day.end]
                })
            })
            apexChart.push({
                name: node.name,
                data: jsonTimers,
            });
        })
        return apexChart
    }



    export function checkForTimeConflicts(week: DaySchedule[], weekdays: DaySchedule): boolean {
        for (const checkForDuplicates of week) {
            const dateStart = checkForDuplicates.start;
            const dateEnd = checkForDuplicates.end;

            const dateinTime = weekdays.start;
            const dateoutTime = weekdays.end;

            if (
                checkForDuplicates.dayOfWeek === weekdays.dayOfWeek &&
                dateinTime <= dateEnd &&
                dateoutTime >= dateStart
            ) {
                return true; // Time conflict found
            }
        }

        return false; // No time conflict found
    }


    export function generateWeeklyTime(start: string, duration: number, weekDays: weekday[]): DaySchedule[] {
        
        const compareWeekArray: weekday[] = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        const dateStart: number = new Date(`01/01/1975 ${start}`).getTime();
        const dateFinish: number = dateStart + (duration * 60000);
        const weeklyTime: DaySchedule[] = [];

        weekDays.forEach((element) => {
            let indexOfWeek = 0;
            let findDayOfTheWeek = compareWeekArray.indexOf(element);
            if (findDayOfTheWeek == (compareWeekArray.length - 1)) {
                indexOfWeek = 0;
            } else {
                indexOfWeek = findDayOfTheWeek + 1;
            }

            if (dateFinish > 157813199000) {
                weeklyTime.push({
                    dayOfWeek: element,
                    start: dateStart,
                    end: 157813199000
                });

                let timeLeft: number = dateFinish - 157813199000;
              
                weeklyTime.push({
                    dayOfWeek: compareWeekArray[indexOfWeek],
                    start: 157726800000,
                    end: 157726800000 + timeLeft
                });
            } else {
                weeklyTime.push({
                    dayOfWeek: element,
                    start: dateStart,
                    end: dateFinish
                });
            }
        });

        return weeklyTime;
    }



    export async function updateCron(node: ApiInterfaces.LoraNode, cronTime: string, loraCommand: string, duration: number): Promise<AxiosResponse<any> | undefined> {
        const name = `${node.name} - ${new Date().getTime()}`;

        const payload = {
            DevEUI: node.DEVEUI,
            LORA_COMMAND: btoa(loraCommand),
            duration: duration
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_SIERRANETCAMERA_URI}/updatecron/${node.companyId}`, {
                command: 'add',
                name: name,
                expression: cronTime,
                payload: payload
            }, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_SIERRANET_CAMERA_TOKEN}${node.companyId}`,
                    'Accept': 'application/json'
                }
            });
            return response;
        } catch (error) {
            store.dispatch(showError('Unable to update cron'))
            return undefined
        }

    }

    export async function resetCron(accessToken: string, companyId: string): Promise<AxiosResponse<any> | undefined> {
        try {
            const response = await axios.post(`${process.env.REACT_APP_SIERRANETCAMERA_URI}/resetcorn/${companyId}`, {},
                {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_SIERRANET_CAMERA_TOKEN}${companyId}`,
                        'Accept': 'application/json'
                    }
                });
            await APIManager.ApiRequest('schedule.reset', { accessToken, companyId })
            store.dispatch(showSuccess('Schedule Deleted'))
            return response;
        } catch (error) {
            store.dispatch(showError('Unable to update cron'))
            return undefined
        }
    }

    export async function getTimersStatus(node: ApiInterfaces.LoraNode): Promise<AxiosResponse<any> | undefined> {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SIERRANETCAMERA_URI}/nodestatus/${node.companyId}?DevEUI=${node.DEVEUI}`, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_SIERRANET_CAMERA_TOKEN}${node.companyId}`,
                    'Accept': 'application/json'
                }
            });
            return response;
        } catch (error) {
            store.dispatch(showError(`${node.name} - Did not responded `))
            return undefined
        }

    }


    export async function CommandTimer(node: ApiInterfaces.LoraNode, command: 'open' | 'close' | 'timed', duration?: number): Promise<AxiosResponse<any> | undefined> {
        try {

            let loraCommand = ''

            if (command === 'open') loraCommand = "S:2:0:2;"
            else if (command === 'timed' && duration) loraCommand = "S:2:" + duration + ":" + (duration + 2) + ";";
            else loraCommand = 'S:1:0:1;'

            const response = await axios.post(`${process.env.REACT_APP_SIERRANETCAMERA_URI}/manual.start/${node.companyId}`,
                {
                    DevEUI: node.DEVEUI,
                    loracommand: loraCommand,
                }, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_SIERRANET_CAMERA_TOKEN}${node.companyId}`,
                    'Accept': 'application/json'
                }
            });
            store.dispatch(showSuccess('Command sent successfully!'))
            return response;
        } catch (error) {
            store.dispatch(showError(`${node.name} - Did not responded `))
            return undefined
        }

    }

    // Create a function to add properties if they don't exist
    export function addPropertiesIfNotExists(obj: Partial<AGMAC_Timers.TimerMetadata>): AGMAC_Timers.TimerMetadata {
        const defaultMetadata: AGMAC_Timers.TimerMetadata = {
            WeekDaysArray: [],
            CronExpression: [],
            ...obj
        };

        return defaultMetadata;
    }







}

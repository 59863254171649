import { v4 as uuid } from 'uuid';
import { KeyMap, makeKeyMapGeneric } from '../../Helper/Core/interface';
import MqttManager, {GetManager} from "../Api/MqttManager";
// import MqttManager, { GetManager } from '../Api/TESTMAN';

export class DataSet<T> {
  protected manager: MqttManager;

  private awaitingInitialLoad: Promise<KeyMap<T>> | undefined;
  private initialLoad: ((data: KeyMap<T>) => void) | undefined;

  private bindingName: string = '';
  private bound: boolean = false;
  private dataLoaded: boolean = false;
  private data: KeyMap<T> = {};
  private localData: KeyMap<T | undefined> = {};

  private bindingKeys: KeyMap<boolean> = {};
  private bindings: KeyMap<(data: KeyMap<T>) => void> = {};

  private itemValidator?: (data: any) => data is T;
  private itemFilter?: (data: T) => boolean;

  public setFilter(filter: (data: T) => boolean) {
    this.itemFilter = filter;
  }

  public constructor(private getKey: (item: T) => string, cb?: (data: KeyMap<T>) => void, itemValidator?: (data: any) => data is T) {

    this.itemFilter = undefined;
    this.manager = GetManager();
    this.itemValidator = itemValidator;
    if (cb) {
      this.register(cb);
    }
  }

  public register(cb: (data: KeyMap<T>) => void) {
    this.bindings[uuid()] = cb;
    if (this.bound) {
      cb(this.data);
    }
  }

  public awaitData(): Promise<KeyMap<T>> {
    if (this.hasLoaded()) {
      return Promise.resolve(this.data);
    }
    if (this.awaitingInitialLoad === undefined) {
      this.awaitingInitialLoad = new Promise<KeyMap<T>>((r) => {
        this.initialLoad = r;
      });
    }
    return this.awaitingInitialLoad;
  }

  protected registerBindingKey(key: string) {
    this.bindingKeys[key] = true;
  }

  protected releaseBindingKey(key: string) {
    if (this.bindingKeys[key]) {
      this.manager.release(key);
      delete this.bindingKeys[key];
    }
  }

  public isLoaded() {
    return this.dataLoaded;
  }
  protected setLoaded() {
    if (!this.dataLoaded) {
      const l = this.initialLoad;
      if (l) {
        this.initialLoad = undefined;
        l(this.data);
      }
      this.dataLoaded = true;
      Object.values(this.bindings).forEach((cb) => cb(this.data));
    }
  }
  protected setData(data: T[]) {
    this.dataLoaded = true;
    const filter = this.itemFilter;
    this.data = makeKeyMapGeneric(filter ? data.filter(filter) : data, this.getKey);
    const l = this.initialLoad;
    if (l) {
      this.initialLoad = undefined;
      l(this.data);
    }
    Object.entries(this.localData).forEach(([key, value]) => {
      if (value) {
        if (this.data[key]) {
          delete this.localData[key];
        } else {
          this.data[key] = value;
        }
      } else {
        delete this.data[key];
      }
    });
    Object.values(this.bindings).forEach((cb) => cb(this.data));
  }

  private findKey(data: T | string) {
    if (this.itemValidator) {
      if (this.itemValidator(data)) {
        return this.getKey(data);
      } else if (typeof data === 'string') {
        return data;
      } else {
        return '';
      }
    } else if (typeof data === 'string') {
      return data;
    } else {
      return this.getKey(data);
    }
  }

  public addItem(data: T) {
    if (this.itemFilter && !this.itemFilter(data)) {
      return;
    }
    const key = this.getKey(data);
    this.localData[key.toString()] = data;
    this.data[key.toString()] = data;
    Object.values(this.bindings).forEach((cb) => cb(this.data));
  }

  public deleteItem(data: T | string) {
    const key = this.findKey(data);
    this.localData[key.toString()] = undefined;
    delete this.data[key.toString()];
    Object.values(this.bindings).forEach((cb) => cb(this.data));
  }

  public clearItem(data: T | string) {
    const key = this.findKey(data);
    delete this.localData[key.toString()];
    delete this.data[key.toString()];
    Object.values(this.bindings).forEach((cb) => cb(this.data));
  }
  protected isBound() {
    return this.bound;
  }

  public bind() {
    this.bound = true;
  }

  public unbind() {
    this.bound = false;
    Object.keys(this.bindingKeys).forEach((key) => {
      this.manager.release(key);
    });
    this.bindingKeys = {};
  }

  public hasLoaded(): boolean {
    return this.dataLoaded;
  }

  public getDataList(): T[] {
    return Object.values(this.data);
  }

  public getData(): KeyMap<T> {
    return this.data;
  }
}

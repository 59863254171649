import React, { Component, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import { Panel, PanelBody, PanelHeader } from "../panel/panel";
import { line_series_options, timeline_series_options } from "./graph_options";


interface IProps {

    series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined,
    title: string,
    height: number,
    type?: "area" | "line" | "bar" | "pie" | "donut" | "radialBar" | "scatter" | "bubble" | "heatmap" | "candlestick" | "boxPlot" | "radar" | "polarArea" | "rangeBar" | "treemap" | "histogram" | undefined,
    panel?: boolean,


}




export function Graph_Builder(props: IProps) {
    const { series, title, height, panel, type } = props

    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);
    const [resize, setResize] = useState<number>(0);
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
        setScreenHeight(window.innerHeight);
    };

    let options = {}

    const handleExpand = (chart: any, options: any, e: Event) => {
        setResize(resize === height ? screenHeight * 0.7 : height)
    }

    switch (type) {
        case 'rangeBar':
            options = timeline_series_options
            break;

        default:
            options = line_series_options(title || '', `${title}`, false, handleExpand)
            break;
    }




    useEffect(() => {
        window.addEventListener('resize', handleResize);
        setResize(height)
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <ReactApexChart options={options as ApexCharts.ApexOptions} series={series} type={type} height={screenWidth < 900 ? (screenHeight * 0.7) : resize} />
        // <ReactApexChart options={options} series={series} type={type || 'line'} height={height} />
    )

}
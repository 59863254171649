import { connect } from "react-redux";
import { IRedux } from "../../Interfaces/redux";
import { useEffect, useState } from "react";
import { ApiInterfaces } from "../../Interfaces";
import APIManager from "../../Server/Api/APIInterface";
import { Col, Row } from "react-bootstrap";
import { Panel, PanelBody } from "../panel/panel";
import Loader from "../Utils/Loader";
import { store } from "../../store";
import { ReduxActions } from "../../redux/actions";
import MapContainer from "../Maps/map";
import DeviceMapContainer from "../Maps/DeviceMapWrapper";
import MqttManager, { GetMqtt } from '../../Server/Api/MqttManager';


const MapView: React.FC<IRedux.IAuth> = ({ user, accessToken, mqttToken }) => {
    const [list, setList] = useState<ApiInterfaces.ListDevices | undefined>(undefined)
    const [showModal, setShowModal] = useState(false);
    

    useEffect(() => {

        const fetchData = async () => {

            ReduxActions.ShowLoader()
            const res = await APIManager.ApiRequest(`management.devices.list`, { id: user.managementsId || "", accessToken })
            if (res.type === 'management.devices.list') setList(res.data)
            ReduxActions.HideLoader()

        }
        fetchData()
        return () => {
            // const instance = MqttManager.getInstance(mqttToken).getMqttService();
            
            GetMqtt().unsubscribeAll()

        };
    }, []);

    return (
        <>
            <Row>
                <Col xs={12}>
                    <Panel theme={'inverse'} reload={false} style={{ marginBottom: '0rem' }} >
                        <PanelBody className="p-6">
                            <h3 className="text-center"> {list ? 'Map View' : 'Loading...'} </h3>
                        </PanelBody>
                    </Panel >
                </Col>
            </Row>
            <hr />
            <Panel className={'h-100'} theme={'inverse'} reload={false} style={{ marginBottom: '0rem' }} >
                <PanelBody className="p-4 h-100"  >
                    {list && <DeviceMapContainer
                        height={"75vh"}
                        width={"100%"}
                        center={{ lat: Number(list.devices[0].lat), lng: Number(list.devices[0].lng) }}
                        devices={list.devices}
                        nodes={list.loraNodes}
                        cameras={list.cameras}
                    />}

                    {/* {list && <MapContainer devices={list.devices} height={`800px`} width={`100%`} defaultLat={-30.2256274416054} defaultLng={149.8191368909956} />} */}
                </PanelBody>
            </Panel >

        </>
    );
};


const mapStateToProps = (state: IRedux.MapState) => {
    const { user, isLoggedIn, accessToken, refreshToken, mqttToken } = state.auth;
    return {
        user,
        isLoggedIn,
        accessToken,
        refreshToken,
        mqttToken
    };
};

const connector = connect(mapStateToProps);

export default connector(MapView);

import { useRef, useEffect } from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import videojs from "!video.js";
import 'video.js/dist/video-js.css';

const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null);
  const player = useRef(null);

  useEffect(() => {
    // Initialize Video.js player
    if (videoRef.current) {
      const options = {
        responsive: true,
        autoplay: true,
        controls: true,
        sources: [{
          src,
          type: 'application/x-mpegURL'
        }]
      };

      player.current = videojs(videoRef.current, options);
      // Clean up on component unmount
      return () => {
        if (player.current) {
          player.current.dispose();
        }
      };
    }
  }, []);

  return (
    <video
      ref={videoRef}
      className="video-js vjs-big-play-centered vjs-fill"
    />
  );
};

export default VideoPlayer;
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ApiInterfaces } from '../../Interfaces';
import { IRedux } from '../../Interfaces/redux';
import APIManager from '../../Server/Api/APIInterface';
import { Graph_Builder } from '../Graphs';
import { generateTimelineApexCharts } from '../Graphs/graph_options';
import Loader from '../Utils/Loader';
import { LoraHelper } from '../../Helper/LoraHelper';
import MultiSelectDropdown, { MultiSelectOption } from '../Utils/DropdownSelector';
import TimeSelect from '../Utils/TimerSelector';
import { AGMAC_Timers, weekday } from '../../Helper/Timers';
import { HelperTime } from '../../Helper/Time';


interface TimersModalProps {
    show: boolean;
    handleClose: () => void;
}


function TimerModal(props: TimersModalProps & IRedux.IAuth) {
    const { show, handleClose } = props;
    const [loading, setLoading] = useState(false);
    const [series, setSeries] = useState<ApexAxisChartSeries>([])
    const [nodes, setNodes] = useState<ApiInterfaces.LoraNode[] | undefined>(undefined)
    const [day, setDay] = useState<Date>(new Date())
    const [weekdays, setWeekdays] = useState<string[]>([])
    const [duration, setDuration] = useState<number | undefined>(undefined)

    const [selectedNodes, setSelectedNodes] = useState<ApiInterfaces.LoraNode[]>([]);
    const [startTime, setStartTime] = useState<string>('');

    useEffect(() => {
        show && getNodes(props.user.companyId)
        show && resetStates()
    }, [props.show]);

    const resetStates = () => {
        setWeekdays([]);
        setDuration(undefined);
        setSelectedNodes([]);
        setStartTime('');
    };


    const getNodes = async (cid: string) => {
        const res = await APIManager.ApiRequest('node.list', { companyId: cid, accessToken: props.accessToken })
        if (res.type === 'node.list') {
            setNodes(res.data)
            setSeries(generateTimelineApexCharts(res.data, day.getDay()))
            // setChartData({ options: timeline_series_options, series: generateTimelineApexCharts(res.data, day) });
        }
    }

    const handleSelectChange = (selected: MultiSelectOption[]) => {
        nodes && setSelectedNodes(LoraHelper.findLoraNodesByIds(nodes, selected.map(option => option.value)));
    };


    const handleTimeSelect = (hour: string, minute: string) => {
        const minutes = Number(hour) * 60 + Number(minute)
        setDuration(minutes)

    };


    const handleDateChange = async (isAdd: boolean) => {
        const numDays = isAdd ? 1 : -1;
        
        await getNodes(props.user.companyId)
        setDay(prevDay => {
            
            const newDate = new Date(prevDay.getTime() + numDays * 24 * 60 * 60 * 1000);
            (nodes && generateTimelineApexCharts(nodes, newDate.getDay()))
            nodes && setSeries(generateTimelineApexCharts(nodes, newDate.getDay()));
            return newDate;
        });

    };

    const handleUpdateGraph = (event: any) => {
        nodes && setSeries(generateTimelineApexCharts(nodes, day.getDay()));
    }




    return (
        nodes ? <Modal show={show} onHide={handleClose} dialogClassName="graph_modal">
            <Modal.Header closeButton>
                <Modal.Title>{`Schedule`}</Modal.Title>
            </Modal.Header>
            <Modal.Body  >
                <Loader loading={loading} />

                <div className={`${loading ? 'blur-component ' : ''}`}>
                    <div className="d-flex flex-wrap gap-3">
                        <MultiSelectDropdown options={AGMAC_Timers.weekOptions} onSelect={(selected: MultiSelectOption[]) => setWeekdays(selected.map(op => op.value))} defaultTitle='Select Days' />
                        <MultiSelectDropdown options={nodes.map(({ id, name }) => ({ value: id, label: name }))} onSelect={handleSelectChange} defaultTitle='Select Timers' />
                        <input
                            type="time"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                        />
                        <TimeSelect onTimeSelect={handleTimeSelect} />
                        <Button size='sm' variant='success' disabled={!(selectedNodes.length > 0 && weekdays.length > 0 && startTime.length > 0 && duration)}
                            onClick={() => AGMAC_Timers.updateTimer(selectedNodes, (weekdays as weekday[]), startTime, (duration || 0), props.accessToken)}>
                            Update Schedule
                        </Button>
                        &nbsp; &nbsp; &nbsp; &nbsp;
                        <Button size='sm' variant='danger' onClick={async () => {
                            AGMAC_Timers.resetCron(props.accessToken, props.user.companyId)
                            await getNodes(props.user.companyId)
                        }}>
                            Reset Timers
                        </Button>

                    </div>
                    <hr />
                    <div className="d-flex flex-wrap gap-3 justify-content-center">
                        <Button size='sm' variant='outline-warning'
                            onClick={() => handleDateChange(false)}>
                            <i className="fas fa-chevron-left"></i>
                        </Button>
                        {HelperTime.DateOnlyWeekBeautify(day)}
                        <Button size='sm' variant='outline-warning'
                            onClick={() => handleDateChange(true)}>
                            <i className="fas fa-chevron-right"></i>
                        </Button>
                    </div>
                    <hr />
                    <Graph_Builder title={'Timers'} series={series} type={'rangeBar'} height={450} panel={false} />
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button variant='danger' onClick={handleClose}>  <i className="far fa-times-circle"></i> &nbsp; Close</Button>
            </Modal.Footer>
        </Modal >
            : <></>



    );
}

const mapStateToProps = (state: IRedux.MapState) => {

    const { user, isLoggedIn, accessToken, refreshToken, mqttToken } = state.auth;
    return {
        user,
        isLoggedIn,
        accessToken,
        refreshToken,
        mqttToken
    };
}

const connector = connect(mapStateToProps)


export default connector(TimerModal)

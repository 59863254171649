import React, { } from 'react';
import { Form, Row } from 'react-bootstrap';
import { Group as GroupProps, getWidth, Collection } from './Interface';
import { Base, Props as BaseProps, State as BaseState } from './Base';

export interface Props extends BaseProps, GroupProps {
  childItems: React.ReactNode[],
  children?: React.ReactNode
}

export interface State extends BaseState {

}

export class Group extends Base<Props, State> {
  public constructor(props: Props) {
    super(props, {});
  }
  public render() {
    this.bindState()
    const blocks: any[][] = [];
    let block: any[] = [];
    let blockSize = 0;
    (this.props.childItems).forEach((child, index) => {
      const item = this.props.items[index];
      if (item.type === 'collection') {
        if (blockSize > 6) {
          blocks.push(block);
          block = [];
          blockSize = 0;
        }
        block.push(child);
        blocks.push(block);
        block = [];
        blockSize = 0;
      } else {
        const width = getWidth((item).width).md || 0;
        if (blockSize + width > 12) {
          blocks.push(block);
          block = [];
          blockSize = 0;
        }
        block.push(child);
        blockSize += width;
      }
    });
    if (block.length > 0) {
      blocks.push(block);
    }
    return <div key={this.props.key} className={this.props.className} style={this.shouldRender() ? undefined : { display: 'none' }}>
      {blocks.map((block, index) => <Row key={this.props.id}>
        {block}
        {this.state.valid ? undefined : <Form.Control.Feedback className="section-invalid" key='negative-feedback' type="invalid">{this.state.validationMessage || '* invlaid'}</Form.Control.Feedback>}
      </Row>)}
    </div>;
  }
}
import { KeyMap } from "../../../Helper/Core/interface";
import { Events } from "../../Events/Events";
import { DataSet } from "../DataSet";

export type DataType = Omit<Events['device.storage.get']['response'], "type">;
const type = 'device.storage.get';

export class Binding extends DataSet<DataType> {
    public constructor(private accessToken: string, private deviceId: string, private companyId:string,cb?: (data: KeyMap<DataType>) => void) {
        super(i => i.id, cb);
    }
    public async bind() {
        super.bind();

        this.registerBindingKey(await this.manager.bind(type, {
            accessToken: this.accessToken,
            id: this.deviceId,
            topic: `${this.companyId}/${this.deviceId}/storage`,
        }, async (res) => {
            if (res.type === type) {
                const {type, ...data} = res
                this.setData([data]);
            } else {
                this.setLoaded();
            }
        }));
    }
}
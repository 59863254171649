import React, { FC, useEffect, useState } from 'react';
import VideoPlayer from '../Utils/Player';
import { ApiInterfaces } from '../../Interfaces';
import { Button } from 'react-bootstrap';
import './dpad.css';
import { IRedux } from '../../Interfaces/redux';
import { connect } from 'react-redux';
import MqttManager, { GetMqtt } from '../../Server/Api/MqttManager';


interface StreamPlayerProps {
    hls: string;
    camera: ApiInterfaces.VpnCamera;
}

interface ReduxProps extends IRedux.IAuth { }

type Props = StreamPlayerProps & ReduxProps;

const CameraPlayer: FC<Props> = ({ hls, camera, mqttToken, user }) => {
    // const instance = MqttManager.getInstance(mqttToken || '').getMqttService();

    const move = (command: string) => GetMqtt().sendMessage(`${user.companyId}/CameraControl/`, command);


    return (
        <>
            <VideoPlayer src={hls} />
            {camera.ptz && (
                <div className="set setbg white">
                    <nav className="d-pad">
                        <a className="up" href="#" onClick={() => move('up')}></a>
                        <a className="right" href="#" onClick={() => move('right')}></a>
                        <a className="down" href="#" onClick={() => move('down')}></a>
                        <a className="left" href="#" onClick={() => move('left')}></a>
                    </nav>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state: IRedux.MapState): ReduxProps => {
    const { user, isLoggedIn, accessToken, refreshToken, mqttToken } = state.auth;
    return {
        user,
        isLoggedIn,
        accessToken,
        refreshToken,
        mqttToken
    };
};

const connector = connect(mapStateToProps);

export default connector(CameraPlayer);
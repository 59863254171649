


// Function to generate fake data for LIDStorage
import {faker} from "@faker-js/faker";
import {ApiInterfaces} from "../../Interfaces";

function generateFakeData(): ApiInterfaces.LIDStorage {
    const storageCurveLength = faker.number.int({ min: 1, max: 3 });
    const storageCurve: ApiInterfaces.LIDStorage['storageCurve'] = Array.from({ length: storageCurveLength }, () => ({
        elevation: faker.number.float(),
        area: faker.number.float(),
        volume: faker.number.float(),
    }));

    return {
        id: faker.string.uuid(),
        deviceId: faker.string.uuid(),
        Area: faker.number.float({ min: 0, max: 1000 }),
        Volume: faker.number.float({ min: 0, max: 1000 }),
        Bottom_mHAD: faker.number.float({ min: 0, max: 1000 }),
        Top_mHAD: faker.number.float({ min: 0, max: 1000 }),
        Depth: faker.number.float({ min: 0, max: 1000 }),
        LOT: faker.string.alphanumeric({length:5}),
        ESID: faker.string.alphanumeric({length:5}),
        TopBank: faker.number.float({ min: 0, max: 1000 }),
        TopWaterHeight: faker.number.float({ min: 0, max: 1000 }),
        Walkway: faker.number.float({ min: 0, max: 1000 }),
        Height: faker.number.float({ min: 0, max: 1000 }),
        storageCurve,
    };
}

// Example usage:
const fakeStorage: ApiInterfaces.LIDStorage = generateFakeData();
export default fakeStorage
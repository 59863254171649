import { SHOW, CLEAR, alertTypes } from './actionInterface'

const clear = () => ({
  type: CLEAR,
  payload: {
    messagealert:null,
    duration:null,
    type:null,
    key:null
  }
})

const show = (messagealert: string, duration:number, type:string, key:any) => (
  {
  type: SHOW,
  payload: {
    messagealert, duration, type, key
  },
})

const showError = (messagealert: string) => show(messagealert, 5000, alertTypes.ERROR, Math.floor(Math.random() * 100))
const showSuccess = (messagealert: string) => show(messagealert, 5000, alertTypes.SUCCESS, Math.floor(Math.random() * 100))
const showWarning = (messagealert: string) => show(messagealert, 5000, alertTypes.WARNING, Math.floor(Math.random() * 100))
const showInfo = (messagealert: string) => show(messagealert, 5000, alertTypes.INFO, Math.floor(Math.random() * 100))

export {
  show, showError, showSuccess, showWarning, showInfo, clear,
}
// Sidebar.tsx
import React, {useEffect, useState} from 'react';
import {Button, Form, Nav} from 'react-bootstrap';
import {connect} from 'react-redux';
import {IRedux} from '../../Interfaces/redux';
import {ApiInterfaces} from '../../Interfaces';
import {store} from '../../store';
import {updateCompanyId} from '../../redux/actions/authAction';
import {useNavigate} from 'react-router-dom';

interface SidebarProps extends IRedux.IAuth {
    show: boolean;
    companies: ApiInterfaces.Company[]

    toggle(): void
}

const CompanySelector: React.FC<SidebarProps> = ({show, toggle, companies}) => {


    const [selectedOption, setSelectedOption] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const handleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setSelectedOption(event.target.value);
        store.dispatch(updateCompanyId(`${event.target.value}`));
        navigate(0);
    };

    const handleSearchChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setSearchTerm(event.target.value);
    };


    const filteredOptions = companies.filter((item) =>
        item.name_Cia.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const optionStyle = {
        fontSize: '18px', // Adjust the font size as needed
        fontWeight: 'bold',
    };

    return (
        <>
            {show &&
                <Form style={{width: "92%"}} className={'mt-3'}>
                    <Form.Group>
                        <Form.Control
                            type="text"
                            placeholder="&#128269; Search..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <hr/>
                        {filteredOptions.map((cia, index) => (
                            <>
                                <Form.Check
                                    key={index}
                                    type="radio"
                                    label={cia.name_Cia}
                                    name="formHorizontalRadios"
                                    id={`formHorizontalRadios${index}`}
                                    value={cia.id}
                                    checked={selectedOption === cia.id}
                                    onChange={handleChange}
                                    style={optionStyle} // Apply custom styles to the option
                                />
                                <br/>
                            </>
                        ))}
                    </Form.Group>
                    <hr/>
                    <div className="d-grid gap-2">
                        <Button variant="secondary" onClick={() => toggle()}>
                            <i className="fas fa-times"></i> - Cancel
                        </Button>
                    </div>
                    <hr/>
                </Form>
            }
        </>

    );
};


const mapStateToProps = (state: IRedux.MapState) => {
    const {user, isLoggedIn, accessToken, refreshToken, mqttToken} = state.auth;
    return {
        user,
        isLoggedIn,
        accessToken,
        refreshToken,
        mqttToken
    };
};

const connector = connect(mapStateToProps);

export default connector(CompanySelector);
import React, { } from 'react';
import { Form } from 'react-bootstrap';
import { Section as SectionProps } from './Interface';
import { Base, Props as BaseProps, State as BaseState } from './Base';

export interface Props extends BaseProps, SectionProps {
  children?: React.ReactNode
}

export interface State extends BaseState {

}

export class Section extends Base<Props, State> {

  public constructor(props: Props) {
    super(props, {});
  }
  public render() {
    this.bindState()
    return <div className={`${this.props.className}`} key={this.props.key} style={this.shouldRender()? undefined:{display: 'none'}}>
      {this.props.title?[ <p className="fs-5 text-center font-weight-bold">{this.props.title}</p>,<hr/>]:undefined}
      {this.props.children}
      {this.state.valid?undefined:<Form.Control.Feedback className="section-invalid" key='negative-feedback' type="invalid">{this.state.validationMessage || '* invlaid'}</Form.Control.Feedback>}
    </div>;
  }
}
import React, {useRef} from "react";
import {FormAction, Section, InputValue, Collection, Row, List,Input, Group} from "../Utils/Forms/Interface";
import {DynamicForm} from "../Utils/Forms/Form";
import {IRedux} from "../../Interfaces/redux";
import {useSelector} from "react-redux";
import {FiPlus, FiTrash} from "react-icons/fi";
import {ApiInterfaces} from "../../Interfaces";
import {HelperFunctions} from "../../Helper/functions";
import {Button} from "react-bootstrap";
import {GiCancel} from "react-icons/gi";
import {ReduxActions} from "../../redux/actions";


type formdata = Partial<ApiInterfaces.Summary> & { id: string };

interface IProps {
    section: formdata;
    devices: ApiInterfaces.Device[]
    saveSection:(newSection: ApiInterfaces.Summary) => void;
    cancelSection:()=>void;
    newItem?: boolean
}

function SectionTemplate(props: IProps): JSX.Element {
    const {section, devices, newItem,cancelSection,saveSection} = props
    const {user} = useSelector((state: IRedux.MapState) => state.auth);
    const DevOtions = HelperFunctions.OptionKeyMapGenerator(devices, "id", "name", false)

    const formRef = useRef<FormAction<formdata> | undefined>(undefined);


    const reportFieldMappings: { [key: string]: string } = {
        average: "average",
        min: "min",
        max: "max",
        sum: "sum",
        stdDeviation: "stdDeviation",
        count: "count",
        range: "range",
        median: "median",
        mode: "mode",
    };


    async function getForm() {
        const f = formRef.current;
        const data = f?.getFormData();
        f?.setFormValidation(true)
        if (data && f?.getFormValidation()) {
            const filteredRows = data.rows?.filter(row => Object.values(row).every(value => value !== undefined));
            if(!filteredRows || filteredRows.length === 0) {
                ReduxActions.ShowError('Must Select at least one Row')
                return
            }
            saveSection({
                ...data,
                rows: filteredRows,
                title: data.title || ""
            })
        }
    }

    const items: (Row | List | Input | Group | Collection<Row | List | Input | Group>)[] = []

    if (section.rows) {
        Object.entries(section.rows).forEach(([key,v]) => {
            console.log(key,v)
            const dev = devices.find(x => x.id === v.deviceId)
            items.push(
                {
                    type: "row",
                    id: `summaryRows-${key}`,
                    items: [
                        {type: "dropdown", id: `summaryRowDeviceId_${key}`, label: "Device ID", value: `[rows.AKey-${key}.deviceId]`, width: 4, options: DevOtions},
                        {type: "dropdown", id: `summaryRowData_${key}`, label: "Data", width: 3, value: `[rows.AKey-${key}.data]`, options:HelperFunctions.MetadataOptions(dev?.metadata || {}) } ,
                        {type: "dropdown", id: `summaryRowOperation_${key}`, label: "Operation", width: 3, value: `[rows.AKey-${key}.operation]`, options: reportFieldMappings},
                        {type: "button", id: `removeSummary_${key}`, icon: <FiTrash/>, label: "Delete Row", width: 2, style: "outline-danger", value: "", onClick: () => deleteRow(key)},

                    ],
                }
            )
        })
    }

    const Template: Section = {
        type: "section",
        title: `${newItem ? 'Create' : 'Edit'} Summary Section`,
        id: "main",
        items: [
            {
                type: "row",
                id: "mainRow",
                items: [
                    {type: "text", id: `title`, label: "Summary Title", value: "[title]", width: 12},
                ],
            },
            ...items
        ],
    };

    let rowCounter = items.length;

    function addSummaryRow() {
        const f = formRef.current;
        const id = `${rowCounter++}`;
        const data = f?.getFormData() || {id: '', rows: []};
        data.rows = data.rows || [];
        data.rows.push({data: '', deviceId: '', operation: ''});

        f?.addFormContentLast([
            {
                type: "row",
                id: `summaryRows-${id}`,
                items: [
                    {type: "dropdown", id: `summaryRowDeviceId_${id}`, label: "Device ID", value: `[rows.AKey-${id}.deviceId]`, width: 4, options: DevOtions},
                    {type: "dropdown", id: `summaryRowData_${id}`, label: "Data", width: 3, value: `[rows.AKey-${id}.data]`, hidden: true},
                    {type: "dropdown", id: `summaryRowOperation_${id}`, label: "Operation", width: 3, value: `[rows.AKey-${id}.operation]`, options: reportFieldMappings, hidden: true},
                    {type: "button", id: `removeSummary_${id}`, icon: <FiTrash/>, label: "Delete Row", width: 2, style: "outline-danger", value: "", onClick: () => deleteRow(id)},

                ],
            },
        ], data as unknown as InputValue);

    }

    function deleteRow(id: string) {
        console.log(id)
        const f = formRef.current;
        f?.replaceFormContent([],`summaryRows-${id}`);
    }


    return (
        <>
            <hr/>
            <DynamicForm<formdata>
                actionTriggered={(id, action, form) => {
                    formRef.current = form;
                    console.log(id, action)
                    return true;
                }}
                valueChanged={(id, value, valid, form) => {
                    // console.log(id, value)
                    if (id.includes('summaryRowDeviceId') && value) {
                        const rowId = id.split('_').pop(); // Extract the unique part of the ID


                        if ((value as any).value) {
                            const dev = devices.find(x => x.id === (value as any).value)
                            if (dev) form.setOptions(`summaryRowData_${rowId}`, HelperFunctions.MetadataOptions(dev.metadata))
                        } else {
                            const dev = devices.find(x => x.id === value)
                            if (dev) form.setOptions(`summaryRowData_${rowId}`, HelperFunctions.MetadataOptions(dev.metadata))
                        }

                        form.setMultipleHidden(false, `summaryRowData_${rowId}`, `summaryRowOperation_${rowId}`);
                    }
                    return true;
                }}
                id="Template"
                inputData={section}
                content={[Template]}
            />
            <hr/>
            <div className="d-flex justify-content-between">
                <Button variant="outline-warning" className="d-flex align-items-center me-2" onClick={() => addSummaryRow()}>
                    <FiPlus size={23}/> Add Rows
                </Button>
            </div>
            <hr/>
            <div className="d-flex">
                <Button variant="outline-success" className="d-flex align-items-center mx-2" onClick={getForm}>
                    <FiPlus size={23}/> Save
                </Button>
                <Button variant="outline-secondary" className="d-flex align-items-center mx-2" onClick={cancelSection}>
                    <GiCancel size={23}/>  &nbsp;Cancel
                </Button>
            </div>
        </>


    );
}

export default SectionTemplate;

import { ReactElement, useEffect, useRef, useState } from "react";
import { Collection, FormAction, Group, Input, Section } from "../Utils/Forms/Interface";
import { DynamicForm } from "../Utils/Forms/Form";
import { connect } from "react-redux";
import { IRedux } from "../../Interfaces/redux";
import { Button, Modal } from "react-bootstrap";
import { ApiInterfaces } from "../../Interfaces";
import UnsavedModal from "../Utils/Unsaved";
import APIManager from "../../Server/Api/APIInterface";
import { ReduxActions } from "../../redux/actions";
import { BindingEvent } from "../../Server/Binding/Events/Events";
import { HelperFunctions } from "../../Helper/functions";
import { GetMqtt } from "../../Server/Api/MqttManager";



interface IProps extends IRedux.IAuth {
    show: boolean;
    handleClose: () => void;
    company: Partial<ApiInterfaces.Company> | undefined
}

type formdata = Partial<ApiInterfaces.Company>




function CompanyModal(props: IProps) {
    const { show, handleClose, company, accessToken } = props;
    const [dialog, setDialog] = useState<boolean>(false)
    const [form, setForm] = useState<FormAction<formdata> | undefined>(undefined);

    const [comp, setComp] = useState<formdata | undefined>(undefined)


    const formRef = useRef<FormAction<formdata> | undefined>(undefined)


    useEffect(() => {
        formRef.current = form
        setComp(company)
    }, [form, company]);



    const checkForChanges = () => {
        const f = formRef.current;
        const changed = f?.getFormChanged()
        if (changed) {
            setDialog(true)
        } else handleClose()
    }

    // async function getForm() {
    //     const f = formRef.current;
    //     const data = f?.getFormData()
    //
    //     if (data) {
    //         if (HelperFunctions.isNullOrUndefined(data.s_managementIds)) data.s_managementIds = [props.user.s_managementId]
    //         try {
    //             const res = await APIManager.ApiRequest(data.id ? 'company.edit' : 'company.create', { accessToken, ...data })
    //             if (res.type !== 'error') {
    //                 ReduxActions.ShowSuccess('Company details saved!')
    //                 BindingEvent.notifyChannels(data.id ? 'change' : 'add', BindingEvent.Event(res), { companyId: res.id, managementsId: res.managementsId || "" });
    //                 if(res.type === 'company.create') {
    //                     GetMqtt().sendMessage(`${props.user.managementsId}/command/global/create.company`, JSON.stringify({
    //                         cia: res.name_Cia,
    //                         cid: res.id,
    //                         management: props.user.managementsId
    //                     }))
    //                 }
    //
    //
    //                 handleClose()
    //             }
    //         } catch (error) {
    //             ReduxActions.ShowError('Unable to update company details')
    //         }
    //
    //
    //     }
    // }

    const onDrag = (event: any) => {
        const f = formRef.current;
        f?.setValue('lat', `${event.latLng.lat()}`)
        f?.setValue('lng', `${event.latLng.lng()}`)
    }

    const filterLogs: Section = {
        type: "section",
        title: "Company Form",
        id: "main",
        //className: "cs-location-section",
        items: [
            {
                type: "row",
                id: "Idsrow",
                //hidden: true,
                items: [

                    // { width: 5, type: "dropdown", id: "Section", value: "[section]", label: "Section:", hidden: true },
                    { width: 12, type: "text", id: "name", label: "Company Name", value: "[name_Cia]", hidden: false },
                    { width: 6, type: "number", id: "lat", label: "Coordinates Latitude", value: "[lat]", disabled: false },
                    { width: 6, type: "number", id: "lng", label: "Coordinates Longitude", value: "[lng]", disabled: false, },
                    { width: 12, label: "Drag Marker to new location", type: "map", height: '400px', id: "map", value: "", defaultZoom: 13, defaultCenter: { lat: comp?.lat ? Number(comp?.lat) : -30.313776166471115, lng: comp?.lng ? Number(comp?.lng) : 149.77699980507586 }, onDrag, disabled: true },

                    // { width: 2, type: "button", id: "submit", style: 'success', value: "Submit", hidden: false, onClick: () => getForm() },


                ],
            },

        ],
    };


    function renderForm() {
        return (
            comp ?
                <DynamicForm<formdata>
                    actionTriggered={(id, action, form) => {
                        setForm(form);
                        return true;
                    }
                    }
                    valueChanged={(id, value, valid, form) => {
                        return true;
                    }}
                    id="FilterVisits"
                    inputData={comp}
                    content={[filterLogs]}
                /> :
                <></>
        )
    }





    return (
        <>
            <UnsavedModal
                action={handleClose}
                show={dialog}
                handleClose={() => setDialog(false)}
            />
            <Modal show={show} onHide={checkForChanges} className="qc_modal" >
                <Modal.Header closeButton className="text-center">
                    <Modal.Title >{comp?.name_Cia ? comp?.name_Cia : "Create New Company"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {renderForm()}
                </Modal.Body>
                <Modal.Footer>
                    {/*<Button variant='success' onClick={getForm}>  &nbsp; <i className="far fa-save"></i>  &nbsp; Save</Button>*/}
                    <Button variant='danger' onClick={checkForChanges}>  &nbsp; <i className="far fa-times-circle"></i> &nbsp; Close</Button>
                </Modal.Footer>
            </Modal >
        </>

    )
}

const mapStateToProps = (state: IRedux.MapState) => {
    const { user, isLoggedIn, accessToken, refreshToken, mqttToken } = state.auth;
    return {
        user,
        isLoggedIn,
        accessToken,
        refreshToken,
        mqttToken
    };
};

const connector = connect(mapStateToProps);

export default connector(CompanyModal);
import React from 'react';
import './Sidebar.css';
import { useSelector} from 'react-redux';
import { IRedux } from '../../Interfaces/redux';
import { Row } from 'react-bootstrap';
import Chart from '../Graphs/Charts'
import {BindingHooks} from "../../Server/Binding/bidings/BindingHook";



const ChartList: React.FC = () => {

    const {user} = useSelector((state: IRedux.MapState) => state.auth)
    const {data} = BindingHooks.Device(user.companyId, 'ChartDev', true)

    return (
        <>
            {data && data.map((x) => (
                <Row>
                    <div className='mt-2 mb-3'>
                        <Chart device={x} key={x.id} type={'line'} />
                    </div>
                    <hr />
                </Row>
            ))}
        </>
    );
};


export default ChartList

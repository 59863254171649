import React from 'react';

interface LiquidFillGaugeProps {
    percentage: number;
}

const LiquidFillGauge: React.FC<LiquidFillGaugeProps> = ({ percentage }) => {
    return (
        <div>
            <svg width="300" height="340" viewBox="0 0 211 211">
                <defs>
                    <clipPath id="clip-path" >
                        <circle id="mask" cx="105.5" cy="105.5" r="103.5" transform="translate(312 -1822)" />
                    </clipPath>
                </defs>
                <g id="circle" transform="translate(-312 1822)">
                    <g id="bg" transform="translate(312 -1822)" fill="#8696a800" stroke="#000" strokeWidth={1}>
                        <circle cx="105.5" cy="105.5" r="105.5" stroke="none" />
                        <circle cx="105.5" cy="105.5" r="105" fill="none" />
                    </g>
                    <g id="water" clipPath="url(#clip-path)">
                        <path
                            id="waveShape"
                            d={`M500,118.244v293.11H4V${Math.round(215 - 2.1 * percentage)}.464c43.35,1.17,46.02,11.89,94.4,11.89,51.2,0,51.2-12,102.39-12s51.2,12,102.4,12,51.2-12,102.41-12C453.98,106.354,456.65,117.074,500,118.244Z`}
                            transform="translate(308 -1830.354)"
                            fill="#254986"
                            stroke="#212529"
                        />
                    </g>
                </g>
                <text x="105.5" y="130" textAnchor="middle" fontSize="36" fill="#ffffff" fontWeight="bold">
                    {percentage.toFixed(2)}%
                </text>
            </svg>
        </div>
    );
};

export default LiquidFillGauge;
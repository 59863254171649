import {ApiInterfaces} from "../Interfaces";
import APIManager from "../Server/Api/APIInterface";
import {IoTGetLastResponse, IoTListAllResponse} from "../Server/Events/base";
import {HelperFunctions} from "./functions";
import {HelperTime} from "./Time";

export namespace IoT {


    export const getLastValue = async (dev: ApiInterfaces.Device, accessToken: string): Promise<IoTGetLastResponse | undefined> => {
        const type = 'iot.get.last.value'
        const res = await APIManager.ApiRequest(type, {accessToken, deviceId: dev.id})
        if (res.type === type) return res
        return undefined
    }

    export const getAllValue = async (dev: ApiInterfaces.Device, accessToken: string): Promise<IoTListAllResponse | undefined> => {
        const res = await APIManager.ApiRequest('iot.list.all', {accessToken, deviceId: dev.id})
        if (res.type === 'iot.list.all') return res
        return undefined
    }


    export const getTimeSeriesData = async (dev: ApiInterfaces.Device, accessToken: string, start: Date, finish: Date) => {
        const res = await APIManager.snRequest('iot.list', {deviceId: dev.id, start, finish})
        if (res.type === 'iot.list') return res
        return undefined
    }

    export const getIdTimeSeriesData = async (id: string, start: Date, finish: Date) => {
        const res = await APIManager.snRequest('iot.list', {deviceId: id, start, finish})
        if (res.type === 'iot.list') return res
        return undefined
    }

    export const updatePanelData = (device: ApiInterfaces.Device, message: any, date?: Date): ApiInterfaces.metadata => {
        const properties = HelperFunctions.getAllPropertyNames(message);
        properties.forEach(x => device.metadata.hasOwnProperty(x) && (device.metadata[x].value = message[x]));
        device.metadata['LastSeen'] = {
            name: 'Last Seen',
            value: date? date.toString() : new Date().toString(),
            visible: false,
            io: date === undefined
        };
        delete device.metadata['Offline']
        return device.metadata
    };

    export const timeOutPanel = (device: ApiInterfaces.Device): ApiInterfaces.metadata => {
        device.metadata['Offline'] = {
            name: 'Last Seen',
            value: "",
            visible: false,
        };
        device.metadata['LastSeen'].io = undefined
        return device.metadata
    };

    export const timestampEachStorageData = (message:any) => {
        const now = new Date()
        const timestamps: {[key:string]:Date} = {}
        const properties = HelperFunctions.getAllPropertyNames(message);
        properties.forEach(x => timestamps[x] = now);
        return timestamps
    }

}
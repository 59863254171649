import React from 'react';
import { coerceToType, FormScaffold, InputValue, List as ListProps, ValueType } from './Interface';
import { Base, Props as BaseProps, State as BaseState } from './Base';

export interface Props extends BaseProps, ListProps {
  children?: React.ReactNode
}

export interface State extends BaseState {
  elements: FormScaffold[],
  flag: number,
}

export class List extends Base<Props, State> {
  public constructor(props: Props) {
    super({ ...props, state: {
      ...props.state,
      setValue: (cb, initial) => {
        props.state.setValue((value) => {
          this.replaceElements(value);
          cb(value);
        }, initial)
      }
    }}, {
      value: props.value,
      elements: [],
      flag: 0,
    });
    setTimeout(() => {
      this.replaceElements(this.state.value);
    }, 100)
    
  }

  private replaceElements(value: InputValue) {
    const data = coerceToType(value, ValueType.Array);
    let elements: FormScaffold[] = [];
    this.state.elements.forEach(e => {
      this.props.stateAccess.removeFormNode(e.id);
    })
    data.forEach((element, index) => {
      const k = this.props.itemKey? coerceToType(element, ValueType.Object)[this.props.itemKey]: undefined;
      const form = this.props.stateAccess.generateFormNode(this.props.items, coerceToType(k === undefined? index: k, ValueType.String), element);
      elements = elements.concat(form);
    })
    this.setState({ elements, flag: this.state.flag +1 });
  }

  protected bindingValueTransform(value: InputValue) {
    return value;
  }

  protected valid(value: InputValue) {
    return true;
  }
  
  public render() {
    this.bindState()
    return <div>
      {this.state.elements.map(i => i.node)}
    </div>;
  }
}
import { Panel, PanelBody } from "../panel/panel";
import { Card, Tab, Tabs } from "react-bootstrap";
import React from "react";
import { ApiInterfaces } from "../../Interfaces";
import Chart from "../Graphs/Charts";
import Highcharts from "highcharts";

type VolumeData = [number, number]; // [timestamp, volume]
interface MedianVolumeInfo {
    date: Date;
    medianVolume: number;
}

const customMetadata = [
    {
        "key": "Delta Volume(ML)",
        "color": "#32cc34",
        "negativeColor": "#FF0000",
        "values": [],
        "disabled": false,
        "context": "volume"
    },
]


export default function DailyChangeGraph(props: { device: ApiInterfaces.Device }) {

    const { device } = props

    const reducer = (series: Highcharts.SeriesOptionsType & { data: any }[]) => {
        for (let i = 0; i < series.length; i++) {

            const { medianVolumes, medianDifferences }: {
                medianVolumes: MedianVolumeInfo[],
                medianDifferences: { date: Date, difference: number }[]
            } = series[i].data.reduce((accumulator: {
                medianVolumes: MedianVolumeInfo[],
                medianDifferences: { date: Date, difference: number }[],
                currentDayVolumes: VolumeData[]
            }, currentValue: VolumeData, currentIndex: number, array: VolumeData[]) => {
                const currentTimestamp = currentValue[0];
                const currentDate = new Date(currentTimestamp).setHours(0, 0, 0, 0);
                // If it's a new day, calculate median and differences
                if (currentIndex > 0 && new Date(array[currentIndex - 1][0]).setHours(0, 0, 0, 0) !== currentDate) {
                    const sortedVolumes = accumulator.currentDayVolumes.map(([_, volume]) => volume).sort((a, b) => a - b);
                    const length = sortedVolumes.length;
                    const median = length % 2 === 0
                        ? (sortedVolumes[length / 2 - 1] + sortedVolumes[length / 2]) / 2
                        : sortedVolumes[Math.floor(length / 2)];
                    accumulator.medianVolumes.push({ date: new Date(array[currentIndex - 1][0]), medianVolume: median });
                    if (accumulator.medianVolumes.length > 1) {
                        const previousMedian = accumulator.medianVolumes[accumulator.medianVolumes.length - 2].medianVolume;
                        const currentMedian = accumulator.medianVolumes[accumulator.medianVolumes.length - 1].medianVolume;
                        const difference = currentMedian - previousMedian;
                        const previousDate = accumulator.medianVolumes[accumulator.medianVolumes.length - 2].date;
                        accumulator.medianDifferences.push({ date: previousDate, difference: difference });
                    }
                    accumulator.currentDayVolumes = [];
                }
                // Accumulate volumes for the current day
                accumulator.currentDayVolumes.push(currentValue);
                // For the last day, calculate median and differences
                if (currentIndex === array.length - 1) {
                    const sortedVolumes = accumulator.currentDayVolumes.map(([_, volume]) => volume).sort((a, b) => a - b);
                    const length = sortedVolumes.length;
                    const median = length % 2 === 0
                        ? (sortedVolumes[length / 2 - 1] + sortedVolumes[length / 2]) / 2
                        : sortedVolumes[Math.floor(length / 2)];
                    // Add median volume of the last day
                    accumulator.medianVolumes.push({ date: new Date(currentTimestamp), medianVolume: median });
                    // Calculate difference with previous day's median volume
                    if (accumulator.medianVolumes.length > 1) {
                        const previousMedian = accumulator.medianVolumes[accumulator.medianVolumes.length - 2].medianVolume;
                        const currentMedian = accumulator.medianVolumes[accumulator.medianVolumes.length - 1].medianVolume;
                        const difference = currentMedian - previousMedian;
                        const previousDate = accumulator.medianVolumes[accumulator.medianVolumes.length - 2].date;
                        accumulator.medianDifferences.push({ date: previousDate, difference: difference });
                    }
                }
                return accumulator;
            }, { medianVolumes: [], medianDifferences: [], currentDayVolumes: [] });

            const newS: any = []

            medianDifferences.forEach(m => {
                newS.push([m.date.getTime(), Number(m.difference.toFixed(2))])
            })
            series[i].data = newS
        }
    }


    return <Card className="mb-4" style={{ border: '1px solid #555', borderRadius: '8px' }}>
        <Card.Header className="text-center" style={{ backgroundColor: '#000', color: '#fff', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
            <h5 className="mb-1" style={{ fontSize: '1.0rem' }}><strong>{device.name}</strong> Daily Change in Volume</h5>
        </Card.Header>
        <Card.Body className="text-center">
            <Chart device={device} key={device.id} type={'bar'} shared={true} customMetadata={customMetadata} reducer={reducer} />
        </Card.Body>
    </Card>



    // <Panel theme={'inverse'}>
    //     <PanelBody className="p-0">
    //         <Tabs defaultActiveKey={`${device.id}`} id={`${device.id}`} className="mb-4" fill justify>
    //             <Tab key={`${device.id}`} eventKey={`${device.id}`}
    //                  title={`${device.name} Daily Change in Volume`} className="mb-3">
    //                 <Chart device={device} key={device.id} type={'bar'} shared={true} customMetadata={customMetadata} reducer={reducer}/>
    //             </Tab>
    //         </Tabs>
    //     </PanelBody>
    // </Panel>;
}
import React, { useState, useEffect, useRef } from 'react';
import { Badge, Button, Spinner, Alert } from 'react-bootstrap';
import APIManager from '../../Server/Api/APIInterface';
import { IRedux } from '../../Interfaces/redux';
import { store } from '../../store';
import { showError } from '../../redux/actions/alertsAction';
import { HelperTime } from '../../Helper/Time';
import { BindingEvent } from '../../Server/Binding/Events/Events';
import { BindingHooks } from "../../Server/Binding/bidings/BindingHook";
import { useSelector } from "react-redux";

const UserNotification = () => {
    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const notificationRef = useRef<HTMLDivElement>(null);
    const { user, accessToken } = useSelector((state: IRedux.MapState) => state.auth);

    const { data } = BindingHooks.UserNotification(user.id, user.managementsId || user.companyId, 'expo_notification');

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (isActive && notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
                setIsActive(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isActive]);

    const handleBellClick = () => {
        setIsActive(!isActive);
    };

    const handleNotificationClose = async (id: string) => {
        setLoading(true);
        try {
            const response = await APIManager.ApiRequest('user.expo.notification.read', { id, accessToken });
            if (response.type === 'user.expo.notification.read') {
                BindingEvent.notify("delete", BindingEvent.Event(response), `${user.managementsId ?? user.companyId}/${user.id}/notification`);
            }
        } catch (error) {
            setError('Failed to close notification');
        } finally {
            setLoading(false);
        }
    };

    const handleReadAll = async () => {
        setLoading(true);
        try {
            const response = await APIManager.ApiRequest('user.expo.notification.readall', { userId: user.id, accessToken });
            if (response.type === 'user.expo.notification.readall') {
                data?.forEach(t => {
                    BindingEvent.notify("delete", BindingEvent.Event({ type: 'user.expo.notification.read', id: t.id }), `${user.managementsId ?? user.companyId}/${user.id}/notification`);
                });
            }
        } catch (error) {
            store.dispatch(showError('Unable to delete notifications'));
        } finally {
            setLoading(false);
        }
    };

    function renderNotificationList() {
        if (loading) {
            return <Spinner animation="border" />;
        }

        if (error) {
            return <Alert variant="danger">{error}</Alert>;
        }

        return (
            data ? <>
                {data.map((notification) => (
                    <div className="card card-inverse grow" key={notification.id}>
                        <div className="card-block">
                            <h5 className="card-title m-t-5 m-b-10">
                                {notification.title}&nbsp;&nbsp;
                                <Badge bg={notification.importance.toLocaleLowerCase() === 'high' ? 'danger' : notification.importance.toLocaleLowerCase() === 'warning' ? 'warning' : 'secondary'}>
                                    {notification.importance}
                                </Badge>
                                <button type="button" aria-label="Close" className="close pull-right" onClick={() => handleNotificationClose(notification.id)}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </h5>
                            <h6 className="card-title m-b-15">{notification.message}</h6>
                            <footer className="f-s-12">
                                <span className="date">{HelperTime.formatRelativeTime(notification.created_at?.toString() || '')}</span>
                            </footer>
                        </div>
                    </div>
                ))}
                {data.length > 0 &&
                    <>
                        <hr />
                        <div className="d-grid gap-2">
                            <Button variant="success" onClick={handleReadAll}>
                                <i className="fab fa-readme"></i> &nbsp; Clear All Notifications
                            </Button>
                        </div>
                    </>}
            </> : <></>
        );
    }

    return (
        <div ref={notificationRef} className={`theme-panel theme-panel-lg ${isActive ? 'active' : ''}`}>
            <a href="#" className="theme-collapse-btn" onClick={handleBellClick}>
                <i className="fas fa-bell"></i>
                <Badge bg="success">{data?.length}</Badge>
            </a>
            <div className="theme-panel-content">
                <div className="padoso"></div>
                <h4 className="text-center">SierraNet Notifications</h4>
                <hr />
                {renderNotificationList()}
            </div>
        </div>
    );
};
export default UserNotification;
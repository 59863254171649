import React, { createRef, useRef, useState } from "react";
import { Collection, FormAction, Input, Section, Row } from "../Utils/Forms/Interface";
import { DynamicForm } from "../Utils/Forms/Form";
import { IRedux } from "../../Interfaces/redux";
import { Button, Modal, ListGroup, Card, Container, Row as BootstrapRow, Col } from "react-bootstrap";
import UnsavedModal from "../Utils/Unsaved";
import { BindingHooks } from "../../Server/Binding/bidings/BindingHook";
import { useSelector } from "react-redux";
import { FiPlus, FiTrash } from "react-icons/fi";
import { ApiInterfaces } from "../../Interfaces";
import SectionTemplate from "./Section";
import { v4 as uuid } from 'uuid';
import { ReduxActions } from "../../redux/actions";
import DataTemplate from "./DataGraph";
import { CiEdit, CiTrash } from "react-icons/ci";
import { FormTemplate } from "../Utils/Forms/Validation";
import { ApiHelper } from "../../Server/Events/ApiOperations";
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import ConfirmModal from "../Utils/ConfirmModal";

interface IProps {
    show: boolean;
    template: Partial<ApiInterfaces.Template>;
    devices: ApiInterfaces.Device[];
    handleClose: () => void;
}

type formdata = Partial<ApiInterfaces.Template> | undefined;
type dataForm = Partial<ApiInterfaces.DataReport> & { id: string };
type summaryForm = Partial<ApiInterfaces.Summary> & { id: string };

function Template(props: IProps): JSX.Element {
    const { show, handleClose, devices } = props;
    const { user } = useSelector((state: IRedux.MapState) => state.auth);
    const [dialog, setDialog] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [template, setTemplate] = useState<Partial<ApiInterfaces.Template>>(props.template)
    const [hasChanges, setHasChanges] = useState<boolean>(false);

    const [showSection, setShowSection] = useState<{ summary: boolean, data: boolean, value: dataForm | summaryForm }>({ summary: false, data: false, value: { id: '' } });

    const formRef = useRef<FormAction<formdata> | undefined>(undefined);

    const checkForChanges = () => {
        const f = formRef.current;
        const changed = f?.getFormChanged() || hasChanges;
        if (changed) {
            setDialog(true);
        } else handleClose();
    };

    const handleConfirmAction = async () => {
        await ApiHelper.op("template.delete", { id: template.id || "", companyId: user.companyId }, user.companyId)
        handleClose()
    }

    const templateOptions: { [key: string]: string } = {
        SummaryTable: "Summary Table",
        Graph: "Graph",
        DataTable: "Data Table",
    };

    async function getForm() {
        const f = formRef.current;
        if (!f) return
        const data = f.getFormData();
        const valid = await f.getYupValidation(FormTemplate)
        if (data && valid.isValid) {
            const upload = {
                ...template,
                title: data.title,
                companyId: user.companyId,
                created_at: template.created_at || new Date()
            } as ApiInterfaces.Template
            await ApiHelper.op(template.id ? "template.edit" : "template.create", upload, user.companyId)
            handleClose()
        }
    }

    async function addSection() {
        const f = formRef.current;
        const data = f?.getValue('templateOption');
        if (data === undefined) ReduxActions.ShowError('Please select valid Section')
        else if (data === 'SummaryTable') setShowSection({ summary: true, data: false, value: { id: uuid() } })
        else setShowSection({ summary: false, data: true, value: { id: uuid(), type: data === 'DataTable' ? 'table' : 'graph' } })
    }

    const Template: Section = {
        type: "section",
        title: "Template Form",
        id: "main",
        items: [
            {
                type: "row",
                id: "mainRow",
                items: [
                    { width: 12, type: "text", id: "title", label: "Template Name", value: "[title]" },
                    { width: 9, type: "dropdown", id: "templateOption", label: "Section", value: "[]", options: templateOptions },
                    { width: 3, type: "button", id: "add", icon: <FiPlus />, label: "Create Section", style: "outline-success", value: "", onClick: addSection },
                ],
            },
        ],
    };

    const deleteSection = (id: string) => {
        setTemplate((prevTemplate) => ({
            ...prevTemplate,
            section: prevTemplate.section?.filter(section => section.id !== id)
        }));
    };

    const saveSection = (newSection: ApiInterfaces.Summary | ApiInterfaces.DataReport) => {
        setTemplate((prevTemplate) => {
            const sections = Array.isArray(prevTemplate.section)
                ? [...prevTemplate.section]
                : [];

            const index = sections.findIndex(s => s.id === newSection.id);

            if (index === -1) {
                sections.push(newSection);
            } else {
                sections[index] = newSection;
            }

            return { ...prevTemplate, section: sections };
        });
        setShowSection({ value: { id: "" }, data: false, summary: false })
    };

    const cancelSection = () => setShowSection({ value: { id: "" }, data: false, summary: false })

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const reorderedSections = Array.from(template.section || []);
        const [removed] = reorderedSections.splice(result.source.index, 1);
        reorderedSections.splice(result.destination.index, 0, removed);

        setTemplate((prevTemplate) => ({
            ...prevTemplate,
            section: reorderedSections
        }));
        setHasChanges(true); // Flag changes when dragging
    };

    function renderForm() {
        return (
            <DynamicForm<formdata>
                actionTriggered={(id, action, form) => {
                    formRef.current = form;
                    return true;
                }}
                valueChanged={(id, value, valid, form) => {
                    if (id === "templateOption" && value) form.setHidden("add", false);
                    return true;
                }}
                id="Template"
                inputData={template}
                content={[Template]}
            />
        );
    }

    const renderContent = () => {
        return (
            <>
                <div style={{ display: !showSection.data && !showSection.summary ? 'block' : 'none' }}>
                    <Container>
                        <BootstrapRow className="mb-2">
                            <Col>
                                <h2 className="text-center">Create Template</h2>
                                <p className="text-center">Use the form below to create or edit a template.</p>
                            </Col>
                        </BootstrapRow>
                        {props.template.title && <>
                            <Button variant="outline-danger" className="mt-1 w-100 d-flex align-items-center justify-content-center" onClick={() => setConfirm(true)}>
                                <FiTrash size={23} className="me-2" />
                                <span>Delete Template</span>
                            </Button>
                            <hr />
                        </>}
                        {renderForm()}
                        <hr />
                        {template.section && <h3 className="text-center mb-3"> Report Sections </h3>}
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="sections">
                                {(provided) => (
                                    <ListGroup className="mt-3" {...provided.droppableProps} ref={provided.innerRef}>
                                        {template.section?.map((s, index) => (
                                            <Draggable key={s.id} draggableId={s.id} index={index}>
                                                {(provided) => (
                                                    <ListGroup.Item
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="d-flex justify-content-between align-items-center mb-0"
                                                    >
                                                        <Card className="w-100">
                                                            <Card.Body className="d-flex justify-content-between align-items-center p-5"> 
                                                                <span><strong className="d-none d-md-inline-block">{('type' in s) ? `${s.type.charAt(0).toUpperCase() + s.type.slice(1).toLowerCase()} Section` : "Summary Table "} : </strong> {s.title}</span>
                                                                <div>
                                                                    <Button variant="outline-warning" className="me-2" onClick={() => setShowSection({ value: s, data: ('type' in s), summary: !('type' in s) })}>
                                                                        <CiEdit size={23} />
                                                                    </Button>
                                                                    <Button variant="danger" onClick={() => deleteSection(s.id)}>
                                                                        <CiTrash size={23} />
                                                                    </Button>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </ListGroup.Item>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </ListGroup>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Container>
                </div>
                {showSection.data && !showSection.summary && (
                    <DataTemplate section={showSection.value} devices={devices} key={showSection.value.id} newItem={!showSection.value.title} cancelSection={cancelSection} saveSection={saveSection} />
                )}
                {!showSection.data && showSection.summary && (
                    <SectionTemplate section={showSection.value} devices={devices} key={showSection.value.id} newItem={!showSection.value.title} cancelSection={cancelSection} saveSection={saveSection} />
                )}
            </>
        );
    };

    return (
        devices ? <>
            <ConfirmModal
                message={"Are you sure you want to delete this template?"}
                title={"Confirm Delete Request"}
                action={handleConfirmAction}
                show={confirm}
                handleClose={() => setConfirm(false)}
            />
            <UnsavedModal action={handleClose} show={dialog} handleClose={() => setDialog(false)} />
            <Modal show={show} onHide={checkForChanges} className="qc_modal">
                <Modal.Header closeButton className="text-center">
                    <Modal.Title>Create Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {renderContent()}
                </Modal.Body>
                <Modal.Footer>
                    {!showSection.data && !showSection.summary && <>
                        <Button variant="success" onClick={getForm}>
                            &nbsp; <i className="far fa-save"></i> &nbsp; Save
                        </Button>
                        <Button variant="danger" onClick={checkForChanges}>
                            &nbsp; <i className="far fa-times-circle"></i> &nbsp; Close
                        </Button>
                    </>}
                </Modal.Footer>
            </Modal>
        </> : <></>
    );
}

export default Template;
import { KeyMap } from "../../../Helper/Core/interface";
import { Events } from "../../Events/Events";
import { DataSet } from "../DataSet";

export type DataType = Omit<Events['iot.get.last.value']['response'], "type">;
const type = 'iot.get.last.value';

export class Binding extends DataSet<DataType> {
    public constructor(private deviceId: string,cb?: (data: KeyMap<DataType>) => void) {
        super(i => i.id, cb);
    }
    public async bind() {
        super.bind();

        this.registerBindingKey(await this.manager.bind(type, {
            deviceId: this.deviceId,
        }, async (res) => {
            if (res.type === type) {
                const {type, ...data} = res
                this.setData([data]);
            } else {
                this.setLoaded();
            }
        }));
    }
}
import axios, { AxiosRequestConfig } from "axios";
import { ErrorResponse } from "./APIInterface";
import { ApiEventTypes, Events } from "../Events/Events";

export const apiRequest = <T extends ApiEventTypes>(
  type: T,
  event: Omit<Events[T]['request'], 'type'>,
  endpoint?: { hostname: string, path: string },
  config?: AxiosRequestConfig,
): Promise<Events[T]['response'] | ErrorResponse> => {
  const postData = JSON.stringify(Object.assign({ type, }, event));
  const accessToken = (event as { accessToken?: string }).accessToken
  return axios.post(
    `${endpoint ? endpoint.hostname : (process.env.REACT_APP_API)
    }/${endpoint ? endpoint.path : (type || '')
    }`
    , postData, Object.assign({}, config || {}, {
      headers: {
        'Content-Type': 'application/json',
        'cx-api-version': '2.0.0',
        'Authorization': accessToken ? `Bearer ${accessToken}` : ``
      }, timeout: config === undefined || config.timeout === undefined ? 300000 : config.timeout
    }))
    .then((response) => {

      if (response && response.data) {
        if (typeof (response.data) === 'object') {
          return response.data;
        } else {
          return JSON.parse((response as any).data.toString());
        }
      } else {
        return {};
      }
    });
};

export type ApiRequest = typeof apiRequest;



export async function asyncRequest<T extends ApiEventTypes>(
  type: T,
  event: Omit<Events[T]['request'], 'type'>,
  endpoint?: { hostname: string, path: string },
  config?: AxiosRequestConfig
): Promise<Events[T]['response'] | ErrorResponse> {

  // const { ...cloneEvent, accessToken, type } = event
  const { accessToken, type: types, ...baseEvent } = (event as { accessToken?: string, type?: string });

  const postData = JSON.stringify(Object.assign({ type, }, baseEvent));
  // const accessToken = (event as { accessToken?: string }).accessToken


  const response = await axios.post<Events[T]['response']>(
    `${endpoint ? endpoint.hostname : (process.env.REACT_APP_API)
    }/${endpoint ? endpoint.path : (type || '')
    }`
    , postData, Object.assign({}, config || {}, {
      headers: {
        'Content-Type': 'application/json',
        'cx-api-version': '2.0.0',
        'Authorization': accessToken ? `Bearer ${accessToken}` : ``
      }, timeout: config === undefined || config.timeout === undefined ? 300000 : config.timeout
    }))

  if (response && response.data) {

    if (typeof (response.data) === 'object') {
      return response.data;
    } else {
      return JSON.parse((response as any).data.toString());
    }
  }
  else return (response as unknown as ErrorResponse)
}

export type AsyncRequest = typeof asyncRequest;
import Select, {GroupBase, StylesConfig} from 'react-select';

// Define custom styles for dark theme
export const ReactSelectDarkTheme: StylesConfig<string, true, GroupBase<string>> = {
    control: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: '#212529',
        borderColor: '#444',
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: '#212529',
    }),
    option: (baseStyles, { isFocused, isSelected }) => ({
        ...baseStyles,
        backgroundColor: isSelected
            ? '#555'
            : isFocused
                ? '#063d56'
                : undefined,
        color: '#fff',
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: '#fff',
    }),
    multiValue: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: '#063d56',
    }),
    multiValueLabel: (baseStyles) => ({
        ...baseStyles,
        color: '#fff',
    }),
    multiValueRemove: (baseStyles) => ({
        ...baseStyles,
        color: '#fff',
        ':hover': {
            backgroundColor: '#777',
            color: '#fff',
        },
    }),
    input: (baseStyles) => ({
        ...baseStyles,
        color: '#fff',
    }),
};
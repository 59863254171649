import { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ApiInterfaces } from '../../Interfaces';
import { IRedux } from '../../Interfaces/redux';
import { FormAction, InputValue, Section } from '../Utils/Forms/Interface';
import { HelperFunctions } from '../../Helper/functions';
import { DynamicForm } from '../Utils/Forms/Form';
import * as Yup from 'yup';
import { store } from '../../store';
import { showError, showSuccess } from '../../redux/actions/alertsAction';
import APIManager from '../../Server/Api/APIInterface';


type Props = {
    handleClose: () => void;
    onAddOrEditUser: (user: ApiInterfaces.User) => void;
    onDeleteUser: (id: string) => void;
    show: boolean;
    companies: ApiInterfaces.Company[]
    isuser: Partial<ApiInterfaces.User> | undefined
}

type UserForm = Partial<ApiInterfaces.User & { isManager: boolean, confirmpassword?: string }>


function UserModal(props: Props & IRedux.IAuth) {
    const { show, handleClose, onAddOrEditUser, onDeleteUser } = props;
    const [user, setUser] = useState<UserForm | undefined>(undefined)
    const [form, setForm] = useState<FormAction<UserForm> | undefined>(undefined);
    const formRef = useRef<FormAction<UserForm> | undefined>(undefined)


    useEffect(() => {
        props.isuser ? setUser({ ...props.isuser, isManager: !!props.isuser?.managementsId }) : setUser(undefined)
        formRef.current = form

    }, [props.isuser, show]);


    const staticValidationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required.'),
        email: Yup.string().required('Email is required.').email('Invalid email format.'),
        company: Yup.string().required('Please select a company.'),
        password: Yup.string()
            .required('Password is required.')
            .min(8, 'Password must be at least 8 characters long.')
            .matches(
                /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                'Password must contain at least one letter, one number, and one symbol.'
            ),
        confirmpassword: Yup.string()
            .required('Confirm Password is required.')
            .test('password-match', 'Passwords must match.', function (value) {
                return value === this.resolve(Yup.ref('password'));
            }),
    });

    const UserDetails: Section = {
        type: "section",
        title: "User Form",
        id: "userform",
        //className: "cs-location-section",
        items: [
            {
                type: "row",
                id: "Idsrow",
                //hidden: true,
                items: [
                    { width: 6, type: "text", id: "name", value: "[name]", label: "Name:" },
                    { width: 6, type: "email", id: "email", value: "[email]", label: "Email:" },
                    { width: 6, type: "dropdown", id: "company", options: HelperFunctions.transformCompaniesToOptions(props.companies), value: "[companyId]", label: "Company:" },
                    { width: 6, type: "checkbox", id: "managment", value: "[isManager]", label: "Is Managment?" },
                    { width: 6, type: "password", id: "password", value: "[password]", label: "Password:" },
                    { width: 6, type: "password", id: "confirmpassword", value: "[confirmpassword]", label: "Confirm Password:" }
                ],
            },

        ],
    };
    

    async function getForm() {
        try {
            const f = formRef.current;
            if (f) {
                const validatedData = await f?.getYupValidation(staticValidationSchema);
                if (validatedData.isValid) {
                    const data = f.getFormData()
                    const { isManager, confirmpassword, ...user } = data
                    user.managementsId = isManager ? props.user.managementsId : null
                    const res = await APIManager.ApiRequest(props.isuser?.name ? 'user.edit' : 'user.create', { accessToken: props.accessToken, ...user })
                    if (res.type === props.isuser?.name ? 'user.edit' : 'user.create') {
                        store.dispatch(showSuccess(res.type === 'user.create' ? `${user.name} Created` : `Successfully updated ${user.name}`));
                        const { type, ...data } = res
                        onAddOrEditUser(data as ApiInterfaces.User)
                        handleClose()
                    }
                }
            }
        } catch (error) {
            console.error('Error:', error);
            store.dispatch(showError('Unable to create users'))
        }
    }

    async function deleteUser(toDelete: Partial<ApiInterfaces.User>) {
        try {
            const res = await APIManager.ApiRequest('user.delete', { accessToken: props.accessToken, managementsId: props.user.managementsId || '', userId: toDelete.id || '' })
            if (res.type === 'user.delete') {
                store.dispatch(showSuccess(`User deleted`));
                onDeleteUser(res.userId)
                handleClose()
            }
        } catch (error) {
            console.error('Error:', error);
            store.dispatch(showError('Unable to delete user'))
        }

    }





    return (
        <Modal show={show} onHide={handleClose} dialogClassName="user_modal" className="qc_modal">
            <Modal.Header closeButton>
                <Modal.Title>{`${user?.name || 'New User'}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body  >
                {user ?
                    <>
                        <DynamicForm<UserForm>
                            actionTriggered={(id, action, form) => {
                                setForm(form);
                                formRef.current = form
 
                                // form.setValue('text', 'Seu Cu')
                                return true;
                            }
                            }
                            valueChanged={(id, value, valid, form) => {
                                return true;
                            }}
                            id={'User Modal'}
                            inputData={user}
                            content={[UserDetails]}
                        />
                        <hr />
                        <div className="d-flex flex-wrap gap-3">
                            <Button variant="success" onClick={() => getForm()}>
                                <i className="fas fa-check-double"></i> Submit
                            </Button>
                            {user.name && <Button variant="danger" onClick={() => deleteUser(user)}>
                                <i className="fas fa-trash"></i> Delete User
                            </Button>}
                        </div>

                    </> : <></>

                }

            </Modal.Body>

            <Modal.Footer>
                <Button variant='danger' onClick={handleClose}>  <i className="far fa-times-circle"></i> &nbsp; Close</Button>
            </Modal.Footer>
        </Modal >
    );
}

const mapStateToProps = (state: IRedux.MapState) => {

    const { user, isLoggedIn, accessToken, refreshToken, mqttToken } = state.auth;
    return {
        user,
        isLoggedIn,
        accessToken,
        refreshToken,
        mqttToken
    };
}

const connector = connect(mapStateToProps)


export default connector(UserModal)

import * as UsersBinding from './Users'
import * as DeviceBinding from './Devices'
import * as CompanyBinding from './Company'
import * as LidStorageBinding from './LidStorage'
import * as UserNotificationBinding from "./UserNotification"
import * as ManagementBinding from "./Management"
import * as ReportBinding from "./Report"
import * as TemplateBinding from "./Template"
import * as IOTLastValueBinding from "./IoTLast"

import * as RefCompaniesBinding from "../refBindings/Company"
import * as RefDeviceBinding from '../refBindings/Devices'
import * as RefCameraBinding from '../refBindings/Camera'
import * as RefNodesBinding from '../refBindings/LoraNodes'
import * as RefNotificationBinding from '../refBindings/Notification'

import * as GetTemplateBinding from '../getBindings/Template'

import {DataSet} from "../DataSet";
import {BindingSet} from "../Binding";
import {useEffect} from "react";
import {ReduxActions} from "../../../redux/actions";


export namespace Bindings {
    export const User = UsersBinding
    export const Device = DeviceBinding
    export const UserNotification = UserNotificationBinding
    export const Company = CompanyBinding
    export const Management = ManagementBinding
    export const Report = ReportBinding
    export const Template = TemplateBinding
    export const LidStorage = LidStorageBinding
    export const IOTLastValue = IOTLastValueBinding

    /**************  Reference Binding  *****************/
    export const RefCompany = RefCompaniesBinding
    export const RefNotification = RefNotificationBinding
    export const RefDevice = RefDeviceBinding
    export const RefNodes = RefNodesBinding
    export const RefCamera = RefCameraBinding

    /**************  Get Binding  *****************/

    export const GetTemplate = GetTemplateBinding



    export const Hook = <T>(bind: DataSet<T>, bindId:string) => {
        const bindings: BindingSet = new BindingSet({});
        useEffect(() => {
            bindings.bind()
            bindings.add(bindId, bind);
            return () => {
                bindings.unbind()
            };
        }, []);
    }

    export const NewHook = <T>(cb: () => DataSet<T>, bindId: string, loader?:boolean): void => {
        const bindings: BindingSet = new BindingSet({});

        useEffect(() => {
            bindings.bind()
            loader && ReduxActions.ShowLoader()
            bindings.add(bindId, cb());
            return () => {
                bindings.unbind()
            };
        }, []);
    }




        
};
import {ApiEventTypes, Events} from "./Events";
import APIManager, {ErrorResponse} from "../Api/APIInterface";
import {ReduxActions} from "../../redux/actions";
import {BindingEvent, BindTopics} from "../Binding/Events/Events";

export namespace ApiHelper {

    export const op = async <T extends ApiEventTypes>(type: T, payload: Omit<Events[T]["request"], "type">, cid:string) => {
        try {
            const res = await APIManager.snRequest(type, payload) as  Events[T]["response"]
            ReduxActions.ShowSuccess(`Action ${type} completed successfully`)
            notify(type,BindTopics.build(type, res, cid), res)
            return res

        } catch (e) {
            ReduxActions.ShowError(`Request ${type} Failed, Please contact SierraTek Support for more information`)
            return undefined
        }
    }

    export function notify<T extends ApiEventTypes>(type: T, topic: string[], res: ErrorResponse | Events[T]["response"] | undefined) {
        let suffix:'add' | 'delete' | 'change' | 'list'
        if(type.includes('create')) suffix = 'add'
        else if(type.includes('edit')) suffix = 'change'
        else if(type.includes('list')) suffix = 'list'
        else if(type.includes('delete')) suffix = 'delete'
        else return
        if (res && res.type === type && "id" in res && topic.length > 0) topic.forEach(t => BindingEvent.notify(suffix, BindingEvent.Event(res), t))
    }
}


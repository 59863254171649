import jsPDF from 'jspdf';
import {HelperTime} from "../../../Helper/Time";
import DateBeautify = HelperTime.DateBeautify;
import {ApiInterfaces} from "../../../Interfaces";
import {PDF_Template} from "./Template";
import {nswStorage} from "../../DAS/Storage";
import {HelperFunctions} from "../../../Helper/functions";


// class PDF {
//     pdf: jsPDF;
//     name: string;
//     constructor(pdf: jsPDF, name:string) {
//         this.pdf = pdf;
//         this.name = name
//     }
//     async save() {
//         await this.pdf.save(`${this.name}-${DateBeautify(new Date())}.pdf`);
//     }

// }

const danger = [223, 194, 196]
const secondary = [233, 236, 239]


async function WaterTake(pdf: jsPDF, T: ApiInterfaces.WaterTakeReport, graphSVG: string) {

    PDF_Template.WaterTakeSummary(pdf, T)
    PDF_Template.SectionTitle(pdf, `Water Take Graph`, (pdf as any).lastAutoTable.finalY + 10)
    await PDF_Template.addImage(pdf, (pdf as any).lastAutoTable.finalY + 70, graphSVG)
    PDF_Template.addPage(pdf, 2, "a4", "portrait", "Water Take", "Appendix A: Data Table")
    const body: any[] = []

    Object.values(T.data).forEach(v => {
        const n = v as unknown as nswStorage
        const {payload: p} = n
        body.push(PDF_Template.addColumns(undefined, HelperTime.DateBeautify(v.timestamp), HelperFunctions.round(p.volume), HelperFunctions.round(p.waterLevel_AHD), HelperFunctions.round(p.storageCapacity), HelperFunctions.round(p.area), HelperFunctions.round(p.distanceToWaterLevel), HelperFunctions.round(p.battery_voltage)))
    })
    const header = PDF_Template.addHeaders(undefined, "Timestamp", "Volume(ML)", "Water Level (mAHD)", "Storage (%)", "Area (Ha)", "Dist Water Level(m)", "Battery (v)")
    PDF_Template.GenerateAutoTable(pdf, header, body, PDF_Template.first_Item_Y)
}

async function WaterStorage(pdf: jsPDF, T: ApiInterfaces.LIDStorage & {name:string}, graphSVG: string) {
    PDF_Template.WaterStorageSummary(pdf, T)
    PDF_Template.SectionTitle(pdf, `Storage Curve`, (pdf as any).lastAutoTable.finalY + 10)
    await PDF_Template.addImage(pdf, (pdf as any).lastAutoTable.finalY + 70, graphSVG)
    PDF_Template.addPage(pdf, 2, "a4", "portrait", "Storage Curve", "Storage Curve: Data Table")
    const body: any[] = []
    Object.values(T.storageCurve).forEach(v => {
        body.push(PDF_Template.addColumns(undefined, v.elevation, v.area, v.volume))
    })
    const header = PDF_Template.addHeaders(undefined, "Water Level (mAHD)", "Surface Area(ha)", "Volume (ML)")
    PDF_Template.GenerateAutoTable(pdf, header, body, PDF_Template.first_Item_Y)
}


function notification(pdf: jsPDF, T: ApiInterfaces.Notification[]) {
    const body: any[] = []
    T.forEach(v => body.push(PDF_Template.addColumns(v.importance === 'High' ? danger : undefined, HelperTime.DateBeautify(v.created_at), v.importance, v.title, v.message)))
    const header = PDF_Template.addHeaders(undefined, "Timestamp", "Importance", "Title", "Message")
    PDF_Template.GenerateAutoTable(pdf, header, body, (pdf as any).lastAutoTable.finalY + 70)
}


export type PDF_type = 'Water Take' | 'Notifications' | 'StorageCurve'

export default async function newPDF<T>(Data: T[] | T, Name: PDF_type, Search?: string, SubTittle?: string, graphSVG?: string) {

    const pdf = new jsPDF('p', 'pt', 'a4', true)
    PDF_Template.blankPage(pdf, 1, Name, 'portrait')
    PDF_Template.SetTitle(pdf, `${Name} Report ${(Search) ? `With Search Parameter "${Search}"` : ``}  ${SubTittle ? SubTittle : ``}`)
    const fileName = `${Name}-${DateBeautify(new Date())}.pdf`

    switch (Name) {

        case 'Water Take':
            await WaterTake(pdf, Data as unknown as ApiInterfaces.WaterTakeReport, graphSVG || "")
            break;
        case 'StorageCurve':
            await WaterStorage(pdf, Data as unknown as ApiInterfaces.LIDStorage & {name:string}, graphSVG || "")
            break;
        case 'Notifications':
            notification(pdf, Data as unknown as ApiInterfaces.Notification[])
            break;
        default:
            break;
    }

    // const blobs = new Blob([pdf.output('blob')], {type: 'application/pdf'})
    // const base64 = await HelperFunctions.blobToBase64(blobs)


    if ((window as any).ReactNativeWebView) {

        // window.open(URL.createObjectURL(blobPDF as any));
        (window as any).ReactNativeWebView.postMessage(JSON.stringify({
            base64: await HelperFunctions.blobToBase64(new Blob([pdf.output('blob')], {type: 'application/pdf'})),
            fileName: `${Name}-${new Date()}.pdf`
        }))

    } else pdf.save(fileName);

}

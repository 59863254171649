import { v4 as uuid } from 'uuid';
import decode from 'jwt-decode';

import axios, { AxiosRequestConfig } from 'axios';
import { apiRequest, asyncRequest } from './Request';
import MqttManager from './MqttManager';
import { store } from '../../store';
import { KeyMap } from '../../Helper/Core/interface';
import { showError } from '../../redux/actions/alertsAction';
import { ApiEventTypes, Events } from '../Events/Events';
import { logout } from '../../redux/actions/authAction';


interface ApiRequestParams<T extends ApiEventTypes> {
    type: T;
    event: Omit<Events[T]['request'], 'type'>;
    endpoint?: Parameters<typeof asyncRequest>[2];
    options?: Parameters<typeof asyncRequest>[3];
}

export interface ErrorResponse {
    type: 'error',
    status: number,
    message: string,
    errorType: string,
    errors?: { [key: string]: string },
}

export const tokenError: ErrorResponse = {
    type: 'error',
    errorType: 'token.refresh.error',
    message: 'Error refreshing the access token',
    status: 500,
}


export abstract class APIInterface {

    private static endpoint: { url?: string, path?: string } = {};
    private static username?: string;
    private static refreshToken?: string;
    private static serviceId?: string;
    private static accessToken?: string;
    private static tokenRefresh?: Promise<string>;
    private static backOff: number = 100;
    protected static isRefreshing: boolean = false;
    private static mqttToken?: string;
    private static authTokenChangeHandlers: KeyMap<(accessToken: string, refreshToken: string, mqttToken: string) => void> = {};


    protected static requestQueue: ApiRequestParams<ApiEventTypes>[] = [];
    protected static refreshPromise?: Promise<string>;



    public static setEndpoint(url?: string, path?: string) {
        APIInterface.endpoint.url = url;
        APIInterface.endpoint.path = path;
    }

    public static setAccessToken(accessToken: string) {
        APIInterface.accessToken = accessToken;
    }

    public static async createExpoUser(accessToken: string, id: string, expoToken: string) {
        await APIManager.ApiRequest('user.expo.token.create', {
            accessToken,
            userId: id,
            expoToken
        });
    }

    public static login(user: string, department: number, level: number, accessToken: string, refreshToken: string, mqttToken: string) {
        APIInterface.accessToken = accessToken;
        APIInterface.username = user;
        APIInterface.refreshToken = refreshToken;
        APIInterface.mqttToken = mqttToken
    }


    public static logout() {
        APIInterface.accessToken = undefined;
        APIInterface.username = undefined;
        APIInterface.refreshToken = undefined;
        APIInterface.tokenRefresh = undefined;
        APIInterface.mqttToken = undefined;
    }

    public static setRefreshToken(username: string, refreshToken: string, serviceId: string) {
        APIInterface.username = username;
        APIInterface.refreshToken = refreshToken;
        APIInterface.serviceId = serviceId;
    }

    public static setMqttToken(username: string, mqttToken: string, serviceId: string) {
        APIInterface.username = username;
        APIInterface.mqttToken = mqttToken;
        APIInterface.serviceId = serviceId;
    }

    public static registerAuthUpdate(handler: (accessToken: string, refreshToken: string, mqttToken: string) => void): string {
        const id = uuid();
        APIInterface.authTokenChangeHandlers[id] = handler;
        return id;
    }

    public static getEndpoint(endpoint?: Parameters<typeof asyncRequest>[2]) {
        const hostname = endpoint?.hostname || APIInterface.endpoint.url || process.env.REACT_APP_API;
        return (hostname === undefined) ? undefined : { hostname };
    }

    public static getHostname() {
        return APIInterface.endpoint.url || process.env.REACT_APP_API || ''
    }





    public static clearAuthUpdate(id: string): void {
        delete APIInterface.authTokenChangeHandlers[id];
    }

    protected static getAccessToken(providedToken: string | undefined) {
        if (APIInterface.accessToken === undefined) {
            APIInterface.accessToken = providedToken;
        }
        return APIInterface.accessToken || providedToken;
    }


    protected static getMqttToken(providedToken: string | undefined) {
        if (APIInterface.mqttToken === undefined) {
            APIInterface.mqttToken = providedToken;
        }
        return APIInterface.mqttToken || providedToken;
    }

    protected static canRefreshAccessToken() {
        return APIInterface.refreshToken && APIInterface.username;
    }


    protected static async refreshCall(dispatchEndpoint: { hostname: string, path: string } | undefined) {
        try {
            const res = await asyncRequest('user.token.refresh', { username: APIInterface.username || '', refreshToken: APIManager.refreshToken, serviceId: this.serviceId }, dispatchEndpoint);
            if (res && 'accessToken' in res) {
                const accessToken = res.accessToken;
                const refreshToken = res.refreshToken;
                const mqttToken = res.mqttToken;
                APIInterface.accessToken = accessToken;
                APIInterface.refreshToken = refreshToken;
                APIInterface.mqttToken = mqttToken;
                Object.values(APIInterface.authTokenChangeHandlers).forEach((handler) => handler(accessToken, refreshToken, mqttToken));
                return accessToken;
            } return undefined

        } catch (error) {
            return undefined
        }
    }

    protected static async getNewToken(maxTry: number = 4): Promise<string> {
        if (maxTry <= 0) {
            store.dispatch(showError('Session Expired, please login again'))
            store.dispatch(logout());
            return Promise.reject("Max try attempts reached, logging out");
        }

        if (APIInterface.tokenRefresh === undefined) {
            const hostname = process.env.REACT_APP_API || APIInterface.endpoint.url;
            const path = 'user.token.refresh';
            const dispatchEndpoint = (hostname === undefined || false) ? undefined : { hostname, path };

            const accessToken = await APIInterface.refreshCall(dispatchEndpoint);

            if (accessToken === undefined) {
                APIInterface.backOff *= 2;
                await new Promise<void>(resolve => setTimeout(resolve, APIInterface.backOff));
                APIInterface.tokenRefresh = undefined;
                return await APIInterface.getNewToken(maxTry - 1);
            }
            APIInterface.backOff = 100;
            APIInterface.tokenRefresh = undefined;
            return accessToken;
        }

        return APIInterface.tokenRefresh;
    }



    protected static async getNewTokenLegacy(): Promise<string> {
        if (APIInterface.tokenRefresh === undefined) {
            const hostname = process.env.REACT_APP_API || APIInterface.endpoint.url;
            const path = 'user.token.refresh';
            const dispatchEndpoint = (hostname === undefined || false) ? undefined : { hostname, path };
            const accessToken = await this.refreshCall(dispatchEndpoint)
            if (accessToken === undefined) {
                APIInterface.backOff *= 2;
                return new Promise<string>(res => {
                    setTimeout(() => {
                        APIInterface.tokenRefresh = undefined;
                        return res(APIInterface.getNewToken());
                    }, APIInterface.backOff);
                });
            }
            APIInterface.backOff = 100;
            APIInterface.tokenRefresh = undefined;
            return accessToken;
        }
        return APIInterface.tokenRefresh
    }



    protected static getNewAccessToken(): Promise<string> {
        if (APIInterface.tokenRefresh === undefined) {
            const hostname = process.env.REACT_APP_API || APIInterface.endpoint.url;
            const path = 'user.token.refresh';
            const dispatchEndpoint = (hostname === undefined || false) ? undefined : { hostname, path };
            APIInterface.tokenRefresh = asyncRequest('user.token.refresh', { username: APIInterface.username || '', refreshToken: APIManager.refreshToken, serviceId: this.serviceId }, dispatchEndpoint)
                .then((res) => {
                    APIInterface.tokenRefresh = undefined;
                    if (res.type === 'user.token.refresh') {

                        const accessToken = res.accessToken;
                        const refreshToken = res.refreshToken;
                        const mqttToken = res.mqttToken;
                        APIInterface.accessToken = accessToken;
                        APIInterface.refreshToken = refreshToken;
                        APIInterface.mqttToken = mqttToken;
                        Object.values(APIInterface.authTokenChangeHandlers).forEach((handler) => handler(accessToken, refreshToken, mqttToken));
                        return accessToken;
                    }
                    return undefined;
                })
                .catch(() => {
                    return undefined;
                })
                .then((accessToken) => {
                    if (accessToken === undefined) {
                        APIInterface.backOff *= 2;
                        return new Promise<string>(res => {
                            setTimeout(() => {
                                APIInterface.tokenRefresh = undefined;
                                return res(APIInterface.getNewAccessToken());
                            }, APIInterface.backOff);
                        });
                    }
                    APIInterface.backOff = 100;
                    APIInterface.tokenRefresh = undefined;
                    return accessToken;
                });
        }
        return APIInterface.tokenRefresh;
    }
}



export default abstract class APIManager extends APIInterface {


    private static wsEndpoint: { url?: string, path?: string } = {};

    // public static getInstance(token: string)

    public static setWSEndpoint(url?: string, path?: string) {
        APIManager.wsEndpoint.url = url;
        APIManager.wsEndpoint.path = path;
    }

    public static async checkForRefresh(accessToken: string) {

        const data = decode(accessToken) as ({ exp: number } | null);
        const now = Date.now() * 0.001;
        if (data && data.exp < now + 120) {
            if (APIInterface.canRefreshAccessToken()) await APIInterface.getNewToken();
            else {
                return tokenError
            }

        }

    }


    public static getWSEndpoint(endpoint?: Parameters<typeof asyncRequest>[2]) {
        const hostname = endpoint?.hostname || APIManager.wsEndpoint.url || process.env.WSGATEWAY_URL;
        return (hostname === undefined) ? undefined : { hostname };
    }

    public static axiosInstance() {
        const instance = axios.create(
            {
                baseURL: `${process.env.REACT_APP_API}`,
                headers: {
                    "Content-Type": "application/json",
                },

            });

        instance.interceptors.request.use(
            (config) => {
                if (config.headers) {
                    //config.headers["x-access-token"] = accessToken
                    config.headers.authorization = `Bearer ${APIInterface.getAccessToken(undefined)}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        instance.interceptors.response.use(
            (res) => {
                return res;
            },
            async (err) => {
                const originalConfig = err.config;

                if (originalConfig.url !== "/login" && err.response) {
                    // Access Token was expired
                    if (err.response.status === 403 || err.response.status === 401 && !originalConfig._retry) {
                        originalConfig._retry = true;

                        try {
                            delete axios.defaults.headers.common["Authorization"];
                            await APIInterface.getNewToken();

                            return instance(originalConfig);
                        } catch (_error) {
                            return Promise.reject(tokenError);
                        }
                    }
                }

                return Promise.reject(err);
            }
        );

        return instance;
    }


    public static async ApiRequest<T extends ApiEventTypes>(
        type: T,
        event: Omit<Events[T]['request'], 'type'>,
        endpoint?: Parameters<typeof asyncRequest>[2],
        options?: Parameters<typeof asyncRequest>[3],
    ): Promise<Events[T]['response'] | ErrorResponse> {
        const dispatchEndpoint = {
            hostname: process.env.REACT_APP_API || APIManager.getHostname(),
            path: type,
        };

        APIInterface.getEndpoint(endpoint);
        let accessToken = (event as { accessToken?: string }).accessToken;
        if (accessToken) {
            // accessToken = APIInterface.getAccessToken(accessToken) || '';
            accessToken = APIInterface.getAccessToken(undefined) || ''
            const data = decode(accessToken) as { exp: number } | null;
            const now = Date.now() * 0.001;
            // start the refresh 2 minutes before expiring to ensure that the request is received with a valid token


            if (data && data.exp < now) {
                if (APIInterface.canRefreshAccessToken()) {
                    if (!APIManager.isRefreshing) {
                        APIManager.isRefreshing = true;
                        APIInterface.refreshPromise = APIInterface.getNewToken();
                    }
                    try {
                        const res = await APIInterface.refreshPromise
                        if (res) {
                            const e = { ...event, accessToken: res };
                            return await asyncRequest(type, Object.assign({}, e), dispatchEndpoint);
                        } else return await asyncRequest(type, event, dispatchEndpoint);
                    } catch (error) {
                        return tokenError;
                    }
                } else {
                    return tokenError;
                }
            } else {
                APIManager.isRefreshing = false;
                APIInterface.refreshPromise = undefined
            }
        }

        return await asyncRequest(type, event, dispatchEndpoint);
    }


    public static async snRequest<T extends ApiEventTypes>(
        type: T,
        event: Omit<Events[T]['request'], 'type'>,
        endpoint?: Parameters<typeof asyncRequest>[2],
        options?: Parameters<typeof asyncRequest>[3],
    ): Promise<Events[T]['response'] | ErrorResponse> {
        const dispatchEndpoint = {
            hostname: process.env.REACT_APP_API || APIManager.getHostname(),
            path: type,
        };
        APIInterface.getEndpoint(endpoint);
        const accessToken = APIInterface.getAccessToken(undefined) || ''
        const data = decode(accessToken) as { exp: number } | null;
        const now = Date.now() * 0.001;

        if (data && data.exp < now) {
            if (APIInterface.canRefreshAccessToken()) {
                if (!APIManager.isRefreshing) {
                    APIManager.isRefreshing = true;
                    APIInterface.refreshPromise = APIInterface.getNewToken();
                }
                try {
                    const res = await APIInterface.refreshPromise
                    if (res) {
                        const e = { ...event, accessToken: res };
                        return await asyncRequest(type, Object.assign({}, e), dispatchEndpoint);
                    } else return await asyncRequest(type, event, dispatchEndpoint);
                } catch (error) {
                    return tokenError;
                }
            } else {
                return tokenError;
            }
        } else {
            APIManager.isRefreshing = false;
            APIInterface.refreshPromise = undefined
        }
        return await asyncRequest(type, {...event, accessToken}, dispatchEndpoint);
    }



    public abstract bind<T extends ApiEventTypes>(
        type: T,
        body: Omit<Events[T]['request'], 'type'>,
        cb: (response: Events[T]['response'] | ErrorResponse, error: any) => void,
    ): Promise<string>;

    public abstract unbind(modelName: string): void;

}




export const ApiRequest = APIManager.ApiRequest;
export const instanceApi = APIManager.axiosInstance();

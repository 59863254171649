
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_COMPANY_ID = 'UPDATE_COMPANY_ID';

export const CLEAR = "CLEAR";
export const SHOW = "SHOW";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const alertTypes = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'danger',
}
export const transitions = {
    FADE: 'fade',
    // SCALE: 'scale',
}
import React, { useEffect, useRef, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { store } from "../../store";
import { showError, showSuccess } from "../../redux/actions/alertsAction";
import APIManager from "../../Server/Api/APIInterface";
import { FormAction, Section } from "../Utils/Forms/Interface";
import * as Yup from 'yup';
import { DynamicForm } from "../Utils/Forms/Form";
import { useNavigate } from "react-router-dom";

type UserForm = {
    email: string,
    code: string,
    password: string,
    confirmpassword: string
}

function Forgot() {

    const [resetCode, setResetCode] = useState(false);
    const [submitText, setSubmitText] = useState("Reset Password");

    const [form, setForm] = useState<FormAction<UserForm> | undefined>(undefined);
    const formRef = useRef<FormAction<UserForm> | undefined>(undefined)
    const navigate = useNavigate();

    useEffect(() => {
        formRef.current = form

    }, []);

    const staticValidationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required.').email('Invalid email format.'),
        code: Yup.string().required('Verification Code is required').min(6, "Code must be at least 6 digits"),
        password: Yup.string()
            .required('Password is required.')
            .min(8, 'Password must be at least 8 characters long.')
            .matches(
                /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
                'Password must contain at least one letter, one number, and one symbol.'
            ),
        confirmpassword: Yup.string()
            .required('Confirm Password is required.')
            .test('password-match', 'Passwords must match.', function (value) {
                return value === this.resolve(Yup.ref('password'));
            }),
    });

    const UserDetails: Section = {
        type: "section",
        title: "Password Reset",
        id: "userform",
        //className: "cs-location-section",
        items: [
            {
                type: "row",
                id: "Idsrow",
                //hidden: true,
                items: [
                    { width: 12, type: "email", id: "email", value: "[email]", label: "Enter Email:" },
                    { width: 12, type: "text", id: "code", value: "[code]", label: "Verification Code:", hidden: true },
                    { width: 12, type: "password", id: "password", value: "[password]", label: "Password:", hidden: true },
                    { width: 12, type: "password", id: "confirmpassword", value: "[confirmpassword]", label: "Confirm Password:", hidden: true }
                ],
            },

        ],
    };

    const handleResetPasswordClick = () => {
        const email = form?.getValue('email')?.toString() || ''
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!pattern.test(email)) {
            store.dispatch(showError('Please use valid email'))
            return
        }
        try {
            APIManager.ApiRequest('user.password.resetcode', { email }).then(res => {
                if (res.type === 'user.password.resetcode') {
                    store.dispatch(showSuccess('Verification Code sent to email'))
                    setResetCode(true)
                    form?.setMultipleHidden(false, 'code', 'password', 'confirmpassword')
                }
            })


        } catch (e) {
            store.dispatch(showError('Email not found'))
        }
    };

    const getForm = async () => {
        ('here?')
        if (form) {

            try {
                const validatedData = await form.getYupValidation(staticValidationSchema);
                if (validatedData.isValid) {
                    setSubmitText("Syncing, please wait...")
                    const data = form.getFormData()
                    const { password, code, email } = data
                    const res = await APIManager.ApiRequest('user.password.reset', { code, password, email })
                    if (res.type === 'user.password.reset') {
                        store.dispatch(showSuccess('Password Reset Successfully'))
                        navigate('/')
                    }
                }
            } catch (error) {
                store.dispatch(showError('Code Not Valid or Expired'))
                setSubmitText("Reset Password")
            }


        }
    }


    return (
        <div className="login login-with-news-feed">
            <div className="news-feed">
                <div className="news-image" style={{ backgroundImage: 'url(/images/SierraNet.jpg)' }}></div>
                <div className="news-caption">
                    <h4 className="caption-title"><b>SierraTek</b> Automation and Control</h4>
                    <p>
                        The SierraTek team offers a combined 130 years of expertise in the Automation and Control sector across various industries.
                    </p>
                </div>
            </div>
            <div className="login-container">
                <div className="login-header mb-30px">
                    <div className="brand">
                        <div className="d-flex align-items-center">
                            <img src="/images/SierraNetLogo.png" alt="" width="354" height="140" />
                        </div>
                    </div>
                </div>
                <div className="login-content">
                    <DynamicForm<UserForm>
                        actionTriggered={(id, action, form) => {
                            setForm(form);
                            formRef.current = form
                            // form.setValue('text', 'Seu Cu')
                            return true;
                        }
                        }
                        valueChanged={(id, value, valid, form) => {
                            return true;
                        }}
                        id={'User Modal'}
                        inputData={{ email: '', code: '', confirmpassword: '', password: '' }}
                        content={[UserDetails]}
                    />

                    {resetCode && (
                        <div className="mb-15px">
                            <Button type="button" variant="success" className="d-block h-45px w-100 btn-lg fs-14px" onClick={getForm}>
                                {submitText}
                            </Button>
                        </div>
                    )}
                    <div className="mb-15px">
                        <Button type="button" variant={resetCode ? "secondary" : "success"} className="d-block h-45px w-100 btn-lg fs-14px" onClick={handleResetPasswordClick}>
                            {resetCode ? 'Resend' : 'Send'} Verification Code
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Forgot;
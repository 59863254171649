import { KeyMap } from "../../../Helper/Core/interface";
import { Events } from "../../Events/Events";
import { DataSet } from "../DataSet";


export type DataType = Events['device.list']['response']['data'][number];

export class Binding extends DataSet<DataType> {
    public constructor(private companyId: string, cb?: (data: KeyMap<DataType>) => void) {
        super(i => i.id, cb);
    }
    public async bind() {
        super.bind();
        this.registerBindingKey(await this.manager.bind('device.list', {
            topic: `${this.companyId}/device`,
            companyId: this.companyId
        }, async (res) => {
            if (res.type === 'device.list') {
                this.setData(res.data);
            } else {
                this.setLoaded();
            }
        }));
    }
}


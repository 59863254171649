import {CLEAR,SHOW} from '../actions/actionInterface'

const getInitialState = () => ({
  messagealert: null,
  duration: null,
  key:null,
  type: 'info',
})

export default function alert(state = getInitialState(), action:any) {
  const { type, payload = {} } = action
  switch (type) {
    case SHOW: {
      return {
        ...state,
        ...payload,
      }
    }

    case CLEAR: {
      return {
        ...state,
        ...payload,
      }
    }

    default: {
      return state
    }
  }
}
// Sidebar.tsx
import React, { useEffect, useRef, useState } from 'react';
import './Sidebar.css';
import { connect } from 'react-redux';
import { IRedux } from '../../Interfaces/redux';
import { ApiInterfaces } from '../../Interfaces';
import { SVG, SVG_Panel, SvgIcon } from '../Utils/SVG';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import APIManager from '../../Server/Api/APIInterface';
import { Panel, PanelBody } from '../panel/panel';
import CompanyModal from '../Managment/CompanyModal';
import { BindingSet } from '../../Server/Binding/Binding';
import { Bindings } from '../../Server/Binding/bidings';
import { ReduxActions } from '../../redux/actions';

const bindings: BindingSet = new BindingSet({});

const DeviceSettings: React.FC<IRedux.IAuth> = ({ user, accessToken }) => {
    const [management, setManagment] = useState<ApiInterfaces.Managements | undefined>(undefined)
    const [companies, setCompanies] = useState<ApiInterfaces.Company[] | undefined>(undefined)
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState<Partial<ApiInterfaces.Company> | undefined>(undefined)


    useEffect(() => {

        bindings.bind()
        ReduxActions.ShowLoader();

        function CreateBinding() {
            const mgm = (new Bindings.Management.Binding(accessToken, user.managementsId || "",
                (data) => {
                    setManagment(Object.values(data)[0]);
                    ReduxActions.HideLoader();
                }
            ));
            const com = (new Bindings.RefCompany.Binding(true, mgm,
                (data) => setCompanies(Object.values(data))));
            bindings.add('mgm', mgm);
            bindings.add('com', com);
        }

        CreateBinding();
        return () => {
            bindings.unbind()
        };
    }, []);

    const handleCloseModal = () => {
        setData(undefined)
        setShowModal(false);
    };

    const handleEdit = (data: Partial<ApiInterfaces.Company>) => {
        setData(data)
        setShowModal(!showModal)
    };


    return (
        <>
            <CompanyModal show={showModal} handleClose={handleCloseModal} company={data} />
            <Row>
                <Col xs={12}>
                    <Panel theme={'inverse'} reload={false} style={{ marginBottom: '0rem' }} >
                        <PanelBody className="p-6">
                            <h3 className="text-center"> {management?.Name} </h3>
                        </PanelBody>
                    </Panel >
                </Col>
            </Row>

            <Row className='pt-3'>
                <Col xs={12}>
                    <Panel theme={'inverse'} reload={false} style={{ marginBottom: '0rem' }} >
                        <PanelBody className="p-6">
                            <div className='table-resposive'>
                                <Table className='pt-3' responsive hover variant="dark">

                                    <thead>
                                        <th className='ps-3'>Company Name</th>
                                        <th className='ps-4'>Latitude</th>
                                        <th className='ps-3'>Longitude</th>
                                        <th className='ps-3'>Edit?</th>
                                    </thead>
                                    <tbody>


                                        {companies && companies.map((x) => (
                                            <tr>
                                                <td>{x.name_Cia}</td>
                                                <td>{Number(x.lat).toPrecision(5)}</td>
                                                <td>{Number(x.lng).toPrecision(5)}</td>
                                                <td>
                                                    <Button variant="info" onClick={() => handleEdit(x)}>
                                                        <i className="far fa-edit"></i>
                                                    </Button>
                                                </td>

                                            </tr>


                                        ))}
                                        <tr>
                                            <td colSpan={4}> <Button variant='outline-warning' onClick={() => handleEdit({})}> <i className="fas fa-folder-plus"></i> &nbsp;Create New  </Button> </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </PanelBody>
                    </Panel >
                </Col>




            </Row>


        </>
    );
};


const mapStateToProps = (state: IRedux.MapState) => {
    const { user, isLoggedIn, accessToken, refreshToken, mqttToken } = state.auth;
    return {
        user,
        isLoggedIn,
        accessToken,
        refreshToken,
        mqttToken
    };
};

const connector = connect(mapStateToProps);

export default connector(DeviceSettings);

import { Panel, PanelBody } from "../panel/panel";
import { Card, Tab, Table, Tabs } from "react-bootstrap";
import React from "react";
import { BindingHooks } from "../../Server/Binding/bidings/BindingHook";
import { IRedux } from "../../Interfaces/redux";
import { ReduxActions } from "../../redux/actions";

function StorageCurveTable(props: { id: string, name: string } & IRedux.IAuth) {
    const { data } = BindingHooks.LidStorage(props.accessToken, props.id || "", props.user.companyId, 'lidCurve')
    return <Card className="mb-0 " style={{ border: '1px solid #555', borderRadius: '8px' }}>
        <Card.Header className="text-center" style={{ backgroundColor: '#000', color: '#fff', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
            <h5 className="mb-1" style={{ fontSize: '1.0rem' }}><strong>{props.name}</strong> Storage Curve</h5>
        </Card.Header>
        <Card.Body className="text-center mt-0">
            <Card className="mb-0 mt-0" style={{ border: '1px solid #555', borderRadius: '8px' }}>
                <Card.Body style={{ overflowY: 'auto', maxHeight: '280px' }}>
                    {data && <Table className='table-no-border-top table-responsive-sm ' hover >
                        <thead className={"sticky-top"} style={{ zIndex: "50" }}>
                            <tr>
                                <th className={"text-center"}> Elevation (mHAD)</th>
                                <th className={"text-center"}> Surface Area (ha)</th>
                                <th className={"text-center"}> Volume (ML)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.storageCurve.map(x => (
                                <tr key={x.elevation}>
                                    <td className={"text-center"}>
                                        {x.elevation}
                                    </td>
                                    <td className={"text-center"}>
                                        {x.area}
                                    </td>
                                    <td className={"text-center"}>
                                        {x.volume}
                                    </td>
                                </tr>)
                            )}


                        </tbody>
                    </Table>}
                </Card.Body>
            </Card>

        </Card.Body>
    </Card>


    // <Panel theme={'inverse'}>
    //     <PanelBody className="p-0 devicePanelHeight">
    //         <Tabs defaultActiveKey={`${props.id}`} id={`${props.id}`} className="mb-1 " fill justify>
    //             <Tab key={`${props.id}`} eventKey={`${props.id}`}
    //                  title={`${props.name} Storage Curve Table`} className="mb-1 ">

    //             </Tab>
    //         </Tabs>
    //     </PanelBody>
    // </Panel>;
}

export default ReduxActions.connector(StorageCurveTable)
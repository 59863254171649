import React from 'react';

interface IconsProps {
    smallIcon?: boolean;
}

const Snow: React.FC<IconsProps> = ({ smallIcon }) => {
    return (
        <svg className={smallIcon ? "iconSm iconSm-sunshower" : "icon icon-sunshower"} viewBox="0 0 220 220">

            <g className="snowflakes">
                <path fill="#43647E" d="M84.535,166.239l-5.663,1.73l-3.644-2.104c0.089-0.392,0.141-0.798,0.141-1.218
		c0-0.418-0.052-0.824-0.141-1.216l3.645-2.104l5.662,1.729c0.156,0.048,0.314,0.071,0.47,0.071c0.688,0,1.324-0.445,1.536-1.138
		c0.26-0.849-0.218-1.747-1.067-2.006l-2.795-0.854l1.482-0.856c0.769-0.443,1.032-1.426,0.588-2.194s-1.426-1.032-2.195-0.589
		l-1.483,0.856l0.658-2.848c0.2-0.865-0.339-1.728-1.204-1.928c-0.865-0.2-1.728,0.339-1.927,1.204l-1.333,5.769l-3.648,2.106
		c-0.595-0.553-1.309-0.979-2.104-1.224v-4.204l4.33-4.039c0.649-0.605,0.685-1.621,0.079-2.271
		c-0.605-0.648-1.622-0.685-2.271-0.078l-2.138,1.993v-1.712c0-0.888-0.72-1.607-1.606-1.607c-0.888,0-1.607,0.72-1.607,1.607v1.712
		l-2.138-1.993c-0.648-0.606-1.666-0.57-2.271,0.078c-0.605,0.649-0.57,1.665,0.079,2.271l4.33,4.039v4.204
		c-0.795,0.245-1.509,0.67-2.104,1.224l-3.649-2.106l-1.332-5.77c-0.2-0.864-1.062-1.403-1.927-1.203
		c-0.865,0.199-1.403,1.063-1.204,1.927l0.658,2.849l-1.483-0.856c-0.769-0.443-1.752-0.18-2.195,0.589
		c-0.444,0.768-0.18,1.751,0.588,2.194l1.483,0.856l-2.796,0.854c-0.849,0.26-1.326,1.158-1.067,2.006
		c0.212,0.693,0.848,1.139,1.537,1.139c0.155,0,0.313-0.023,0.47-0.071l5.662-1.729l3.645,2.104
		c-0.09,0.393-0.142,0.798-0.142,1.217s0.052,0.825,0.142,1.218l-3.646,2.104l-5.662-1.73c-0.848-0.259-1.747,0.218-2.006,1.067
		c-0.259,0.849,0.219,1.746,1.067,2.006l2.796,0.854l-1.483,0.856c-0.769,0.443-1.032,1.427-0.588,2.195
		c0.298,0.515,0.838,0.804,1.393,0.804c0.273,0,0.549-0.07,0.802-0.216l1.483-0.856l-0.658,2.849
		c-0.2,0.864,0.339,1.728,1.204,1.927c0.121,0.028,0.243,0.042,0.362,0.042c0.731,0,1.393-0.503,1.564-1.245l1.333-5.769
		l3.649-2.107c0.595,0.553,1.31,0.979,2.104,1.224v4.204l-4.329,4.039c-0.649,0.604-0.685,1.622-0.079,2.271
		c0.605,0.649,1.623,0.685,2.271,0.079l2.137-1.994v1.712c0,0.888,0.72,1.607,1.606,1.607c0.887,0,1.607-0.72,1.607-1.607v-1.712
		l2.138,1.994c0.31,0.289,0.703,0.432,1.095,0.432c0.431,0,0.859-0.171,1.176-0.511c0.605-0.648,0.57-1.666-0.079-2.271l-4.33-4.039
		v-4.204c0.795-0.245,1.509-0.671,2.104-1.224l3.649,2.107l1.333,5.769c0.171,0.743,0.833,1.245,1.564,1.245
		c0.12,0,0.241-0.014,0.362-0.042c0.865-0.199,1.404-1.063,1.205-1.927l-0.658-2.849l1.482,0.856
		c0.253,0.146,0.529,0.216,0.802,0.216c0.556,0,1.096-0.288,1.393-0.804c0.444-0.769,0.181-1.751-0.588-2.194l-1.483-0.857
		l2.796-0.854c0.849-0.259,1.327-1.157,1.067-2.006C86.281,166.457,85.382,165.979,84.535,166.239z M69.906,167.54
		c-1.594,0-2.892-1.297-2.892-2.893c0-1.594,1.297-2.892,2.892-2.892c1.595,0,2.893,1.298,2.893,2.892
		C72.798,166.243,71.501,167.54,69.906,167.54z" />
                <path fill="#43647E" d="M123.582,166.239l-5.662,1.73l-3.645-2.104c0.09-0.392,0.142-0.798,0.142-1.218
		c0-0.418-0.052-0.824-0.142-1.216l3.645-2.104l5.662,1.729c0.156,0.048,0.314,0.071,0.471,0.071c0.688,0,1.324-0.445,1.535-1.138
		c0.26-0.849-0.218-1.747-1.066-2.006l-2.795-0.854l1.482-0.856c0.768-0.443,1.031-1.426,0.588-2.194s-1.426-1.032-2.195-0.589
		l-1.482,0.856l0.658-2.848c0.2-0.865-0.339-1.728-1.203-1.928c-0.865-0.2-1.729,0.339-1.928,1.204l-1.333,5.769l-3.648,2.106
		c-0.595-0.553-1.31-0.979-2.104-1.224v-4.204l4.33-4.039c0.648-0.605,0.685-1.621,0.078-2.271c-0.604-0.648-1.621-0.685-2.27-0.078
		l-2.138,1.993v-1.712c0-0.888-0.72-1.607-1.606-1.607c-0.888,0-1.607,0.72-1.607,1.607v1.712l-2.138-1.993
		c-0.648-0.606-1.666-0.57-2.271,0.078c-0.605,0.649-0.57,1.665,0.079,2.271l4.33,4.039v4.204c-0.795,0.245-1.509,0.67-2.104,1.224
		l-3.649-2.106l-1.332-5.77c-0.2-0.864-1.062-1.403-1.927-1.203c-0.865,0.199-1.403,1.063-1.204,1.927l0.658,2.849l-1.483-0.856
		c-0.769-0.443-1.752-0.18-2.195,0.589c-0.444,0.768-0.18,1.751,0.588,2.194l1.483,0.856l-2.796,0.854
		c-0.849,0.26-1.326,1.158-1.067,2.006c0.212,0.693,0.848,1.139,1.537,1.139c0.155,0,0.313-0.023,0.47-0.071l5.662-1.729
		l3.645,2.104c-0.09,0.393-0.142,0.798-0.142,1.217s0.052,0.825,0.142,1.218l-3.646,2.104l-5.662-1.73
		c-0.848-0.259-1.747,0.218-2.006,1.067c-0.259,0.849,0.219,1.746,1.067,2.006l2.796,0.854l-1.483,0.856
		c-0.769,0.443-1.032,1.427-0.588,2.195c0.298,0.515,0.838,0.804,1.393,0.804c0.273,0,0.549-0.07,0.802-0.216l1.483-0.856
		l-0.658,2.849c-0.2,0.864,0.339,1.728,1.204,1.927c0.121,0.028,0.243,0.042,0.362,0.042c0.731,0,1.393-0.503,1.564-1.245
		l1.333-5.769l3.649-2.107c0.595,0.553,1.31,0.979,2.104,1.224v4.204l-4.329,4.039c-0.649,0.604-0.685,1.622-0.079,2.271
		c0.605,0.649,1.623,0.685,2.271,0.079l2.137-1.994v1.712c0,0.888,0.72,1.607,1.606,1.607c0.887,0,1.607-0.72,1.607-1.607v-1.712
		l2.138,1.994c0.31,0.289,0.703,0.432,1.095,0.432c0.432,0,0.859-0.171,1.176-0.511c0.605-0.648,0.57-1.666-0.078-2.271l-4.33-4.039
		v-4.204c0.795-0.245,1.51-0.671,2.104-1.224l3.65,2.107l1.332,5.769c0.172,0.743,0.832,1.245,1.564,1.245
		c0.119,0,0.24-0.014,0.361-0.042c0.865-0.199,1.404-1.063,1.205-1.927l-0.658-2.849l1.482,0.856
		c0.254,0.146,0.529,0.216,0.802,0.216c0.556,0,1.097-0.288,1.394-0.804c0.443-0.769,0.18-1.751-0.588-2.194l-1.483-0.857
		l2.796-0.854c0.849-0.259,1.326-1.157,1.066-2.006C125.328,166.457,124.43,165.979,123.582,166.239z M108.954,167.54
		c-1.594,0-2.892-1.297-2.892-2.893c0-1.594,1.297-2.892,2.892-2.892c1.595,0,2.892,1.298,2.892,2.892
		C111.846,166.243,110.549,167.54,108.954,167.54z" />
                <path fill="#43647E" d="M162.632,166.239l-5.662,1.73l-3.645-2.104c0.09-0.392,0.142-0.798,0.142-1.218
		c0-0.418-0.052-0.824-0.142-1.216l3.645-2.104l5.662,1.729c0.156,0.048,0.314,0.071,0.471,0.071c0.688,0,1.324-0.445,1.535-1.138
		c0.26-0.849-0.218-1.747-1.066-2.006l-2.795-0.854l1.482-0.856c0.768-0.443,1.031-1.426,0.588-2.194s-1.426-1.032-2.195-0.589
		l-1.482,0.856l0.658-2.848c0.2-0.865-0.339-1.728-1.203-1.928c-0.865-0.2-1.729,0.339-1.928,1.204l-1.333,5.769l-3.648,2.106
		c-0.595-0.553-1.31-0.979-2.104-1.224v-4.204l4.329-4.039c0.648-0.605,0.685-1.621,0.078-2.271
		c-0.604-0.648-1.621-0.685-2.27-0.078l-2.138,1.993v-1.712c0-0.888-0.721-1.607-1.607-1.607s-1.606,0.72-1.606,1.607v1.712
		l-2.138-1.993c-0.648-0.606-1.666-0.57-2.271,0.078c-0.605,0.649-0.57,1.665,0.08,2.271l4.329,4.039v4.204
		c-0.795,0.245-1.509,0.67-2.104,1.224l-3.648-2.106l-1.332-5.77c-0.2-0.864-1.063-1.403-1.928-1.203
		c-0.865,0.199-1.403,1.063-1.203,1.927l0.658,2.849l-1.483-0.856c-0.769-0.443-1.752-0.18-2.195,0.589
		c-0.444,0.768-0.181,1.751,0.589,2.194l1.482,0.856l-2.796,0.854c-0.849,0.26-1.326,1.158-1.067,2.006
		c0.212,0.693,0.848,1.139,1.537,1.139c0.154,0,0.313-0.023,0.469-0.071l5.662-1.729l3.646,2.104
		c-0.09,0.393-0.142,0.798-0.142,1.217s0.052,0.825,0.142,1.218l-3.646,2.104l-5.662-1.73c-0.848-0.259-1.746,0.218-2.006,1.067
		c-0.259,0.849,0.219,1.746,1.067,2.006l2.796,0.854l-1.482,0.856c-0.77,0.443-1.033,1.427-0.589,2.195
		c0.298,0.515,0.838,0.804,1.394,0.804c0.272,0,0.549-0.07,0.802-0.216l1.483-0.856l-0.658,2.849
		c-0.201,0.864,0.338,1.728,1.203,1.927c0.121,0.028,0.243,0.042,0.362,0.042c0.731,0,1.394-0.503,1.564-1.245l1.333-5.769
		l3.648-2.107c0.595,0.553,1.31,0.979,2.104,1.224v4.204l-4.328,4.039c-0.65,0.604-0.686,1.622-0.08,2.271
		c0.605,0.649,1.623,0.685,2.271,0.079l2.137-1.994v1.712c0,0.888,0.721,1.607,1.607,1.607s1.606-0.72,1.606-1.607v-1.712
		l2.138,1.994c0.31,0.289,0.703,0.432,1.095,0.432c0.432,0,0.859-0.171,1.176-0.511c0.605-0.648,0.57-1.666-0.078-2.271l-4.33-4.039
		v-4.204c0.795-0.245,1.51-0.671,2.104-1.224l3.65,2.107l1.332,5.769c0.172,0.743,0.832,1.245,1.564,1.245
		c0.119,0,0.24-0.014,0.361-0.042c0.865-0.199,1.404-1.063,1.205-1.927l-0.658-2.849l1.482,0.856
		c0.254,0.146,0.529,0.216,0.802,0.216c0.556,0,1.097-0.288,1.394-0.804c0.443-0.769,0.18-1.751-0.588-2.194l-1.483-0.857
		l2.796-0.854c0.849-0.259,1.326-1.157,1.066-2.006C164.378,166.457,163.479,165.979,162.632,166.239z M148.004,167.54
		c-1.595,0-2.893-1.297-2.893-2.893c0-1.594,1.298-2.892,2.893-2.892s2.892,1.298,2.892,2.892
		C150.896,166.243,149.599,167.54,148.004,167.54z" />
            </g>
            <g className="cloud-offset">
                <path fill="#43647E" d="M144.979,144.945c-6.177,0-12.277-1.229-17.934-3.585c-6.06,2.515-12.216,3.585-19.997,3.585
		c-8.326,0-16.357-1.866-23.96-5.56c-5.329,2.71-11.261,4.118-17.491,4.118c-21.271,0-38.576-17.305-38.576-38.575
		c0-15.344,9.325-29.173,22.996-35.267c6.651-25.269,29.614-42.96,57.032-42.96c19.87,0,38.255,9.958,49.176,26.31
		c20.533,5.087,35.41,23.656,35.41,45.278C191.635,124.016,170.705,144.945,144.979,144.945z" />
            </g>
            <g className="snow-cloud">
                <path fill="#43647E" d="M149.365,62.911c-8.359-15.386-24.712-25.209-42.316-25.209c-24.461,0-44.287,17.107-47.508,40.333
		c-12.299,2.766-21.52,13.77-21.52,26.894c0,15.206,12.369,27.575,27.576,27.575c6.395,0,12.346-2.076,17.133-5.916
		c7.713,4.945,15.701,7.357,24.318,7.357c8.141,0,13.678-1.293,20.041-4.818c5.419,3.156,11.542,4.818,17.89,4.818
		c19.658,0,35.655-15.994,35.655-35.656C180.635,80.114,166.961,65.083,149.365,62.911z" />
            </g>

        </svg>

    )
}

export default Snow
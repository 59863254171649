import React from 'react';
import { Table } from 'react-bootstrap';
import { HelperFunctions } from "../../Helper/functions";
import { ApiInterfaces } from "../../Interfaces";
import { HelperTime } from "../../Helper/Time"; // Assuming you're using react-bootstrap

interface DataTableSectionProps {
    section: ApiInterfaces.DataReport;
    devices: ApiInterfaces.Device[];
    dataMap: { [key: string]: ApiInterfaces.DeviceData[] }
}

const DataTableSection: React.FC<DataTableSectionProps> = ({ section, devices, dataMap }) => {
    const device = HelperFunctions.findDevById(devices || [], section.deviceId);
    return (
        <>
            {dataMap[section.deviceId] && <Table hover bordered responsive>
                <thead>
                    <tr>
                        <th className="text-center text-info">
                            Date
                        </th>
                        {section.include.map((value, index) => (
                            <th key={index} className="text-center text-info">
                                {value}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {dataMap[section.deviceId].map(item => {
                        const rowValues = section.include.map((header) => {
                            let value = '';
                            const metadata = device?.graph_metadata?.find((meta: { key: any; }) => meta.key === header);
                            if (metadata) {
                                const context = metadata.context;
                                if (item.payload.hasOwnProperty(context)) value = item.payload[context];
                            }
                            return value;
                        });

                        // Check if all values in the row are empty
                        const isEmptyRow = rowValues.every(value => value === '');

                        if (isEmptyRow) return null;

                        return (
                            <tr key={item.id}>
                                <td className="text-center">{HelperTime.DateBeautify(item.timestamp)}</td>
                                {rowValues.map((value, index) => (
                                    <td key={`${item.id}-${section.include[index]}`} className="text-center">{value}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>}
        </>
    );
};

export default DataTableSection;
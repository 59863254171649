// Sidebar.tsx
import React from 'react';
import './Sidebar.css';
import { useSelector} from 'react-redux';
import { IRedux } from '../../Interfaces/redux';
import { Col, Row } from 'react-bootstrap';
import Settings from './Settings';
import {BindingHooks} from "../../Server/Binding/bidings/BindingHook";

  

const DeviceSettings: React.FC = () => {
    const {user} = useSelector((state: IRedux.MapState) => state.auth)
    const {data} = BindingHooks.Device(user.companyId, 'DevList', true)

    return (
        <>
            <Row>
                {data && data.map((x) => (
                    <Col sm='6' xl='3'>
                        <Settings device={x} key={x.id} />
                    </Col>

                ))}
            </Row>


        </>
    );
};

export default DeviceSettings;

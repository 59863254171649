import { useRef, useEffect, useMemo } from 'react'

export interface MarkerProps extends google.maps.MarkerOptions {
    onClick?: (event: google.maps.MapMouseEvent) => void
    onDrag?: (event: google.maps.MapMouseEvent) => void
}

export interface MapProps extends google.maps.MapOptions {
    className?: string
    onClick?: (event: google.maps.MapMouseEvent) => void
    markers?: MarkerProps[]
    height: `${number}px` | `${number}%` | `${number}vh`,
    width: `${number}px` | `${number}%`,
    zoom: number,
    center: { lat: number, lng: number }
}

export function MapComponent(props: MapProps) {
    const { className, onClick, markers = [], zoom, center, ...restProps } = props
    const ref = useRef<HTMLDivElement>(null)
    const map = useRef<google.maps.Map | null>(null)

    const stringOptions = useMemo(() => JSON.stringify(restProps), [restProps])

    useEffect(() => {
        if (!ref.current) return
        map.current = new window.google.maps.Map(ref.current, {
            center,
            fullscreenControl: true,
            mapTypeId: "satellite",
            zoom,
        });
        if (onClick) map.current.addListener('click', onClick)
        return () => {
            if (!map.current) return
            google.maps.event.clearListeners(map.current, 'click')
        }
    }, [])

    useEffect(() => {
        if (!map.current) return
        map.current.setOptions(restProps)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stringOptions])

    useEffect(() => {
        const currentMap = map.current
        if (!currentMap || !markers) return
        const mapMarkers = markers.map(({ onClick, onDrag, ...marker }) => {
            const markerNew = new window.google.maps.Marker(marker)
            markerNew.setMap(currentMap)

            const finalOnClick = (event: google.maps.MapMouseEvent) => {
                // const { latLng } = event
                // if (!latLng) return
                // currentMap.setCenter({ lat: latLng.lat(), lng: latLng.lng() })

                if (onClick) onClick(event)
            }

            const finalOnDrag = (event: google.maps.MapMouseEvent) => {
                const { latLng } = event
                if (!latLng) return
                currentMap.setCenter({ lat: latLng.lat(), lng: latLng.lng() })
                if (onDrag) onDrag(event)
            }

            markerNew.addListener('click', finalOnClick)
            markerNew.addListener('dragend', finalOnDrag)



            return markerNew
        })

        return () => {
            if (!mapMarkers) return
            for (const marker of mapMarkers) {
                marker.setMap(null)
            }
        }
    }, [markers, onClick])

    return <div ref={ref} style={{ height: '100%', width: props.width }} />
}
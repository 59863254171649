import {APIInterface} from "../Server/Api/APIInterface";
import {ApiInterfaces} from "../Interfaces";
import {useEffect, useState} from "react";

export namespace HelperTime {

    export function getDayOfWeek(timestamp: number): string {
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const date = new Date(timestamp * 1000); // Multiply by 1000 to convert from seconds to milliseconds
        const dayIndex = date.getDay();

        return daysOfWeek[dayIndex];
    }

    export function DateBeautify(PassDate: Date | undefined | null): string {
        if (PassDate) {
            const InDate = new Date(PassDate);

            const Time = ("0" + InDate.getDate()).slice(-2) + "/" + ("0" + (InDate.getMonth() + 1)).slice(-2) + "/"
                + InDate.getFullYear() + " " + InDate.getHours() + ":" +
                ("0" + InDate.getMinutes()).slice(-2);
            return Time
        } else return ' '
    }

    export function DateOnlyBeautify(PassDate: Date | undefined | null): string {
        if (PassDate) {
            const InDate = new Date(PassDate);

            const Time = ("0" + InDate.getDate()).slice(-2) + "/" + ("0" + (InDate.getMonth() + 1)).slice(-2) + "/"
                + InDate.getFullYear();
            return Time
        } else return ' '
    }

    export function DateOnlyWeekBeautify(PassDate: Date | undefined | null): string {
        if (PassDate) {
            const InDate = new Date(PassDate);

            const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            const dayOfWeek = daysOfWeek[InDate.getDay()];

            const Time = dayOfWeek + ", " + ("0" + InDate.getDate()).slice(-2) + "/" + ("0" + (InDate.getMonth() + 1)).slice(-2) + "/"
                + InDate.getFullYear();
            return Time;
        } else {
            return ' ';
        }
    }


    export function DateBeautifyTimeOnly(PassDate: Date | undefined): string {
        if (PassDate) {
            const InDate = new Date(PassDate);

            const Time = ("0" + InDate.getHours()).slice(-2) + ":" +
                ("0" + InDate.getMinutes()).slice(-2);

            return Time;
        } else {
            return '';
        }
    }

    export function useTimeAgo(dateString: string | undefined) {
        const [timeAgo, setTimeAgo] = useState(dateString ? formatRelativeTime(dateString): undefined);

        useEffect(() => {
            const interval = dateString && setInterval(() => {
                setTimeAgo(formatRelativeTime(dateString));
            }, 1000); // Update every second

            return () => clearInterval(interval);
        }, [dateString]);

        return {timeAgo, setTimeAgo};
    }

    export function isOlderThanNHours(lastDataTimestamp: number, n: number): boolean {
        const currentTime: number = new Date().getTime();
        const nHoursAgo: number = currentTime - (n * 60 * 60 * 1000);
        return lastDataTimestamp < nHoursAgo;
    }

    export function isOlderThanNMinutes(lastDataTimestamp: number, n: number): boolean {
        const currentTime: number = new Date().getTime();
        const nMinutesAgo: number = currentTime - (n * 60 * 1000);

        return lastDataTimestamp < nMinutesAgo;
    }

    export function formatRelativeTime(dateString: string): string {
        const date = new Date(dateString);
        const now = new Date();

        const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

        if (seconds < 60) {
            return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
        }

        const minutes = Math.floor(seconds / 60);

        if (minutes < 60) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        }

        const hours = Math.floor(minutes / 60);

        if (hours < 24) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        }

        const days = Math.floor(hours / 24);

        if (days < 30) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        }

        const months = Math.floor(days / 30);

        if (months < 12) {
            return `${months} month${months !== 1 ? 's' : ''} ago`;
        }

        const years = Math.floor(months / 12);

        return `${years} year${years !== 1 ? 's' : ''} ago`;
    }

    export function ReportTimeConflict(newReport: Omit<ApiInterfaces.Report, "id">, existingReports: ApiInterfaces.Report[]): boolean {
        for (const report of existingReports) {
            const newReportDate = new Date(newReport.created_at).getTime()
            const newReportFinish = newReport.finished ? new Date(newReport.finished).getTime() : new Date().getTime()
            const reportDate = new Date(report.created_at).getTime()
            const reportFinishDate = report.finished ? new Date(report.finished).getTime() : new Date().getTime()

            // Check if the new report's created_at time falls within the existing report's time range
            if (newReportDate >= reportDate && newReportDate <= reportFinishDate) return true
            // Check if the new report's finished time falls within the existing report's time range
            if (newReportFinish >= reportDate && newReportFinish <= reportFinishDate) return true;
            // Check if the new report's time range completely encompasses the existing report's time range
            if (newReportDate <= reportDate && (newReportFinish >= reportFinishDate)) return true;
        }
        return false; // No conflict found
    }

    function isReportRunning(report: ApiInterfaces.Report): boolean {
        const currentTime = new Date().getTime();
        return new Date(report.created_at).getTime() <= currentTime && (!report.finished || currentTime <= new Date(report.finished).getTime());
    }

    export function isAnyReportRunning(existingReports: ApiInterfaces.Report[]): ApiInterfaces.Report | undefined {
        for (const report of existingReports) {
            if (isReportRunning(report)) return report;
        }
        return undefined;
    }

    export const FixTimeForInput = (date:Date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const days = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${days}`;
    }

}
import { combineReducers } from "redux";
import auth from "./authReducers";
import alert from "./alertReduce";
import load from "./loadReducer";

const reducers =  combineReducers({
  auth,
  alert,
  load
});

export default reducers
import jsPDF from 'jspdf'
import autoTable, {RowInput} from 'jspdf-autotable';
import {HelperTime} from '../../../Helper/Time';
import {ApiInterfaces} from "../../../Interfaces";
import {HelperFunctions} from "../../../Helper/functions";

export namespace PDF_Template {
    import DateBeautify = HelperTime.DateBeautify;
    export type Color = [number, number, number]

    export const top_left_margin = 40;
    export const top_margin = 10;
    export const logo = {
        src: "/images/STLogowhite.png",
        ext: "PNG",
        pageX: top_left_margin,
        pageY: top_margin,
        srcX: 240,
        srcY: 35
    }
    export const line = {
        X: logo.pageX - 10,
        Y: logo.srcY + 25,
        W: 560
    }
    export const Title = {
        X: top_left_margin,
        Y: line.Y + 30
    }

    export const first_Item_Y = Title.Y + 30

    export function blankPage(pdf: jsPDF, page: number, Name: string, orientation: 'portrait' | 'landscape') {
        pdf.addImage(logo.src, logo.src, logo.pageX, logo.pageY, logo.srcX, logo.srcY);
        pdf.setLineWidth(3.0);
        pdf.setDrawColor(0, 0, 0);
        pdf.line(line.X, line.Y, pdf.internal.pageSize.getWidth() - 20, line.Y);
        pdf.setFontSize(8);
        pdf.setFont('Calibri', undefined, undefined);
        pdf.text(`${Name} - ${HelperTime.DateBeautify(new Date())}`, 30, pdf.internal.pageSize.getHeight() - 10); //print number bottom right
        page++;
    }


    export function SetTitle(pdf: jsPDF, PDFTitle: string, NextLogo?: boolean) {
        pdf.setFontSize(15);
        pdf.setFont('Calibri', undefined, 'bold')
        pdf.text(`${PDFTitle}`,  (pdf.internal.pageSize.width / 2) - (pdf.getStringUnitWidth(PDFTitle) * 15 / 2), NextLogo ? logo.pageY + 60 : Title.Y);
        pdf.setFontSize(12);
    }

    export function SectionTitle(pdf: jsPDF, title: string, y: number) {
        pdf.setFontSize(15);
        const xOffset = (pdf.internal.pageSize.width / 2) - (pdf.getStringUnitWidth(title) * 15 / 2);
        pdf.text(title, xOffset, y + 30).setFont('Calibri', undefined, 'bold');
        pdf.setLineWidth(1.0);
        pdf.line(line.X, y + 40, line.W, y + 40);
    }

    export async function addImage(pdf: jsPDF, y: number, img:string, svg?: boolean) {
        const lineX = line.X
        const base64 = svg? await HelperFunctions.svgToPng(img) : img
        const size = pdf.internal.pageSize.width - 2*(lineX)

        pdf.addImage(base64, 'PNG', lineX, y, size, 340);
    }

    export function addColumn<T>(data: T, color?: number[]): any {
        const paint = color ? color : [255, 255, 255];
        return {content: data, styles: {halign: 'center', fillColor: paint}}
    }


    export function addColumns(color?: number[], ...data: Array<string | boolean | undefined | number | Date>): any[] {
        const paint = color ? color : [255, 255, 255];
        const body: any[] = []

        Object.values(data).map(c => {
            if (c !== undefined) {
                let data = c;
                if (typeof data === 'boolean') data = data ? 'Yes' : 'No';
                else if (data instanceof Date) data = HelperTime.DateBeautify(data)
                else if (data === null) data = 'N/A'
                body.push({
                    content: data.toString(), /*colspan:colspan,*/
                    styles: {halign: 'center', fillColor: paint},
                    original: c
                })
            }

        })
        return body
    }

    export function addHeaders(color?: [number, number, number], ...data: Array<string | undefined | boolean>): RowInput[] {
        const paint = color ? color : [233, 236, 239] as [number, number, number];
        const header: any[] = []
        Object.values(data).map(c => {
            if (c) {
                header.push({content: c, colSpan: 1, styles: {halign: 'center', fillColor: paint, textColor: [0, 0, 0], fontStyle: 'bold'}})
            }
        })
        const PDFHeader: RowInput[] = []
        PDFHeader[0] = header


        return PDFHeader
    }

    export function GenerateAutoTable(pdf: jsPDF, header: RowInput[], body: RowInput[], start?: number, borderLine?: [number, number, number]) {
        autoTable(pdf, {
            theme: 'grid',
            headStyles: {
                fillColor: [139, 0, 0]
            },
            margin: {top: 30},
            startY: start ? start : Title.Y + 30,
            head: header,
            // bodyStyles: borderLine ? {lineColor: borderLine} : {},
            body: body,
            styles : { halign : 'center'},
            // pageBreak: 'avoid',
            // headStyles :{fillColor: [233, 236, 239]},
            // alternateRowStyles: {fillColor : [231, 215, 252]}

            // columnStyles: { text: { cellWidth: 'auto' } },
            // styles: { overflow: 'linebreak', cellWidth: 'auto' },
            //columnStyles: { text: { cellWidth: 'auto' } },
        });
    }


    export function ColsPanRow(title: string | undefined, d: string | boolean | undefined | Number, id?: number, Col?: number, singleCol?: boolean, halign?: 'center' | 'left' | 'right', color?: [number, number, number]) {
        if (typeof d === 'boolean') d = d ? 'Yes' : 'No'
        else if (d === null || d === undefined) d = 'N/A'

        const fillcolor = color ? color : [255, 255, 255]
        return [
            {
                content: title,
                colSpan: singleCol ? 0 : 3,
                styles: {fontStyle: 'bold', fillColor: [233, 236, 239], minCellWidth: 40, halign: halign},
                id: id ? id : ''
            },
            {
                content: d,
                colSpan: Col ? Col : 6,
                styles: {
                    fillColor: singleCol ? [233, 236, 239] : fillcolor,
                    minCellWidth: 40,
                    halign: halign,
                    fontStyle: singleCol ? 'bold' : 'normal',
                },
                id: id
            }
        ]
    }


// export function RunLookUpInfoTable(pdf: jsPDF, Runnumber: string | number, Stock: string, RunDate: Date | string, Itemcode: string, StockAlias: string, Qty: number | string) {
//     // if(typeof RunDate === 'string')
//     const date = typeof RunDate === 'string' ? RunDate : DateBeautify(RunDate)

//     autoTable(pdf, {
//         theme: 'grid',
//         margin: { top: Title.Y + 30 },
//         head: [['', ''],],
//         body: [
//             ["Runnumber", Runnumber, { styles: { fillColor: [230, 230, 230] } }],
//             ["Product", Stock, { styles: { fillColor: [230, 230, 230] } }],
//             ["Date Of Run", date, { styles: { fillColor: [230, 230, 230] } }],
//             ["Itemcode", Itemcode, { styles: { fillColor: [230, 230, 230] } }],
//             // ["Stock Alias", StockAlias, { styles: { fillColor: [230, 230, 230] } }],
//             ["Quantity Produced", Qty, { styles: { fillColor: [230, 230, 230] } }]
//         ],
//         showHead: false,
//         columnStyles: {
//             0: { fillColor: [233, 236, 239], textColor: [0, 0, 0], fontStyle: 'bold' },
//         },
//     });

// }


    export function SummaryTable(pdf: jsPDF, data: {
        [key: string]: string | number | undefined | boolean
    }, start?: number, theme?: "grid" | "striped" | "plain", firstColColor?: [number, number, number], firstColTextColor?: [number, number, number]) {
        const body: any[] = []
        let fillColor = [230, 230, 230];
        Object.entries(data).map(([k, c]) => {
            if (c !== undefined) {
                if (typeof c === 'boolean') {
                    fillColor = c ? [186, 222, 173] : [252, 217, 217]
                    c = c ? 'Yes' : 'No'
                } else if (c === null) c = 'N/A'
                body.push([k, c, {styles: {fillColor: fillColor}}])
            }
        })
        autoTable(pdf, {
            theme: theme ? theme : 'grid',
            margin: {top: 30},
            startY: start ? start : Title.Y + 30,
            head: [['', ''],],
            body: body,
            showHead: false,
            columnStyles: {
                0: {
                    fillColor: firstColColor ? firstColColor : [233, 236, 239],
                    textColor: firstColTextColor ? firstColTextColor : [0, 0, 0],
                    fontStyle: 'bold'
                },
            },
        });

    }

    export function WaterTakeSummary(pdf: jsPDF, t: ApiInterfaces.WaterTakeReport) {
        const d= t.data;
        autoTable(pdf, {
            theme: 'grid',
            margin: {top: Title.Y + 30},
            head: [['', '', '', '']],
            body: [
                ["Report Id", {content: t.report.id, colSpan: 3}],
                ["Report Title", {content: "Water Take Report", colSpan: 3}],
                ["Storage Name", {content: t.device.name, colSpan: 3}],
                ["Storage Coordinates", {content: `Lat ${t.device.lat} - Lng ${t.device.lng}` , colSpan: 3}],
                ["Created At", DateBeautify(t.report.created_at),"Finished", DateBeautify(t.report.finished)],
                ["Initial Volume", `${d[0].payload.volume.toFixed(3)} ML`,"Final Volume", `${d[d.length - 1].payload.volume.toFixed(3)} ML`],
                ["Initial Storage Level",`${d[0].payload.storageCapacity.toFixed(3)} %` ,"Final Storage Level", `${d[d.length - 1].payload.storageCapacity.toFixed(3)} %`],
                ["Initial Water Level", `${d[0].payload.waterLevel_AHD.toFixed(3)} AHD`, "Final Water Level" , `${d[d.length - 1].payload.waterLevel_AHD.toFixed(3)} AHD`],
                ["Initial Surface Area", `${d[0].payload.area.toFixed(3)} Ha`, "Final Surface Area", `${d[d.length - 1].payload.area.toFixed(3)} Ha`],
                [{content:"", colSpan:4}],
                ["Volume Gain",`${(d[d.length - 1].payload.volume - d[0].payload.volume).toFixed(3)} ML`,"Storage Delta", `${(d[d.length - 1].payload.storageCapacity - d[0].payload.storageCapacity).toFixed(3)} %`],
                ["Water Level Delta",`${(d[d.length - 1].payload.waterLevel_AHD - d[0].payload.waterLevel_AHD).toFixed(3)} AHD`,"Surface Area Delta", `${(d[d.length - 1].payload.area - d[0].payload.area).toFixed(3)} Ha`]

            ],
            showHead: false,
            columnStyles: {
                0: {fillColor: [233, 236, 239], textColor: [0, 0, 0], fontStyle: 'bold'},
                2: {fillColor: [233, 236, 239], textColor: [0, 0, 0], fontStyle: 'bold'},
            },

        });
    }

    export function WaterStorageSummary(pdf: jsPDF, t: ApiInterfaces.LIDStorage & {name:string}) {
        autoTable(pdf, {
            theme: 'grid',
            margin: {top: Title.Y + 30},
            head: [['', '', '', '']],
            body: [
                ["Storage Name", {content: t.name, colSpan: 3}],
                ["Area (Ha)", {content: t.Area}, "Storage Volume (ML)", {content: t.Volume}],
                ["Bottom Level (mHAD)", {content: t.Bottom_mHAD}, "Top Level (mHAD)", {content: t.Top_mHAD}],
                ["Depth (M)", {content: t.Bottom_mHAD}, "LOT/DP", {content: t.LOT}],
                ["Top Water (mHAD)", {content: t.TopWaterHeight}, "Top Bank (mHAD)", {content: t.TopBank}],
                ["Walkway (mHAD)", {content: t.Walkway}, "Height (mHAD)", {content: t.Height}],
            ],
            showHead: false,
            columnStyles: {
                0: {fillColor: [233, 236, 239], textColor: [0, 0, 0], fontStyle: 'bold'},
                2: {fillColor: [233, 236, 239], textColor: [0, 0, 0], fontStyle: 'bold'},
            },
        });
    }

    export function addPage(pdf: jsPDF, pageNumber:number, format:string, orientation:  "portrait" | "landscape", name: string, title:string) {
        pdf.addPage(format, orientation)
        blankPage(pdf, pageNumber, name, orientation)
        SetTitle(pdf, title)
    }

    export function SectionText(pdf: jsPDF, text: string, y: number, fontSize: number, style?: 'bold') {
        pdf.setFontSize(fontSize);
        pdf.text(text, 40, y + 30).setFont('Calibri', undefined, style ? style : undefined);
    }



}

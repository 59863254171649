import React from 'react';
import { connect, useSelector } from 'react-redux';
import { IRedux } from '../../Interfaces/redux';

const ReduxLoader: React.FC<IRedux.Loader> = (props) => {
    const loading = props.loading

    return (
        loading ? <div id="page" className={`h-100`}>
            <img id="loading-image" src="/logos/loading.gif" alt="Loading..." style={{ width: '60px', height: '60px' }} /> <br />
        </div> : <></>
    );
};


function mapStateToProps(state: IRedux.MapState ) {
    const { loading } = state.load;
    //
    return {
      loading
    };
}

export default connect(mapStateToProps)(ReduxLoader)
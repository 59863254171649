import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {IRedux} from "../../../Interfaces/redux";
import {ApiInterfaces} from "../../../Interfaces";
import {Collection, FormAction, Group, Input, Section} from "../../Utils/Forms/Interface";
import {DynamicForm} from "../../Utils/Forms/Form";
import {Button} from "react-bootstrap";
import {FileHelper} from "../../../Helper/FileHelper";
import {ApiHelper} from "../../../Server/Events/ApiOperations";


interface IProps extends IRedux.IAuth {
    handleEdit?: (changed: boolean) => void;
    device: Partial<ApiInterfaces.Device>;
    storage: Partial<ApiInterfaces.LIDStorage>


}

type formdata = Partial<ApiInterfaces.LIDStorage>


function LidStorage(props: IProps): JSX.Element {
    const {device, storage, user} = props
    const [confirm, setConfirm] = useState(false)
    const formRef = useRef<FormAction<formdata> | undefined>(undefined)

    const items: (Input | Group | Collection<Input | Group>)[] = []

    if (storage.storageCurve) {
        Object.entries(storage.storageCurve).forEach(([key]) => {
            items.push({
                width: 4,
                type: "text",
                id: `${key} elevation`,
                value: `[storageCurve.AKey-${key}.elevation]`,
                label: Number(key) === 0 ? "Elevation mAHD" : undefined
            })
            items.push({
                width: 4,
                type: "text",
                id: `${key} area`,
                value: `[storageCurve.AKey-${key}.area]`,
                label: Number(key) === 0 ? "Area Ha" : undefined
            })
            items.push({
                width: 4,
                type: "text",
                id: `${key} volume`,
                value: `[storageCurve.AKey-${key}.volume]`,
                label: Number(key) === 0 ? "Volume ML" : undefined
            })
        })
    }

    // useEffect(() => {
    //     formRef.current = form
    // }, [form]);

    const checkForChanges = () => {
        const f = formRef.current;
        const changed = f?.getFormChanged()
        if (changed) {
            // handleEdit(true)
        }
    }

    async function getForm() {
        const f = formRef.current;
        const formData = f?.getFormData()

        if (formData) {
            const {temp_id, ...data} = formData
            data.deviceId = device.id
            await ApiHelper.op('device.storage.edit', {...data}, user.companyId)
            setConfirm(false)


        }
    }

    const handleShow = (event: any) => {

        const show = formRef.current?.getValue('button_show')
        const hide = !(show === 'Show Storage Curve')

        formRef.current?.setHidden('storageCurveRow', hide)

        formRef.current?.setValue('button_show', hide ? 'Show Storage Curve' : 'Hide Storage Curve')
    }


    const filterLogs: Section = {
        type: "section",
        title: `${device.name} Registration`,
        id: "main",
        //className: "cs-location-section",
        items: [
            {
                type: "row",
                id: "Idsrow",
                //hidden: true,
                items: [
                    {width: 6, type: "number", id: "Area", label: "Area Ha", value: "[Area]", hidden: false},
                    {
                        width: 6,
                        type: "number",
                        id: "Volume",
                        label: "Storage Volume (ML)",
                        value: "[Volume]",
                        hidden: false
                    },
                    {
                        width: 6,
                        type: "number",
                        id: "Volume",
                        label: "Bottom Level (mHAD)",
                        value: "[Bottom_mHAD]",
                        hidden: false
                    },
                    {
                        width: 6,
                        type: "number",
                        id: "Volume",
                        label: "Top Level (mHAD)",
                        value: "[Top_mHAD]",
                        hidden: false
                    },
                    {width: 6, type: "number", id: "Volume", label: "Depth (M)", value: "[Depth]", hidden: false},
                    {width: 6, type: "text", id: "LOT", label: "LOT/DP", value: "[LOT]", hidden: false},
                    {width: 6, type: "text", id: "ESID", label: "ESID", value: "[ESID]", hidden: false},
                    {width: 6, type: "text", id: "TopBank", label: "TopBank", value: "[TopBank]", hidden: false},
                    {
                        width: 6,
                        type: "text",
                        id: "TopWaterHeight",
                        label: "TopWaterHeight",
                        value: "[TopWaterHeight]",
                        hidden: false
                    },
                    {width: 6, type: "text", id: "Walkway", label: "Walkway", value: "[Walkway]", hidden: false},
                    {width: 6, type: "text", id: "Height", label: "Sensor Height", value: "[Height]", hidden: false},

                ],
            },
            {
                type: "row",
                id: "ButtonROW",
                items: [{
                    width: 6,
                    type: "button",
                    id: "button_show",
                    style: 'outline-warning',
                    value: "Show Storage Curve",
                    hidden: false,
                    onClick: handleShow
                }]
            },
            {
                type: "row",
                id: "storageCurveRow",
                hidden: true,
                items: [...items]
            }

        ],
    };

    return (
        <>
            {(storage.id || storage.temp_id) &&
                <>
                    <div className="d-flex gap-2 justify-content-between">
                        {!confirm ?
                            <Button variant="outline-success w-100" onClick={() => setConfirm(true)}>
                                <i className="fas fa-save"></i> - Update Configuration
                            </Button> :
                            <>
                                <Button variant="success w-50"
                                        onClick={() => getForm()}>
                                    <i className="far fa-check-square"></i> - Please Confirm
                                </Button>

                                <Button variant="danger w-50"
                                        onClick={() => setConfirm(false)}>
                                    <i className="far fa-window-close"></i> - Cancel
                                </Button>
                            </>
                        }
                    </div>
                    <hr/>
                </>
            }
            <DynamicForm<formdata>
                actionTriggered={(id, action, form) => {
                    // setForm(form);
                    formRef.current = form
                    return true;
                }
                }
                valueChanged={(id, value, valid, form) => {
                    return true;
                }}
                id="FilterVisits"
                inputData={storage}
                content={[filterLogs]}
            />
        </>


    )
}

const mapStateToProps = (state: IRedux.MapState) => {
    const {user, isLoggedIn, accessToken, refreshToken, mqttToken} = state.auth;
    return {
        user,
        isLoggedIn,
        accessToken,
        refreshToken,
        mqttToken
    };
};

const connector = connect(mapStateToProps);

export default connector(LidStorage);
export interface KeyMap<T> {
    [key: string]: T,
}

export type CompleteMap<T, K extends string> = {
    [key in K]: T
}

export const verifyMap = <T, K extends string>(map: KeyMap<T>, keys: K[]): map is CompleteMap<T, K> => {
    return keys.filter(k => map[k] === undefined).length === 0;
}

export const makeKeyMapGeneric = <T>(items: T[], getId: (item: T) => string) => {
    // ("makeKey Generic", items)
    const result: KeyMap<T> = {};
    items.forEach((i) => {
        result[getId(i)] = i;
    });
    return result;
};

export const makeKeyMap = <T extends { id: string }>(items: T[]) => {
    return makeKeyMapGeneric(items, i => i.id);
};

export const filterList = <I, O extends I>(items: I[], predicate: (data: I) => data is O): O[] => {
    const r: O[] = [];
    items.forEach((i) => {
        if (predicate(i)) {
            r.push(i);
        }
    })
    return r;
};

export const removeUndefined = <T>(items: (T | undefined)[]): T[] => filterList(items, (i): i is T => i !== undefined);




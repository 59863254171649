import {AGMAC_Timers} from "../../Helper/Timers";
import {HelperFunctions} from "../../Helper/functions";
import {ApiInterfaces} from "../../Interfaces";
import {Externals} from "../../Interfaces/Externals";
import {IoTListResponse} from "../../Server/Events/base";
import {GraphBuilder} from "./Default";
import Highcharts from "highcharts";

interface CustomIcon {
    icon: string;
    index: number;
    title: string;
    class: string;
    click: (chart: any, options: any, e: Event) => void; // Specify return type as void
}

const customIcons = (expand: (chart: any, options: any, e: Event) => void): CustomIcon[] =>
    [{
        icon: '<img class="ApexCustomIcon" src="/images/resize.png" width="60" height="60" style="padding-left:4px; padding-right:4px;">',
        index: 1,
        title: 'tooltip of the icon',
        class: 'custom-icon',
        click: expand
    }];


export const line_series_options = (name: string, title: string, dataLabels?: boolean, expand?: (chart: any, options: any, e: Event) => void) => ({
    chart: {
        type: "area",
        foreColor: '#fff',
        zoom: {
            enabled: true,
        },

        animations: {
            enabled: false,
        },

        toolbar: {
            export: {
                csv: {
                    filename: name,
                    columnDelimiter: ",",
                    headerCategory: "Time",
                    headerValue: "value",
                    dateFormatter(timestamp: number) {
                        return new Date(timestamp).toString();
                    },
                },
            },

            tools: {
                pan: false,
                reset: true,
                zoomin: false,
                zoomout: false,
                customIcons: expand && customIcons(expand)
            }
        },
    },

    stroke: {
        width: 3,
        curve: "smooth",
    },
    title: {
        text: title,
        align: 'center'
    },

    dataLabels: {
        enabled: dataLabels || false,
    },
    grid: {
        borderColor: '#e7e7e7',
        row: {
            colors: ['#000', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
        },
    },
    legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        fontSize: '16px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 400,
        offsetX: 5,
    },
    xaxis: {
        type: "datetime",
        tickAmount: 50,
        labels: {
            datetimeUTC: false,
            format: "dd/MM H:mm",
        },
    },
    tooltip: {
        x: {
            format: "dd/MM H:mm",
        },
        theme: 'dark'
    },
}) as ApexCharts.ApexOptions


export const bar_time = (name: string, title: string, dataLabels?: boolean, expand?: (chart: any, options: any, e: Event) => void) => ({
    chart: {
        height: 350,
        type: 'bar',
        foreColor: '#fff',
        zoom: {
            enabled: true,
        },

        animations: {
            enabled: false,
        },

        toolbar: {
            export: {
                csv: {
                    filename: name,
                    columnDelimiter: ",",
                    headerCategory: "Time",
                    headerValue: "value",
                    dateFormatter(timestamp: number) {
                        return new Date(timestamp).toString();
                    },
                },
            },

            tools: {
                pan: false,
                reset: true,
                zoomin: false,
                zoomout: false,
                customIcons: expand && customIcons(expand)
            }
        },

    },
    plotOptions: {
        bar: {
            borderRadius: 1,
        }
    },

    title: {
        text: title,
        align: 'center'
    },

    dataLabels: {
        enabled: dataLabels || false,
    },
    grid: {
        borderColor: '#e7e7e7',
        row: {
            colors: ['#000', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
        },
    },
    legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'bottom',
        horizontalAlign: 'center',
        floating: false,
        fontSize: '16px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 400,
        offsetX: 5,
    },
    xaxis: {
        type: "datetime",
        tickAmount: 50,
        labels: {
            datetimeUTC: false,
            format: "dd/MM H:mm",
        },
    },
    tooltip: {
        x: {
            format: "dd/MM H:mm",
        },
        theme: 'dark'
    },


}) as ApexCharts.ApexOptions


export const timeline_series_options = {
    chart: {
        height: 350,
        foreColor: '#fff',
        type: 'rangeBar',
        zoom: {
            enabled: false
        },
        animations: {
            enabled: false
        }
    },
    plotOptions: {
        bar: {
            horizontal: true,
            distributed: true,
            dataLabels: {
                hideOverflowingLabels: false
            }
        }
    },

    yaxis: {
        show: false
    },
    xaxis: {
        type: 'datetime',
        min: new Date("1975-01-01T00:00").getTime(), // start date
        max: new Date("1975-01-01T23:59").getTime(), // end date
        //tickAmount: 48, // interval you want
        labels: {
            datetimeUTC: false,
            format: 'H:mm'
        }
    },
    grid: {
        borderColor: '#e7e7e7',
        row: {
            colors: ['#000', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
        },
    },
    tooltip: {
        x: {
            format: "H:mm",
        },
        theme: 'dark'
    },


} as ApexCharts.ApexOptions;


export const arrg = {
    chart: {
        height: 400,
        type: 'rangeBar'
    },
    plotOptions: {
        bar: {
            horizontal: true,
            barHeight: '80%',
            rangeBarOverlap: false,
            columnWidth: '10%',
        }
    },
    xaxis: {
        type: 'datetime',
        position: 'top',
    },
    yaxis: {
        forceNiceScale: true
    },
    stroke: {
        width: 1
    },
    fill: {
        type: 'solid',
        opacity: 0.6
    },
    legend: {
        position: 'top',
        horizontalAlign: 'center'
    }
} as ApexCharts.ApexOptions


export type ApexAxisSeriesLinearData = {
    name?: string | undefined;
    type?: string | undefined;
    color?: string | undefined;
    data: {
        x: any;
        y: any;
        fillColor?: string | undefined;
        strokeColor?: string | undefined;
        meta?: any;
        goals?: any;
    }[];
}

export interface Line_Series_build {
    name: string,
    x_Axis: string,
    y_Axis: PropertyKey,
    filter_y_Axis?: { field: string, value: string }
}


export function generateApexChartsWithMetadata(data: ApiInterfaces.DeviceData[], metadata: GraphBuilder.Metadata[]): ApexAxisChartSeries {
    const metadataMap = new Map<string, GraphBuilder.Metadata>();

    metadata.forEach(entry => {
        metadataMap.set(entry.context, {...entry, values: []});
    });

    data.forEach(item => {
        Object.keys(item.payload || {}).forEach(context => {
            const metadataEntry = metadataMap.get(context);
            if (metadataEntry) {
                const value = item.payload[context] || 0;
                metadataEntry.values.push({x: item.timestamp, y: value});
            }
        });
    });

    const apexCharts: ApexAxisChartSeries = Array.from(metadataMap.values()).map(({key, values, color}) => ({
        name: key,
        data: values,
        // color: color,
    }));

    return apexCharts;
}


export function generateTimelineApexCharts(nodes: ApiInterfaces.LoraNode[], day: number): ApexAxisChartSeries {

    const weekDay = AGMAC_Timers.daysOfWeek[day];

    const apexChart: ApexAxisChartSeries = [];

    nodes.forEach(node => {
        const metadata = AGMAC_Timers.addPropertiesIfNotExists(node.metadata as AGMAC_Timers.TimerMetadata)
        const jsonTimers: any[] = [];
        metadata.WeekDaysArray.forEach(day => {
            if (weekDay === day.dayOfWeek) jsonTimers.push({
                x: node.name,
                y: [new Date(day.start).getTime(), new Date(day.end).getTime()]
            })
        })
        apexChart.push({
            name: node.name,
            data: jsonTimers,
        });
    })

    return apexChart
}


export const Generate_Line_Series = <T extends Object>(data: T[], ...build: Line_Series_build[]): ApexAxisChartSeries => {

    const series: { [key: string]: ApexAxisSeriesLinearData } = {};

    build.forEach(y => series[y.name] = {name: y.name, data: []})

    Object.values(data).map(el => build.forEach(t => {
        if (HelperFunctions.hasOwnProperty(el, t.x_Axis) && HelperFunctions.hasOwnProperty(el, t.y_Axis)) {
            t.filter_y_Axis ?
                HelperFunctions.hasOwnProperty(el, t.filter_y_Axis.field) && el[t.filter_y_Axis.field] === t.filter_y_Axis.value && series[t.name].data.push({
                    x: el[t.x_Axis],
                    y: Number(el[t.y_Axis])
                })
                : series[t.name].data.push({x: el[t.x_Axis], y: Number(el[t.y_Axis])})
        }
    }))

    return Object.values(series)
}
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { ApiInterfaces } from '../../Interfaces';
import Chart from './Charts';

interface GraphModalProps {
    show: boolean;
    handleClose: () => void;
    device: ApiInterfaces.Device | undefined;
    key: string | undefined

}




function GraphModal(props: GraphModalProps) {
    const { show, device, handleClose } = props;

    return (
        device ?
            <Modal keepMounted  show={show} onHide={handleClose} dialogClassName="graph_modal">
                <Modal.Header closeButton>
                    <Modal.Title>{`${device.name} - Trends`}</Modal.Title>
                </Modal.Header>
                <Modal.Body  >
                    <Chart device={device} key={device.id} type={'line'} shared={!device.aggregate} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant='danger' onClick={handleClose}>  <i className="far fa-times-circle"></i> &nbsp; Close</Button>
                </Modal.Footer>
            </Modal >
            : <></>
    );
}



export default GraphModal

import React from 'react';

interface IconsProps {
    smallIcon?: boolean;
}

const Others: React.FC<IconsProps> = ({ smallIcon }) => {
    return (
        <svg className={smallIcon ? "iconSm iconSm-sunshower" : "icon icon-sunshower"} viewBox="0 0 220 220">

            <g className="sunny-short-ray">
                <path fill="#43647E" d="M149.347,30.932l-0.014-8.394c-0.003-1.617-1.318-2.927-2.935-2.925c-1.616,0.003-2.924,1.318-2.924,2.935
		l0.014,8.474C145.45,30.86,147.406,30.839,149.347,30.932z" />
                <path fill="#43647E" d="M112.781,46.829c1.257-1.484,2.607-2.9,4.063-4.221l-5.938-5.918c-1.146-1.142-3-1.14-4.143,0.005
		c-1.142,1.146-1.139,3.001,0.008,4.142L112.781,46.829z" />
                <path fill="#43647E" d="M200.662,78.134c0.388-0.001,0.756-0.078,1.094-0.213c1.074-0.437,1.83-1.492,1.83-2.721
		c-0.004-1.617-1.315-2.928-2.933-2.926l-8.478,0.016c0.164,1.959,0.186,3.916,0.091,5.855L200.662,78.134z" />
                <path fill="#43647E" d="M180.593,45.644l5.918-5.937c1.144-1.146,1.14-3-0.005-4.142c-1.147-1.143-3.001-1.14-4.143,0.004
		l-5.992,6.013C177.856,42.838,179.27,44.189,180.593,45.644z" />
                <path fill="#43647E" d="M93.738,73.868c-1.619,0.002-2.928,1.316-2.924,2.935c0.004,1.615,1.318,2.925,2.934,2.923l8.473-0.014
		c-0.16-1.963-0.182-3.917-0.088-5.857L93.738,73.868z" />
                <path fill="#43647E" d="M181.62,105.171c-1.258,1.484-2.609,2.899-4.063,4.223l5.939,5.918c0.857,0.855,2.111,1.068,3.167,0.639
		c0.354-0.143,0.687-0.357,0.974-0.646c1.143-1.145,1.139-3-0.006-4.141L181.62,105.171z" />
                <path fill="#43647E" d="M145.055,121.067l0.013,8.395c0.003,1.617,1.317,2.928,2.934,2.922c0.388,0,0.755-0.074,1.093-0.213
		c1.077-0.434,1.834-1.488,1.83-2.719l-0.014-8.475C148.95,121.14,146.994,121.161,145.055,121.067z" />
                <path fill="#43647E" d="M113.807,106.356l-5.917,5.938c-1.142,1.145-1.141,3,0.006,4.143c0.857,0.855,2.112,1.068,3.17,0.641
		c0.354-0.145,0.687-0.361,0.972-0.646l5.991-6.012C116.545,109.161,115.129,107.812,113.807,106.356z" />
            </g>
            <g className="sunny-long-ray">
                <path fill="#43647E" d="M175.881,17.208c0.936-2.209-0.096-4.761-2.307-5.697c-2.211-0.938-4.763,0.096-5.697,2.306l-7.959,18.792
		c-0.014,0.034-0.021,0.07-0.035,0.103c2.787,0.818,5.487,1.9,8.064,3.232L175.881,17.208z" />
                <path fill="#43647E" d="M125.51,36.326c0.014,0.031,0.035,0.058,0.051,0.091c1.508-0.822,3.072-1.576,4.703-2.238
		c1.087-0.44,2.184-0.82,3.283-1.17l-7.639-18.862c-0.901-2.226-3.436-3.298-5.662-2.397c-2.223,0.901-3.299,3.435-2.395,5.66
		L125.51,36.326z" />
                <path fill="#43647E" d="M85.019,55.324l18.79,7.959c0.033,0.012,0.07,0.021,0.104,0.032c0.818-2.786,1.901-5.485,3.234-8.061
		l-18.74-7.935c-2.209-0.937-4.761,0.098-5.696,2.308C81.773,51.839,82.809,54.389,85.019,55.324z" />
                <path fill="#43647E" d="M186.783,54.359c0.821,1.508,1.576,3.074,2.236,4.705c0.439,1.088,0.821,2.183,1.171,3.284l18.862-7.638
		c2.226-0.902,3.299-3.437,2.398-5.661c-0.901-2.224-3.437-3.299-5.661-2.398l-18.916,7.66
		C186.844,54.323,186.815,54.345,186.783,54.359z" />
                <path fill="#43647E" d="M118.521,134.792c-0.937,2.211,0.097,4.762,2.308,5.697c1.105,0.469,2.295,0.445,3.324,0.027
		c1.034-0.418,1.905-1.229,2.371-2.334l7.959-18.789c0.016-0.035,0.023-0.072,0.037-0.105c-2.787-0.818-5.488-1.9-8.065-3.232
		L118.521,134.792z" />
                <path fill="#43647E" d="M168.889,115.675c-0.012-0.033-0.031-0.061-0.047-0.092c-1.508,0.822-3.074,1.574-4.704,2.238
		c-1.089,0.439-2.185,0.82-3.284,1.17l7.639,18.863c0.901,2.225,3.436,3.297,5.662,2.395c2.223-0.902,3.297-3.434,2.397-5.66
		L168.889,115.675z" />
                <path fill="#43647E" d="M107.619,97.643c-0.824-1.51-1.578-3.074-2.238-4.707c-0.441-1.085-0.821-2.183-1.171-3.282l-18.862,7.641
		c-2.225,0.899-3.297,3.436-2.396,5.658c0.9,2.227,3.435,3.299,5.66,2.398l18.914-7.66
		C107.559,97.677,107.586,97.657,107.619,97.643z" />
                <path fill="#43647E" d="M209.383,96.677l-18.791-7.959c-0.033-0.014-0.068-0.02-0.104-0.033c-0.818,2.786-1.9,5.484-3.234,8.062
		l18.739,7.936c1.104,0.467,2.295,0.443,3.327,0.025c1.029-0.417,1.902-1.228,2.371-2.334
		C212.626,100.163,211.593,97.612,209.383,96.677z" />
            </g>
            <g className="sunny-body">
                <path fill="#43647E" d="M180.088,62.681c-7.357-18.162-28.043-26.923-46.205-19.568c-18.164,7.356-26.925,28.044-19.568,46.205
		c7.354,18.165,28.043,26.926,46.205,19.569C178.684,101.53,187.443,80.846,180.088,62.681z M154.733,50.464
		c-0.411,1.812-2.217,2.948-4.026,2.535c-4.427-1.007-8.997-0.636-13.221,1.075c-5.488,2.224-9.782,6.45-12.091,11.9
		c-2.308,5.453-2.356,11.475-0.134,16.964c0.697,1.721-0.134,3.684-1.857,4.381c-0.413,0.168-0.841,0.248-1.262,0.248
		c-1.33,0-2.588-0.795-3.117-2.104c-2.898-7.154-2.836-15.008,0.174-22.113c3.007-7.108,8.605-12.619,15.76-15.516
		c5.504-2.229,11.469-2.715,17.241-1.398C154.012,46.849,155.147,48.652,154.733,50.464z" />
            </g>
            <g className="rain-drops">
                <path fill="#43647E" d="M37.69,141.629c-0.852,6.32-11.666,18.842-11.666,27.824c0,6.443,5.225,11.664,11.666,11.664
		c6.443,0,11.666-5.221,11.666-11.664C49.356,160.07,38.444,148.1,37.69,141.629z" />
                <path fill="#43647E" d="M77.874,141.629c-0.854,6.32-11.666,18.842-11.666,27.824c0,6.443,5.223,11.664,11.666,11.664
		s11.666-5.221,11.666-11.664C89.54,160.07,78.626,148.1,77.874,141.629z" />
                <path fill="#43647E" d="M118.056,141.629c-0.854,6.32-11.664,18.842-11.664,27.824c0,6.443,5.223,11.664,11.664,11.664
		c6.445,0,11.666-5.221,11.666-11.664C129.722,160.07,118.81,148.1,118.056,141.629z" />
            </g>
            <g className="cloud-offset">
                <path fill="#43647E" d="M113.903,143.264c-6.173,0-12.273-1.229-17.931-3.585c-6.062,2.515-12.218,3.585-19.999,3.585
	c-8.325,0-16.356-1.866-23.959-5.559c-5.329,2.711-11.262,4.119-17.492,4.119c-21.27,0-38.574-17.306-38.574-38.576
	c0-15.344,9.325-29.175,22.996-35.269c6.653-25.268,29.615-42.96,57.029-42.96c19.873,0,38.259,9.958,49.18,26.313
	c20.532,5.085,35.406,23.653,35.406,45.276C160.56,122.334,139.63,143.264,113.903,143.264z" />
            </g>
    
            <g className="rain-cloud">
                <path fill="#43647E" d="M118.294,61.231c-8.359-15.388-24.715-25.212-42.32-25.212c-24.457,0-44.283,17.108-47.506,40.333
	c-12.301,2.767-21.52,13.771-21.52,26.896c0,15.205,12.369,27.576,27.574,27.576c6.396,0,12.348-2.078,17.133-5.917
	c7.713,4.944,15.705,7.356,24.318,7.356c8.145,0,13.68-1.295,20.043-4.816c5.418,3.152,11.541,4.816,17.887,4.816
	c19.662,0,35.656-15.996,35.656-35.656C149.56,78.432,135.888,63.401,118.294,61.231z" />
            </g>

        </svg>

    )
}

export default Others
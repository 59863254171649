import { useEffect, useRef, useState } from "react";
import { Collection, FormAction, Group, Input, Section } from "../Utils/Forms/Interface";
import { DynamicForm } from "../Utils/Forms/Form";
import { connect } from "react-redux";
import { IRedux } from "../../Interfaces/redux";
import { Button, Modal } from "react-bootstrap";
import { ApiInterfaces } from "../../Interfaces";
import { IoTGetLastResponse } from "../../Server/Events/base";
import UnsavedModal from "../Utils/Unsaved";
import APIManager from "../../Server/Api/APIInterface";
import { store } from "../../store";
import { showSuccess } from "../../redux/actions/alertsAction";
import { SVG_Panel } from "../Utils/SVG";
import { BindingEvent } from "../../Server/Binding/Events/Events";
import LIDConfig from "./Form/LIDConfig";


interface IProps extends IRedux.IAuth {
    show: boolean;
    handleClose: () => void;
    device: Partial<ApiInterfaces.Device>
    payload: JSON | IoTGetLastResponse
}

type formdata = Partial<ApiInterfaces.Device>


function DeviceEditModal(props: IProps):JSX.Element {
    const { show, handleClose, device, payload } = props;
    const [dialog, setDialog] = useState<boolean>(false)
    const [showStorage, setShowStorage] = useState<boolean>(false)

    const formRef = useRef<FormAction<formdata> | undefined>(undefined)

    const items: (Input | Group | Collection<Input | Group>)[] = []

    if (payload) {
        let count = 0
        Object.entries(payload).forEach(([key, x]) => {
            items.push({ width: 3, type: "text", id: `${key} payload`, value: key, label: count === 0 ? "Payload" : undefined, disabled: true })
            items.push({ width: 2, type: "text", id: `${key} name`, value: `[metadata.AKey-${key}.name]`, label: count === 0 ? "Name" : undefined, placeholder: "Insert Name..." })
            items.push({ width: 3, type: "text", id: `${key} unit`, value: `[metadata.AKey-${key}.unit]`, label: count === 0 ? "Unit" : undefined, placeholder: "Insert Unit..." })
            items.push({ width: 2, type: "IconSelect", options: SVG_Panel.DropDownIconMenu(), id: `${key} svg`, value: `[metadata.AKey-${key}.svg]`, label: count === 0 ? "Icon" : undefined, placeholder: "Choose Icon..." })
            items.push({ width: 1, type: "color", id: `${key} color`, placeholder: 'Seu Cu', value: `[metadata.AKey-${key}.color]`, label: count === 0 ? "Color" : undefined })
            items.push({ width: 1, type: "checkbox", id: `${key} visible`, placeholder: 'Seu Cu', value: `[metadata.AKey-${key}.visible]`, label: count === 0 ? "visible" : undefined })

            if (window.innerWidth < 760) items.push({ type: "line", value: "" })
            count = 1
        })
    }

    useEffect(() => {

        const setUp = () => {
            if (payload && device) {
                Object.keys(payload).forEach((key) => {
                    if (!device.metadata) return
                    if (!device.metadata?.hasOwnProperty(key)) {
                        device.metadata[key] = {
                            name: key,
                            unit: "",
                            value: "",
                            svg: "",
                            visible: false
                        }
                    }
                })
            }
        }

        setUp()
    }, []);


    const checkForChanges = () => {
        const f = formRef.current;
        const changed = f?.getFormChanged()
        if (changed) {
            setDialog(true)
        } else handleClose()
    }

    async function getForm() {
        const f = formRef.current;
        const data = f?.getFormData()

        if (data && data.id) {
            const { display, payload, status, ...device } = data
            const res = await APIManager.ApiRequest('device.edit', { accessToken: props.accessToken, ...device, id: data.id })
            if (res.type === 'device.edit') {
                BindingEvent.notify('change', BindingEvent.Event(res), `${res.companyId}/${res.type.split('.')[0]}`)
                store.dispatch(showSuccess("Changes were saved successfully"))
                handleClose()
            }
        }
    }

    const onDrag = (event: any) => {
        formRef.current?.setValue('lat', event.latLng.lat())
        formRef.current?.setValue('lng', event.latLng.lng())
    }

    const filterLogs: Section = {
        type: "section",
        title: "Device Edit Form",
        id: "main",
        //className: "cs-location-section",
        items: [
            {
                type: "row",
                id: "Idsrow",
                //hidden: true,
                items: [

                    // { width: 5, type: "dropdown", id: "Section", value: "[section]", label: "Section:", hidden: true },
                    { width: 12, type: "text", id: "Id", label: "Device Id", value: "[id]", hidden: !device, disabled:true },
                    { width: 6, type: "text", id: "name", label: "Name", value: "[name]", hidden: false },
                    { width: 6, type: "text", id: "Type", label: "Type", value: "[class]", disabled: true },
                    { width: 6, type: "number", id: "lat", label: "Coordinates Latitude", value: "[lat]", disabled: true, hidden: !device?.lat },
                    { width: 6, type: "number", id: "lng", label: "Coordinates Longitude", value: "[lng]", disabled: true, hidden: !device?.lat },
                    { width: 12, label: "Drag Marker to new location", type: "map", id: "map", value: "", defaultZoom: 13, defaultCenter: { lat: device?.lat || 0, lng: device?.lng || 0 }, onDrag, disabled: true, hidden: !device?.lat },

                    // { width: 2, type: "button", id: "submit", style: 'success', value: "Submit", hidden: false, onClick: () => getForm() },


                ],
            },

        ],
    };

    const PayloadSection: Section = {
        type: "section",
        title: "Device Panel",
        id: "PayloadSection",
        //className: "cs-location-section",
        items: [
            {
                type: "row",
                id: "payloadRow",
                //hidden: true,
                items: items
            },

        ],
    };

    function renderForm() {
        return (
            !showStorage ?
                <DynamicForm<formdata>
                    actionTriggered={(id, action, form) => {
                        formRef.current = form;
                        return true;
                    }
                    }
                    valueChanged={(id, value, valid, form) => {
                        return true;
                    }}
                    id="FilterVisits"
                    inputData={device}
                    content={[filterLogs, PayloadSection]}
                />
                :
                <LIDConfig device={device}/>
        )
    }
    return (
        <>
            <UnsavedModal
                action={handleClose}
                show={dialog}
                handleClose={() => setDialog(false)}
            />
            <Modal show={show} onHide={checkForChanges} className="qc_modal" >
                <Modal.Header closeButton className="text-center">
                    <Modal.Title >{device?.name || "aa"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {renderForm()}
                </Modal.Body>
                <Modal.Footer>
                    {device?.class === 'Storage' && <Button variant={`${!showStorage ? 'outline-' : ""}warning`} onClick={() => {
                        setShowStorage(prevState => (!prevState))
                    }}>  &nbsp; <i
                        className="fas fa-cog"></i>  &nbsp; Show {showStorage ? 'Device' : 'Storage' } Configuration </Button>}
                    {!showStorage && <>
                        <Button variant='success' onClick={getForm}>  &nbsp; <i
                            className="far fa-save"></i>  &nbsp; Save</Button>
                        <Button variant='danger' onClick={checkForChanges}>  &nbsp; <i
                            className="far fa-times-circle"></i> &nbsp; Close</Button>
                    </>}
                </Modal.Footer>
            </Modal >
        </>

    )
}

const mapStateToProps = (state: IRedux.MapState) => {
    const { user, isLoggedIn, accessToken, refreshToken, mqttToken } = state.auth;
    return {
        user,
        isLoggedIn,
        accessToken,
        refreshToken,
        mqttToken
    };
};

const connector = connect(mapStateToProps);

export default connector(DeviceEditModal);
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom';

import './scss/react.scss';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import "./styles.css"
import "primereact/resources/themes/arya-orange/theme.css"
import 'primereact/resources/primereact.css'


import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Wrapper } from '@googlemaps/react-wrapper';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

if (process.env.NODE_ENV === 'production') {
  console.log = () => { }
  console.count = () => { }
  console.error = () => { }
  console.debug = () => { }
}

root.render(


  <Provider store={store}>
    <PersistGate persistor={persistor}>
      {/* <React.StrictMode> */}
        <BrowserRouter>
          <Wrapper  apiKey={`${process.env.REACT_APP_GOOGLE_KEY}&loading=async`}>
            <App />
          </Wrapper>
        </BrowserRouter>
      {/* </React.StrictMode> */}
    </PersistGate>
  </Provider>

  ,
);
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

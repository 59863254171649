
import { KeyMap } from "../../Helper/Core/interface";
import { Events } from "../Events/Events";
import { Filters } from "../Events/generic";


// import { apiActions, ModelType } from "./Events/ValidAPI";
export namespace BindHelper {

  export const deleteAPIData = (data: { items: KeyMap<any> }, item: any) => {

    delete data.items[item.id];
  }

  export const updateAPIData = (data: { items: KeyMap<any> }, item: any) => {
    data.items[item.id] = item;
  }


  export const updateMultipleAPIData = (data: { items: KeyMap<any> }, items: any[], filters?: Filters) => {
    Object.values(items).forEach(x => {
      data.items[x.id] = x;
    })
  }



  // const supportedApis: {
  //     [key: string]: {
  //         modelName: ModelType,
  //         model: any,
  //         //api: API.Core.Model<any>,
  //         action: apiActions,
  //     },
  // } = {};

  // export const isSupportedAPI = (type: ApiEventTypes): boolean => supportedApis[type] !== undefined;
  // export const isSupportedAPIRequest = (event: Values<Events>['request']) => supportedApis[event.type] !== undefined;
  // export const isSupportedAPIResponse = (event: Values<Events>['response']) => supportedApis[event.type] !== undefined;




  // const getReferences = (
  //     modelType: ModelType,
  //     transform: any,
  //     input: any,
  //     options: {
  //         api: apiActions,
  //         objectReferences: boolean,
  //     },
  // ) => {
  //     const refs: { def: RefTransform<any>, id: string }[] = [];
  //     processTransformer(input, {}, transform, {
  //         api: options.api,
  //         inputType: 'api',
  //         outputType: 'api',
  //         valueSelector: (t) => isRefTransform(t),
  //         valueProcessor: (t, v) => {
  //             if (isRefTransform<any>(t)) {
  //                 refs.push({ def: t, id: v });
  //             }
  //         },
  //     });
  //     if (input.id) {
  //         refs.push({ def: { referencedType: modelType, type: 'ref', key: 'id' }, id: input.id });
  //     }
  //     return refs;
  // }


  type Values<T> = T[keyof T];

  export const synthesisAPIResponse = (data: { items: KeyMap<any> }, requestType: Values<Events>['response']['type']) => {
    const response: any = {
      // status: 200,
      // message: '',
      type: requestType,
    };

    if (requestType.includes('.get')) {
      const item = Object.values(data.items)[0];
      if (item) {
        Object.assign(response, item);
      } else {
        response.type = 'error';
        response.status = 404;
        response.message = 'No data found';
      }
    } else if (requestType.includes('.list')) {
      response.data = Object.values(data.items);
    }
    return response;
  }


  export const getAPIRequestBinding = (e: Values<Events>['request']) => {
    let binding: string | undefined = undefined;
    const ids: { type: string, id: string }[] = [];
    binding = e.type.split('.')[0];
    const id = (e as any).id;
    if (id) {
      ids.push({ type: e.type.split('.')[0], id: id });
    }

    return { binding, ids };
  }


  export const getModelCreateBinding = (event: { objectType: string, data: any }) => {
    const ids: { type: string, id: string }[] = [];
    const binding: { [key: string]: boolean } = {};
    ids.push({ type: event.objectType, id: event.data['id'] });
    binding[event.objectType] = true;
    return { ids, binding };
  }


  export const getModelEditBinding = (event: { objectType: string, data: any }) => {

    const ids: { type: string, id: string }[] = [];
    const binding: { [key: string]: boolean } = {};
    binding[event.objectType] = true;
    return { ids, binding };
  }



  export const getAPIResponseBinding = (e: Values<Events>['response']): { binding: string | undefined, ids: { type: string, id: string }[] } => {

    let binding: string | undefined = undefined;
    const ids: { type: string, id: string }[] = [];
    if (e.type.includes('.get')) {
      binding = e.type.split('.')[0];
      ids.push({ type: e.type.split('.')[0], id: (e as any)['id'] });
    } else if (e.type.includes('.list')) {
      binding = e.type.split('.')[0];
      (((e as any).data || []) as any[]).forEach(i => {
        ids.push({ type: e.type.split('.')[0], id: i['id'] });
      })
    }
    return { binding, ids };
  }


  // export const getAPIData = (request: Values<Events>['request'], response: Values<Events>['response']): KeyMap<any> => {
  //   (request, response)
  //   if ((response as any).type.includes('.get')) {
  //     const r: any = {};
  //     // getEventData(response, r, request.type.split('.')[0], { api: 'get' });
  //     r.id = (request as any).id;
  //     return {
  //       [r.id]: r,
  //     };
  //   } else if ((response as any).type.includes('.list')) {
  //     const res: KeyMap<any> = {};
  //     (((response as any).data || []) as any[]).forEach(i => {
  //       const r: any = {};
  //       // getEventData(i, r, e.type.split('.')[0], { api: 'list' });
  //       if (r.id) {
  //         res[r.id] = r;
  //       }
  //     });
  //     return res;
  //   }
  //   return {};
  // }



  export const getAPIData = (request: Values<Events>['request'], o: Values<Events>['response']): KeyMap<any> => {

    const t: any = o
    if (t.type.includes('.get') ) {
      return {
        [t['id']]: o
      }
    } else if (t.type.includes('.list')) {
      const res: KeyMap<any> = {};
      ((t.data || []) as any[]).forEach(i => {
        res[i[`id`]] = i;
      });
      return res;
    }
    return {};
  }





  // const getEventData = (
  //   input: any,
  //   output: any,
  //   transform: Transform<any>,
  //   options: {
  //     api: apiActions,
  //     optional?: boolean,
  //   },
  // ): void => {
  //   processTransformer(input, output, transform, {
  //     api: options.api,
  //     inputType: 'api',
  //     outputType: 'api',
  //   }, undefined, options.api === 'list' || options.api === 'custom');
  // }








}



import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Badge, Table, Card, Button } from 'react-bootstrap';
import TimeAgo from 'react-timeago';
import { Subscription } from 'rxjs';
import { ApiInterfaces } from '../../Interfaces';
import { IRedux } from '../../Interfaces/redux';
import { GetMqtt } from '../../Server/Api/MqttManager';
import { IoT } from '../../Helper/IoT';
import { SVG_Panel, SvgIcon } from '../Utils/SVG';
import { BindingHooks } from "../../Server/Binding/bidings/BindingHook";
import { HelperTime } from "../../Helper/Time";
import { useSelector } from "react-redux";

type IProps = {
    device: ApiInterfaces.Device;
    key: string;
    expanded?: boolean;
};

type Props = IProps;

const DevicePanel = ({ device, expanded }: Props) => {
    const { mqttToken } = useSelector((state: IRedux.MapState) => state.auth);
    const { data, setData } = BindingHooks.IOTLastValuePanel(device, `live-${device.id}-${expanded}`);
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const [expand, setExpand] = useState<boolean>(!!expanded);

    useEffect(() => {
        const stream: Subscription = GetMqtt().getMessage(`${device.companyId}/${device.id}/Live/`).subscribe(async (message) => {
            setData({ ...IoT.updatePanelData(device, JSON.parse(message), device.IO ? undefined : new Date()) });
            if (timerRef.current) clearTimeout(timerRef.current);
            if (device.IO) await checkNewMessages();
        });
        if (device.IO) checkNewMessages();
        return () => {
            stream.unsubscribe();
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, [mqttToken]);

    const checkNewMessages = useCallback(async () => await delay(), []);

    const delay = useCallback(() =>
        new Promise<void>((resolve) => {
            timerRef.current = setTimeout(() => {
                setData({ ...IoT.timeOutPanel(device) });
                resolve();
            }, 30 * 1000);
        }), []);

    const renderPreview = () => {
        const svgMetadata = Object.entries(data).filter(([, value]) => value.svg);
        return (
            <div
                className='d-flex flex-wrap gap-1 w-100 justify-content-center align-items-center position-relative'
                onClick={() => setExpand(!expand)}
                style={{ cursor: 'pointer' }} // Added cursor style
            >
                {svgMetadata.map(([k, v]) => (
                    v.visible && v.svg && v.svg in SVG_Panel.Icon &&
                    <div key={k} className="d-flex align-items-center">
                        <SvgIcon height={30} width={30} element={SVG_Panel.Icon[v.svg]} color={v.color || undefined} /> &nbsp;
                        <p className='fw-bolder m-0 ms-2'>{v.value} {v.unit || ""}</p> &nbsp;
                    </div>
                ))}
                <Button variant="link" size="sm" style={{ border: 'none', boxShadow: 'none', position: 'absolute', right: 0 }}>
                    {expand ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
                </Button>
            </div>
        );
    };

    const renderPanel = () => (
        Object.entries(data).map(([k, v]) => (
            v.visible && <tr key={k}>
                <td>{v.name}</td>
                <td>
                    {v.label ? v.label[v.value] && <h4><Badge bg={`${v.label[v.value].bg || 'secondary'}`}> {v.label[v.value].value} {v.unit || ""}</Badge></h4> : ` ${v.value} ${v.unit || ""}`}
                </td>
            </tr>
        ))
    );

    const renderStatus = () => {
        const Last = data["LastSeen"];
        const offline = data["Offline"];
        const date = new Date(Last.value);
        const online = device.IO ? !offline : !HelperTime.isOlderThanNMinutes(date.getTime(), 120);

        return (
            <div className="d-flex justify-content-center mt-2">
                <SvgIcon height={20} width={20} element={SVG_Panel.Icon[device.IO && !Last.io && !offline ? 'Sync' : online ? 'Online' : 'Offline']} />
                &nbsp;
                <cite className='text-muted small m-0 ms-0'>
                    {!data["LastSeen"].io ?
                        <span className={!online ? 'text-danger' : device.IO && !Last.io ? 'text-warning' : ''}>
                            {!online && "Offline "} {device.IO && !Last.io && !offline && 'Syncing '} Last seen <TimeAgo date={date} />
                        </span>
                        : "Live Data"}
                </cite>
            </div>
        );
    };

    return (
        <div>
            {device && (
                <>
                    {Object.values(data).length === 0 ? (
                        <div className="d-grid gap-2 w-100 justify-content-center">
                            <h5 className="text-danger">Fetching Data...</h5>
                        </div>
                    ) : (
                        <>
                            <Card className="mb-4" style={{ border: '1px solid #555', borderRadius: '8px' }}>
                                <Card.Body>
                                    {renderPreview()}
                                    {expand && (
                                        <>
                                            <hr />
                                            <Table hover responsive="lg" className="mb-0 mt-3">
                                                <tbody>
                                                    {renderPanel()}
                                                </tbody>
                                            </Table>
                                        </>
                                    )}
                                </Card.Body>
                            </Card>
                            {renderStatus()}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default DevicePanel;
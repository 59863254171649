import React, { useEffect, useState } from 'react';
import MarkerModal from './modal';
import { ApiInterfaces } from '../../Interfaces';
import { MapComponent, MarkerProps } from './googlemaps';



interface MapContainerProps {
    devices?: ApiInterfaces.Device[]
    height: `${number}px` | `${number}%` | `${number}vh`,
    width: `${number}px` | `${number}%`,
    center: { lat: number, lng: number }
    cameras?: ApiInterfaces.VpnCamera[]
    nodes?: ApiInterfaces.LoraNode[]
}

interface MarkerData {
    title: string;
    data: ApiInterfaces.Device | ApiInterfaces.VpnCamera | ApiInterfaces.LoraNode

}

function DeviceMapContainer(props: MapContainerProps) {


    const [showModal, setShowModal] = useState(false);
    const [markerData, setMarkerData] = useState<MarkerData | undefined>(undefined);
    const { center, height, width, nodes, cameras, devices } = props

    const handleMarkerClick = (event: any, markerData: MarkerData) => {
        setMarkerData(markerData);
        setShowModal(true);

    };

    const handleCloseModal = () => {
        setShowModal(false);
        setMarkerData(undefined)

    };

    function markers(devices: ApiInterfaces.Device[] | ApiInterfaces.VpnCamera[] | ApiInterfaces.LoraNode[] | undefined) {
        const m: MarkerProps[] = []

        devices && devices.forEach(v => {
            const name = ('streamName' in v) ? `${v.streamName} Live Feed` : v.name;
            typeof v.lat === 'number' && typeof v.lng === 'number' && m.push({
                position: { lat: v.lat, lng: v.lng },
                title: name,
                icon: {
                    url: `markers/${v.class.toLocaleLowerCase()}.png`,
                    scaledSize: new google.maps.Size(55, 50), // scaled size
                    origin: new google.maps.Point(0, 0), // origin
                    anchor: new google.maps.Point(0, 0) // anchor
                },
                // `markers/${v.class.toLocaleLowerCase()}.png`,
                onClick(event) {
                    handleMarkerClick(event, { title: name, data: v });
                },
            })
        });

        return m
    }


    return (
        <div style={{ height, width }}>
            <MapComponent 
            markers={[...markers(devices), ...markers(cameras), ...markers(nodes)]}
            height={height} 
            width={width} 
            zoom={13} 
            center={center} />
            <MarkerModal show={showModal} handleClose={handleCloseModal} markerData={markerData} key={markerData?.data.id || ""} />
        </div>
    );
}


export default DeviceMapContainer



import React from 'react';
import { Table } from 'react-bootstrap';
import { ApiInterfaces } from "../../Interfaces";
import { HelperFunctions } from "../../Helper/functions"; // Assuming you're using react-bootstrap

interface SummarySectionProps {
    section: ApiInterfaces.Summary;
    devices: ApiInterfaces.Device[];
    opMap: {
        [deviceId: string]: {
            [key: string]: {
                [operation: string]: number
            }
        }
    };
}

const SummarySection: React.FC<SummarySectionProps> = ({ devices, opMap, section }) => {
    return (
        <div>
            <Table hover bordered responsive>
                <thead>
                    <tr>
                        <th className="text-center text-info">Device Name</th>
                        <th className="text-center text-info">Data Type</th>
                        <th className="text-center text-info">Operation</th>
                        <th className="text-center text-info">Value</th>
                    </tr>
                </thead>
                <tbody>
                    {section.rows.map((row: ApiInterfaces.SummaryRow, rowIndex) => {
                        const device = HelperFunctions.findDevById(devices, row.deviceId);
                        const { data, operation, deviceId } = row;
                        return (
                            <tr key={rowIndex}>
                                <td className="text-center">{device?.name}</td>
                                <td className="text-center">{device?.metadata[data]?.name}</td>
                                <td className="text-center text-info">{operation}</td>
                                <td className="text-center">
                                    {opMap[deviceId]?.[data]?.[operation] != null ? opMap[deviceId][data][operation] : 'N/A'} {device?.metadata[data]?.unit}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default SummarySection;
import React, { useEffect, useRef, useState } from 'react';
import { IRedux } from '../../Interfaces/redux';
import { connect } from 'react-redux';
import { ApiInterfaces } from '../../Interfaces';
import { Subscription } from 'rx-subject';
import { AGMAC_Timers } from '../../Helper/Timers';
import { store } from '../../store';
import { showError, showInfo } from '../../redux/actions/alertsAction';
import { Chirpstack } from '../../Interfaces/Lora';
import { LoraDecodedHelper, LoraHelper } from '../../Helper/LoraHelper';
import { Accordion, Badge, Button, Table } from 'react-bootstrap';
import TimeSelect from '../Utils/TimerSelector';
import { GetMqtt } from '../../Server/Api/MqttManager';

type IProps = {
  node: ApiInterfaces.LoraNode;
  showTrend?: (show: boolean, device: ApiInterfaces.Device) => void;
  expanded?: boolean
};

interface metadata {
  [key: string]: {
    name: string;
    unit: string;
    value: string;
  };
}

interface LoraDecodedHelperIndexSignature {
  [key: string]: (...args: any[]) => void;
}

type Props = IProps & IRedux.IAuth;

const AgmacTimers = (props: Props) => {
  const [node, setNode] = useState<ApiInterfaces.LoraNode | undefined>(undefined);
  // const instance = MqttManager.getInstance(props.mqttToken || '').getMqttService();
  const [panel, setPanel] = useState<metadata>({});
  const [duration, setDuration] = useState<number | undefined>(undefined)
  const [expand, setExpand] = useState<boolean>(props.expanded ? true : false);
  const [clicked, setClicked] = useState<boolean>(false)

  const panelRef = useRef<metadata>({});


  const isOffline = Object.values(panelRef.current).length === 0
  const getStatus = async () => {
    setClicked(true)
    await AGMAC_Timers.getTimersStatus(props.node);
  }

  useEffect(() => {



    // getStatus()
    const stream: Subscription | undefined = GetMqtt().getMessage(`${props.node.companyId}/${props.node.DEVEUI}/Live/`).subscribe((message) => updatePanelData(props.node, JSON.parse(message)));
    setNode(props.node);
    setPanel(props.node.metadata as metadata);

    return () => {
      stream?.unsubscribe();
    };
  }, []);

  const updatePanelData = (dev: ApiInterfaces.LoraNode, message: Chirpstack.payload, date?: Date) => {

    const decodedMessage = LoraHelper.decodeBase64(message.data)
    const decoder = (LoraDecodedHelper as Record<string, (...args: any[]) => void>)[dev.class];
    // (decodedMessage)

    if (typeof decoder === 'function') {
      const data = decoder(decodedMessage) as unknown as metadata

      setPanel(prevPanel => {
        const updatePanel = { ...prevPanel, ...data } as metadata;
        panelRef.current = updatePanel;
        return updatePanel;
      });
      setNode(dev);


    }
  };

  const handleTimeSelect = (hour: string, minute: string) => {

    const minutes = Number(hour) * 60 + Number(minute)
    setDuration(minutes)


  };

  function renderPanel() {
    return Object.entries(panelRef.current).map(([k, v]) => {
      return (
        <tr key={k}>
          <td>
            {v.name}
          </td>
          <td>
            {v.value} {v.unit}
          </td>
        </tr>
      );
    });
  }


  return (
    <div>
      {node && (
        <Accordion activeKey={expand ? "0" : undefined}>
          <Accordion.Item eventKey="0">
            <Accordion.Header onClick={() => (setExpand(!expand))}> <b>{node.name} &nbsp; - &nbsp; <Badge bg={isOffline ? 'warning' : 'success'}> {clicked ?  isOffline ? "Waiting Reply" : "Online" : "Waiting to sync"} </Badge></b>
              &nbsp;&nbsp;&nbsp;
              <Button className='border-0' size='sm' variant={`outline-info`} onClick={() => {

                getStatus()
                store.dispatch(showInfo('Trying to resync device'))
              }}>
                <i className="fas fa-sync-alt fa-1x"></i>
              </Button>
            </Accordion.Header>
            <Accordion.Body>
              <Table hover responsive="lg">
                <tbody>
                  {clicked ?
                    Object.values(panelRef.current).length === 0 ? (
                      <tr>
                        <td colSpan={2} className="text-center">
                          <div className="d-grid gap-2">
                            <h5 className="text-danger">Fetching Live Data, please wait....</h5>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {renderPanel()}
                        <tr>
                          <td>
                            <Button size='sm' variant='outline-danger' onClick={() => AGMAC_Timers.CommandTimer(node, 'close')}><i className="fas fa-times"></i>  Close Gate</Button>
                          </td>
                          <td>
                            <Button size='sm' variant='outline-success' onClick={() => AGMAC_Timers.CommandTimer(node, 'open')}><i className="fas fa-play-circle"></i>  Open Gate</Button>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <TimeSelect onTimeSelect={handleTimeSelect} />
                          </td>
                          <td>
                            <Button size='sm' variant='outline-info' onClick={() => {
                              if (duration) {
                                AGMAC_Timers.CommandTimer(node, 'timed', duration)
                              }
                            }} ><i className="fas fa-hourglass-start"></i> Time Open</Button>
                          </td>
                        </tr>

                      </>


                    )
                    :
                    <tr>
                      <td colSpan={2} className="text-center">
                        <div className="d-grid gap-2">
                          <h5 className="text-danger">Please sync device before operate</h5>
                        </div>
                      </td>
                    </tr>

                  }

                </tbody>
              </Table>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </div>
  );
};


const mapStateToProps = (state: IRedux.MapState) => {
  const { user, isLoggedIn, accessToken, refreshToken, mqttToken } = state.auth;
  return {
    user,
    isLoggedIn,
    accessToken,
    refreshToken,
    mqttToken
  };
};

const connector = connect(mapStateToProps);

export default connector(AgmacTimers);
import React, { useState } from 'react';
import { Panel_Metadata } from './SystemPanel';
import { Button, Form } from 'react-bootstrap';
import { store } from '../../store';
import { showError } from '../../redux/actions/alertsAction';


export namespace PanelExtraComponents {

    type Extra = Panel_Metadata & {
        lock: boolean,
        ExtraInputCallback: (show: boolean, topic: string, command: number, confirmMessage: string) => void
    }


    function isNumberWithinRange(value: number | string, min: number, max: number) {
        if (typeof value !== 'number' || isNaN(value)) {
            return false;
        }
        return value >= min && value <= max;
    }



    export const Panel_Input: React.FC<Extra> = (props) => {
        const [inputValue, setInputValue] = useState<number | ''>(''); // Use state to manage the input value
        const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = parseFloat(event.target.value);

            const clampedValue = Math.min(Math.max(value, 0 || -Infinity), props.validation?.max || Infinity);
            setInputValue(clampedValue);
        };

        return (
            <div className='d-flex  gap-4 w-100 justify-content-start'>

                <Form.Control
                    type="number"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder=""
                    className="w-50 text-success"
                    min={props.validation?.min}
                    max={props.validation?.max}

                />

                {
                    props.inputs && props.inputs.map((x, keys) => (
                        <Button key={keys} variant={`outline-${x.bttClass}`} active={!!x.cssActive}
                                className={`w-50 ${props.lock ? 'disabled-button' : ''}`}
                                onClick={() => {
                                    if (props.validation && (isNumberWithinRange(inputValue, props.validation.min, props.validation.max))) {

                                        const plcInput = (Number(inputValue) * 256) + Number(x.dataMqtt)
                                        props.ExtraInputCallback(true, x.commandTopic, plcInput, x.customMessage ? `${x.customMessage} ${inputValue}${props.validation?.unit || ""}` : "Please confirm input")


                                    } else {
                                        store.dispatch(showError(`Input is outside range [${props.validation?.min} , ${props.validation?.max}]${props.validation?.unit || ""}`))
                                    }

                                }}>
                            <i className={`fas ${x.iconClass} fa-fw fa-2x`}></i> <p className={"mb-0"}>{x.inputName}</p>
                        </Button>
                    ))
                }
            </div>
        );
    };


}
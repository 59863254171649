import { Form, Row as FormRow } from 'react-bootstrap';
import { Row as RowProps } from './Interface';
import { Base, Props as BaseProps, State as BaseState } from './Base';

export interface Props extends BaseProps, RowProps {
  children?: React.ReactNode

}

export interface State extends BaseState {

}

export class Row extends Base<Props, State> {

  public constructor(props: Props) {
    super(props, {});
  }
  public render() {
    this.bindState()
    return <FormRow key={this.props.key} style={this.shouldRender() ? undefined : { display: 'none' }}>
      {this.props.children}
      {this.state.valid ? undefined : <Form.Control.Feedback className="section-invalid" key='negative-feedback' type="invalid">{this.state.validationMessage || '* invlaid'}</Form.Control.Feedback>}
    </FormRow>;
  }
}
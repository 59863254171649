import React, {useEffect, useState, useRef} from 'react';
import {Button, Table} from 'react-bootstrap';
import {connect} from 'react-redux';
import {ApiInterfaces} from '../../Interfaces';
import {IRedux} from '../../Interfaces/redux';
import {GetMqtt} from '../../Server/Api/MqttManager';
import ConfirmModal from '../Utils/ConfirmModal';
import {PanelExtraComponents} from './PanelComponents';
import {store} from '../../store';
import {showSuccess} from '../../redux/actions/alertsAction';
import {ReduxActions} from "../../redux/actions";

type IProps = {
    device: ApiInterfaces.Device;
    lock: boolean
};


export interface Panel_Metadata {
    name: string
    id: string
    system: string
    type: 'button' | 'status' | 'input'
    validation?: Validation
    inputs?: Input[]
    unit?: string
    statusValue?: string
    processing?: boolean
}

interface Input {
    commandTopic: string
    dataMqtt: number | string
    bttClass: string
    iconClass: string
    faSize?: string
    inputName: string
    feedback: 'feedback' | 'remote' | 'enable' | 'mode'
    active: number
    customMessage?: string
    deactive?: string
    confirm?: boolean
    cssActive?: boolean
    skip?: boolean
}

interface Feedback {
    feedback: number
    remote: number
    enable: number
    mode: number
    Type: string
}

interface Validation {
    min: number
    max: number
    conversion: string
    unit?: string
}


type Props = IProps & IRedux.IAuth;

const SystemPanel = (props: Props) => {
    const [panel, setPanel] = useState<Panel_Metadata[]>([]);
    const panelRef = useRef(panel);
    const [device, setDevice] = useState<ApiInterfaces.Device>();
    const [show, setShow] = useState(false);
    const [command, setCommand] = useState<string | undefined>(undefined)
    const [topic, setTopic] = useState<string | undefined>(undefined)
    const [confirmMessage, setConfirmMessage] = useState<string>('')
    // const instance = MqttManager.getInstance(props.mqttToken || '').getMqttService()


    useEffect(() => {

        const instance = GetMqtt()

        setDevice(props.device);
        setPanel(props.device.panel_metadata as Panel_Metadata[]);
        GetMqtt().sendMessage(`${props.device.companyId}/clientConnected/`, '1')

        const feedbackStream = instance.getMessage(`${props.device.companyId}/${props.device.id}/Feedback/`);
        const liveStream = instance.getMessage(`${props.device.companyId}/${props.device.id}/Live/`);


        const feedbackSubscription = feedbackStream.subscribe((feedbackMessage) => {
            processFeedback(feedbackMessage, undefined);
        });

        const liveSubscription = liveStream.subscribe((liveMessage) => {
            processFeedback(undefined, liveMessage);
        });

        // Cleanup subscriptions
        return () => {
            feedbackSubscription.unsubscribe();
            liveSubscription.unsubscribe();
        };
    }, [props.mqttToken, props.device]);

    const processFeedback = (feedbackMessage: string | undefined, liveStreamMessage: string | undefined) => {
        const updatePanel = props.device.panel_metadata as Panel_Metadata[];
        const feedbackData = feedbackMessage ? JSON.parse(feedbackMessage) as Feedback : undefined;
        const liveData = liveStreamMessage ? JSON.parse(liveStreamMessage) as any : undefined;

        updatePanel.forEach((x) => {
            if (x.type === 'button') {
                x.inputs?.forEach((el) => {
                    if (feedbackData && feedbackData.hasOwnProperty(el.feedback) && x.system === feedbackData.Type) {
                        x.processing = feedbackData.feedback === 2;
                        el.cssActive = feedbackData[el.feedback] === el.active;
                    }
                });
            } else if (x.type === 'status' && liveData && liveData.hasOwnProperty(x.id)) {
                x.statusValue = liveData[x.id];
            }
        });

        panelRef.current = updatePanel;
        setPanel([...updatePanel]);
    };


    function renderPanel() {
        return Object.entries(panelRef.current).map(([k, v]) =>
            (
                <tr key={k}>
                    <td className="align-middle d-none d-sm-table-cell" style={{maxWidth: '80px'}}>
                        {v.name}
                    </td>
                    <td colSpan={1}>
                        {v.processing ? <p className='text-warning'>Processing....</p> :
                            v.type === 'button' && v.inputs &&
                            <div className='d-flex  gap-4 w-100'
                                 style={v.inputs.length === 1 ? {paddingRight: "1.5rem"} : {}}>
                                {v.inputs.map((x, keys) => (
                                    <Button key={keys} variant={`outline-${x.bttClass}`} active={x.cssActive}
                                            className={`w-50 ${props.lock && 'disabled-button'} ${typeof x.cssActive !== 'undefined' && !x.cssActive && "opacity-50"}`}
                                            onClick={() => {
                                                if (x.skip) {
                                                    GetMqtt().sendMessage(x.commandTopic, `${x.dataMqtt}`)
                                                    store.dispatch(showSuccess(`Input command sent successfully!`))

                                                } else {
                                                    setCommand(`${x.dataMqtt}`)
                                                    setTopic(`${x.commandTopic}`)
                                                    setConfirmMessage(x.customMessage || "Are you sure you want to perform this action?")
                                                    setShow(true)
                                                }
                                            }}>
                                        <i className={`fa-solid fas ${x.iconClass} fa-fw fa-2x`}></i> <p
                                        className={"mb-0"}>{x.inputName}</p>
                                    </Button>
                                ))}
                            </div>
                        }
                        {/* eslint-disable-next-line react/jsx-pascal-case */}
                        {v.type === 'input' && <PanelExtraComponents.Panel_Input {...v} lock={props.lock}
                                                                                 ExtraInputCallback={ExtraInputCallback}/>}
                        {v.type === 'status' && <p className='text-center text-warning'>{v.statusValue}</p>}
                    </td>
                </tr>
            ));
    }

    const ExtraInputCallback = (show: boolean, topic: string, command: number, confirmMessage: string) => {
        setCommand(`${command}`)
        setTopic(topic)
        setConfirmMessage(confirmMessage)
        setShow(true)


    }

    const handleConfirmAction = () => {
        // Perform the action you want when OK is clicked
        if (command && topic) {
            GetMqtt().sendMessage(topic, command)
            store.dispatch(showSuccess(`Input command sent successfully!`))
            setPanel((prevPanel) => {
                const updatedPanel = prevPanel.map((v) => {
                    if (v.inputs) {
                        // eslint-disable-next-line eqeqeq
                        const hasMatchingInput = v.inputs.some((input) => input.dataMqtt == command);
                        if (hasMatchingInput) {
                            return {
                                ...v,
                                processing: true,
                            };
                        }
                    }
                    return v;
                });
                panelRef.current = updatedPanel; // Update the panelRef with the updated panel data
                return updatedPanel;
            });
        }
        setShow(false); // Close the modal
    };

    return (
        <div>
            <ConfirmModal
                message={confirmMessage}
                action={handleConfirmAction}
                show={show}
                handleClose={() => setShow(false)}
            />
            {device && (
                <Table hover responsive="lg">
                    <tbody>

                    {panelRef.current.length === 0 ?
                        <tr>
                            <td colSpan={2} className="text-center">
                                <div className="d-grid gap-2">
                                    <h5 className="text-danger">Fetching Live Data, please wait....</h5>
                                </div>
                            </td>
                        </tr>
                        :
                        renderPanel()
                    }


                    </tbody>
                </Table>
            )}
        </div>
    );
};

export default ReduxActions.connector(SystemPanel)
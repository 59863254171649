import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoute = ({
    isAllowed,
    children,
}) => {

    // const searchParams = new URLSearchParams(window.location.search);
    if (!isAllowed) {
        return <Navigate to={{pathname: '/login'/*, search: searchParams.toString() */ }} replace />;
    }

    return children ? children : <Outlet />;
};


export default ProtectedRoute
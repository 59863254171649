import React, { useEffect, useState } from 'react'
import { Card, Col, Tab, Table, Tabs } from 'react-bootstrap'
import { connect, useSelector } from 'react-redux'
import { ApiInterfaces } from '../../Interfaces'
import { IRedux } from '../../Interfaces/redux'
import { Panel, PanelBody } from '../panel/panel'
import { IoT } from '../../Helper/IoT'
import LiquidFillGauge from '../Utils/WaterFill'
import { HelperTime } from '../../Helper/Time'
import Chart from '../Graphs/Charts'
import { StatusTable } from "./StatusTable";
import StorageCurveGraph from "./StorageCurveGraph";
import DailyChangeGraph from "./DailyChangeGraph";
import WaterTake from "./WaterTake";
import StorageCurveTable from "./StorageCurveTable";
import { BindingHooks } from "../../Server/Binding/bidings/BindingHook";
import { ReduxActions } from "../../redux/actions";
import { Subscription } from "rxjs";
import { GetMqtt } from "../../Server/Api/MqttManager";
import DeviceIndex from "../Devices";


export type nswStorage = {
    payload: {
        area: number
        battery_voltage: number
        distanceToWaterLevel: number
        maxVolume: number
        sensor_mAHD: number
        signalStrength: number
        storageCapacity: number
        volume: number
        waterLevel_AHD: number
    }

    timestamp_each: {
        area: Date
        battery_voltage: Date
        distanceToWaterLevel: Date
        maxVolume: Date
        sensor_mAHD: Date
        signalStrength: Date
        storageCapacity: Date
        volume: Date
        waterLevel_AHD: Date
    }
    timestamp: Date
}
const LevelStorage: React.FC<{ device: ApiInterfaces.Device }> = ({ device }) => {

    // const [load, setLoad] = useState<nswStorage | undefined>(undefined);
    const { mqttToken, user } = useSelector((state: IRedux.MapState) => state.auth)
    const { data, setData } = BindingHooks.IOTLastValue(device, `live-${device.id}`);
    const binds = BindingHooks.StorageDevice(user.companyId, 'StoragePanel')


    const customMetadata = [
        {
            "key": "Volume(ML)",
            "color": "#4a5dc6",
            "values": [],
            "disabled": false,
            "context": "volume"
        },
        {
            "key": "Water Level(AHD)",
            "color": "#C0C0C0",
            "values": [],
            "disabled": false,
            "type": "line",
            "context": "waterLevel_AHD"
        }
    ]


    const handleMessage = (message: string) => {
        setData((prevData) => {
            try {
                const payload = { ...prevData?.payload, ...JSON.parse(message) };
                const timestamp_each = { ...prevData?.timestamp_each, ...IoT.timestampEachStorageData(JSON.parse(message)) }
                return {
                    deviceId: device.id,
                    id: "",
                    timestamp: new Date(),
                    payload,
                    timestamp_each
                };
            } catch (error) {
                return prevData; // Return the previous state unchanged in case of error
            }
        });
    };


    useEffect(() => {
        const stream: Subscription = GetMqtt().getMessage(`${device.companyId}/${device.id}/Live/`).subscribe((message) => handleMessage(message));
        return () => {
            stream.unsubscribe();
        };
    }, [mqttToken]);

    return (

        <>
            <Col xs={12} md={12} lg={4} xl={4} xxxl={3}>
                <Card className="mb-4 mapHeight" style={{ border: '1px solid #555', borderRadius: '8px' }}>
                    <Card.Header className="text-center" style={{ backgroundColor: '#000', color: '#fff', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                        <h5 className="mb-1" style={{ fontSize: '1.0rem' }}><strong>{device.name}</strong> Storage Capacity</h5>
                    </Card.Header>
                    <Card.Body className="text-center">
                        <div className="d-flex justify-content-center p-4">
                            {data?.payload && <LiquidFillGauge percentage={Number(data.payload.storageCapacity || 0)} />}
                        </div>
                        <div className="text-center mb-3 text-muted text-sm">
                            <hr />
                            {HelperTime.DateBeautify(data?.timestamp_each?.storageCapacity)}
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            {binds.data && <DeviceIndex devices={binds.data} key={'storage'} />}

            <WaterTake key={device.id} device={device} />
            <Col xs={12} md={12} lg={5} xl={5} xxxl={3}>
                <StorageCurveTable id={device.id} name={device.name} />
            </Col>
            
            <Col xs={12}>
                <StorageCurveGraph device={device} />
            </Col>

            <Col xs={12}>
                <Card className="mb-4" style={{ border: '1px solid #555', borderRadius: '8px' }}>
                    <Card.Header className="text-center" style={{ backgroundColor: '#000', color: '#fff', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                        <h5 className="mb-1" style={{ fontSize: '1.0rem' }}><strong>{device.name}</strong> Volume and Height</h5>
                    </Card.Header>
                    <Card.Body className="text-center">
                        <Chart device={device} key={device.id} type={'column'} shared={true}
                            customMetadata={customMetadata} />
                    </Card.Body>
                </Card>
            </Col>
            <Col xs={12}>
                <DailyChangeGraph device={device} />
            </Col>
        </>
    );
};
export default LevelStorage






import React, { useState } from "react";
import {Link, useNavigate} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { validateLogin } from '../../Helper/FormValidator';
import axios from "axios";
import {LOGIN_FAIL, LOGIN_SUCCESS} from "../../redux/actions/actionInterface";
import {showError} from "../../redux/actions/alertsAction";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        username: "",
        password: "",
        loading: false,
        SubmitText: "Sign Me In"
    });

    const { username, password, loading, SubmitText } = formData;

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            if (validateLogin(username, password)) {
                setFormData({ ...formData, SubmitText: "Syncing, please wait..." });
                const res = await axios.post(`${process.env.REACT_APP_API}` + '/user.login', { email: username.toLowerCase(), password: password });
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.data,
                });

                // navigate(0);

            }
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            dispatch(showError(message + " - Credentials not found"))

            dispatch({
                type: LOGIN_FAIL,
            });
            setFormData({ ...formData, SubmitText: "Sign Me In" });
        }
    };

    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    return (
        <>
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100vw', // Viewport width
                height: '100vh', // Viewport height
                backgroundColor: '#212529', // Example background color
            }}>
            </div>
            <div className="login login-with-news-feed h-100">
                <div className="news-feed">
                    <div className="news-image" style={{backgroundImage: 'url(/images/SierraNet.jpg)'}}></div>
                    <div className="news-caption">
                        <h4 className="caption-title"><b>SierraTek</b> Automation and Control</h4>
                        <p>
                            The SierraTek team offers a combined 130 years of expertise in the Automation and
                            Control sector across various industries.
                        </p>
                    </div>
                </div>
                <div className="login-container">
                    <div className="login-header mb-30px">
                        <div className="brand">
                            <div className="d-flex align-items-center">
                                <img src="/images/SierraNetLogo.png" alt="" width="354" height="140"/>
                            </div>
                        </div>
                    </div>

                    <div className="login-content">
                        <form onSubmit={handleSubmit} className="fs-13px">
                            <div className="form-floating mb-15px">
                                <input type="text" className="form-control h-45px fs-13px"
                                       placeholder="User Name" name="username"
                                       value={username} onChange={handleChange}/>
                                <label htmlFor="username"
                                       className="d-flex align-items-center fs-13px text-gray-600">Username</label>
                            </div>
                            <div className="form-floating mb-15px">
                                <input type="password" className="form-control h-45px fs-13px"
                                       placeholder="Password" name="password"
                                       value={password} onChange={handleChange}/>
                                <label htmlFor="password"
                                       className="d-flex align-items-center fs-13px text-gray-600">Password</label>
                            </div>
                            <div className="mb-40px pb-40px text-inverse">
                                <Link to="/resetpassword" className="text-white">Forgot Password?</Link>
                            </div>
                            <div className="mb-15px">
                                <button type="submit" disabled={SubmitText !== "Sign Me In"}
                                        className="btn btn-success d-block h-45px w-100 btn-lg fs-14px">{SubmitText}</button>
                            </div>
                            <hr className="bg-gray-600 opacity-2"/>
                            <div className="text-white-600 text-center text-white-500-darker mb-0">
                                &copy; SierraTek Global Pty Ltd All Right Reserved 2023
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;

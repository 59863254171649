import React, {useState, useEffect} from "react";
import {InputGroup, FormControl, Button} from "react-bootstrap";
import {CSVLink} from "react-csv";
import {HelperTime} from "../../Helper/Time";
import newPDF, {PDF_type} from "./PDF/Generic";

interface IProps<T> {
    table: T[];
    name: PDF_type;
    updateList: (table: T[]) => void;
}

const TableHelper = <T extends {}>({table, name, updateList}: IProps<T>) => {
    const [csv, setCsv] = useState([]);
    const [filter, setFilter] = useState<T[]>([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        setCsv(table as any);
        setFilter(table);
    }, [table]);

    const objectsToCSV = () => {
        const arr = csv;
        if (arr.length > 0) {
            const array = [Object.keys(arr[0])].concat(arr);
            return array
                .map((r: any) =>
                    Object.values(r)
                        .map((v: any) => (typeof v === 'string' ? JSON.stringify(v) : v))
                        .toString()
                )
                .join('\n');
        }
        return "";
    };

    const filterByValue = (array: any[], searchString: string) => {
        return array.filter((o) =>
            Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(searchString))
        );
    };

    const exportPDF = async () => await newPDF(filter, name, search.length > 0 ? search : undefined)

    const onSearch = (value: string) => {
        let newList: T[];
        if (value !== "") {
            const filtered = value.toLowerCase();
            newList = filterByValue(table, filtered);
        } else {
            newList = table;
        }
        setCsv(newList as any);
        setFilter(newList);
        setSearch(value);
        updateList(newList);
    };

    const csvReport = {
        data: objectsToCSV(),
        filename: `${name}-${HelperTime.DateBeautify(new Date())}.csv`
    };

    return (
        <>
            <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                    <i className="fas fa-search"></i>
                </InputGroup.Text>
                <FormControl
                    placeholder="Search..."
                    aria-label="Search..."
                    aria-describedby="basic-addon1"
                    value={search}
                    onChange={(e) => onSearch(e.target.value)}
                />
                <Button variant="outline-info" onClick={exportPDF}>
                    <i className="far fa-file-pdf"></i>
                </Button>
                <Button variant="outline-primary">
                    <CSVLink {...csvReport}>
                        <i className="fas fa-save"></i>
                    </CSVLink>
                </Button>
            </InputGroup>
        </>
    );
};

export default TableHelper;
import React, { } from 'react';
import { Form } from 'react-bootstrap';
import { Collection as CollectionProps } from './Interface';
import { Base, Props as BaseProps, State as BaseState } from './Base';

export interface Props<T> extends BaseProps, CollectionProps<T> {
  children?: React.ReactNode
}

export interface State extends BaseState {

}

export class Collection<T> extends Base<Props<T>, State> {

  public constructor(props: Props<T>) {
    super(props, {});
  }
  public render() {
    this.bindState()
    return <div key={this.props.key} style={this.shouldRender() ? undefined : { display: 'none' }} >
      {this.props.children}
    </div>;
  }
}
import { Accordion, Button, Table } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { ApiInterfaces } from '../../Interfaces';
import { IRedux } from '../../Interfaces/redux';
import React, { useEffect, useState } from "react";
import { BindingSet } from "../../Server/Binding/Binding";
import { Bindings } from "../../Server/Binding/bidings";
import { HelperTime } from "../../Helper/Time";
import DateBeautify = HelperTime.DateBeautify;
import WaterTakeModal from "../DAS/modal";
import { ApiHelper } from '../../Server/Events/ApiOperations';
import ConfirmModal from '../Utils/ConfirmModal';


type IProps = {
    device: ApiInterfaces.Device;
    reports?: ApiInterfaces.Report[];
    expanded?: boolean;
    key: string;
};


type Props = IProps;
const bindings: BindingSet = new BindingSet({});

const ReportsPanel = (props: Props) => {
    const { device } = props
    const [expand, setExpand] = useState<boolean>(!!props.expanded);
    const [show, setShow] = useState<boolean>(false)
    const [reports, setReports] = useState<ApiInterfaces.Report[] | undefined>(undefined)
    const [view, setView] = useState<ApiInterfaces.Report | undefined>(undefined)
    const { accessToken, user } = useSelector((state: IRedux.MapState) => state.auth);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [reportToDelete, setReportToDelete] = useState<ApiInterfaces.Report | undefined>(undefined);


    useEffect(() => {
        bindings.bind()
        props.reports ? setReports(props.reports) : CreateBinding()
        return () => bindings.unbind()
    }, [props.reports]);

    const handleDeleteReport = async () => {
        if (reportToDelete) {
            await ApiHelper.op('report.delete', { id: reportToDelete.id, deviceId: device.id }, user.companyId);
            setShowDeleteModal(false);
            setReportToDelete(undefined);
        }
    };

    function CreateBinding() {
        const bind = (new Bindings.Report.Binding(accessToken, props.device.id, user.companyId, data => setReports(Object.values(data).sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()))))
        bindings.add('report', bind);
    }

    const handleClose = () => setShow(false);
    const OpenModal = (r: ApiInterfaces.Report) => {
        setShow(true)
        setView(r)
    }


    const confirmDelete = (report: ApiInterfaces.Report) => {
        setReportToDelete(report);
        setShowDeleteModal(true);
    };


    const renderPreview = () => {
        return (
            <div className='d-flex flex-wrap gap-1 w-100 justify-content-center'>
                <p className="text-bg-dark">
                    <i className="far fa-file-alt"></i> &nbsp; {expand ? 'Hide' : 'Expand'} Reports List
                </p>

            </div>
        );
    };

    return (
        reports ? <>
            <WaterTakeModal show={show} handleClose={handleClose} device={device} report={view} key={view?.id} />
            <Accordion activeKey={expand ? "0" : undefined}>
                <Accordion.Item eventKey="0">
                    <Accordion.Header onClick={() => (setExpand(!expand))}>
                        {renderPreview()}
                    </Accordion.Header>
                    <Accordion.Body>
                        <Table hover bordered={true} responsive="md">
                            <tbody>
                                <tr>
                                    {/*<td className={"text-center"}> Report Title</td>*/}
                                    <td className={"text-center"}> Created at</td>
                                    <td className={"text-center"}> Finished</td>
                                    <td className={"text-center"}> View</td>
                                    <td className={"text-center"}> Delete?</td>
                                </tr>
                                {reports.map(v => (
                                    <tr key={v.created_at.toString()}>
                                        {/*<td>{v.title}</td>*/}
                                        <td>{DateBeautify(v.created_at)}</td>
                                        <td>{DateBeautify(v.finished)}</td>
                                        <td>{v.finished && new Date(v.finished).getTime() <= new Date().getTime() ?
                                            <Button variant={'outline-success'} onClick={() => OpenModal(v)}><i
                                                className="far fa-file-pdf"></i></Button> :
                                            <Button onClick={() => OpenModal(v)} variant={'outline-white'}><i
                                                className="far fa-clock"></i></Button>
                                        }</td>
                                        <td>
                                        <Button variant={'outline-danger'} onClick={() => confirmDelete(v)}>
                                            <i className="far fa-trash-alt"></i>
                                        </Button>
                                        </td>
                                       
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <ConfirmModal
                    message="Are you sure you want to delete this report?"
                    action={handleDeleteReport}
                    show={showDeleteModal}
                    handleClose={() => {
                        setShowDeleteModal(false);
                        setReportToDelete(undefined);
                    }}
                />
        </> :
            <div className="d-grid gap-2 w-100 justify-content-center">
                <h5 className="text-danger">Fetching Reports, please wait....</h5>
            </div>
    )
};

export default ReportsPanel
import { ApiInterfaces } from "../Interfaces";

export namespace Calculator {
    const parseToNumber = (value: number | string): number => {
        return typeof value === "string" ? parseFloat(value) : value;
    };

    export const roundUpToTwoDecimalPlaces = (value: number | string): number => {
        const num = parseToNumber(value);
        return Math.round(num * 100) / 100;
    };

    export const calculateAverage = (numbers: (number | string)[]): number => {
        if (numbers.length === 0) return 0;
        const sum = numbers.reduce((a: number, b) => a + parseToNumber(b), 0);
        return roundUpToTwoDecimalPlaces(sum / numbers.length);
    };

    export const calculateSum = (numbers: (number | string)[]): number => {
        return roundUpToTwoDecimalPlaces(numbers.reduce((a: number, b) => a + parseToNumber(b), 0));
    };

    export const calculateMax = (numbers: (number | string)[]): number => {
        if (numbers.length === 0) return 0;
        return roundUpToTwoDecimalPlaces(Math.max(...numbers.map(parseToNumber)));
    };

    export const calculateMin = (numbers: (number | string)[]): number => {
        if (numbers.length === 0) return 0;
        return roundUpToTwoDecimalPlaces(Math.min(...numbers.map(parseToNumber)));
    };

    export const calculateStdDeviation = (numbers: (number | string)[]): number => {
        if (numbers.length === 0) return 0;
        const mean = numbers.reduce((a: number, b) => a + parseToNumber(b), 0) / numbers.length;
        const variance = numbers.reduce((a: number, b) => a + Math.pow(parseToNumber(b) - mean, 2), 0) / numbers.length;
        return roundUpToTwoDecimalPlaces(Math.sqrt(variance));
    };

    export const calculateCount = (numbers: (number | string)[]): number => {
        return numbers.length;
    };

    export const calculateRange = (numbers: (number | string)[]): number => {
        if (numbers.length === 0) return 0;
        return roundUpToTwoDecimalPlaces(Math.max(...numbers.map(parseToNumber)) - Math.min(...numbers.map(parseToNumber)));
    };

    export const calculateMedian = (numbers: (number | string)[]): number => {
        if (numbers.length === 0) return 0;
        const sortedNumbers = numbers.map(parseToNumber).sort((a, b) => a - b);
        const mid = Math.floor(sortedNumbers.length / 2);
        return roundUpToTwoDecimalPlaces(sortedNumbers.length % 2 !== 0 ? sortedNumbers[mid] : (sortedNumbers[mid - 1] + sortedNumbers[mid]) / 2);
    };
}


export const processReportTemplate = (
    deviceDataMap: { [key: string]: ApiInterfaces.DeviceData[] },
    summaries: ApiInterfaces.Summary[]
): { [deviceId: string]: { [key: string]: { [operation: string]: number } } } => {
    const dataMap: { [deviceId: string]: { [key: string]: number[] } } = {};

    // Group data by device ID and payload key
    Object.entries(deviceDataMap).forEach(([deviceId, deviceData]) => {
        deviceData.forEach(data => {
            if (!dataMap[deviceId]) {
                dataMap[deviceId] = {};
            }
            Object.keys(data.payload).forEach(key => {
                if (!dataMap[deviceId][key]) {
                    dataMap[deviceId][key] = [];
                }
                dataMap[deviceId][key].push(data.payload[key]);
            });
        });
    });

    // Calculate operations for each summary row
    const results: { [deviceId: string]: { [key: string]: { [operation: string]: number } } } = {};

    summaries.forEach(summary => {
        summary.rows.forEach(row => {
            const deviceId = row.deviceId;
            const dataKey = row.data;
            const operation = row.operation;

            if (!results[deviceId]) {
                results[deviceId] = {};
            }
            if (!results[deviceId][dataKey]) {
                results[deviceId][dataKey] = {};
            }

            const data = dataMap[deviceId][dataKey];
            if (data) {
                switch (operation) {
                    case "average":
                        results[deviceId][dataKey][operation] = Calculator.calculateAverage(data);
                        break;
                    case "min":
                        results[deviceId][dataKey][operation] = Calculator.calculateMin(data);
                        break;
                    case "max":
                        results[deviceId][dataKey][operation] = Calculator.calculateMax(data);
                        break;
                    case "sum":
                        results[deviceId][dataKey][operation] = Calculator.calculateSum(data);
                        break;
                    case "stdDeviation":
                        results[deviceId][dataKey][operation] = Calculator.calculateStdDeviation(data);
                        break;
                    case "count":
                        results[deviceId][dataKey][operation] = Calculator.calculateCount(data);
                        break;
                    case "range":
                        results[deviceId][dataKey][operation] = Calculator.calculateRange(data);
                        break;
                    case "median":
                        results[deviceId][dataKey][operation] = Calculator.calculateMedian(data);
                        break;
                }
            }
        });
    });

    console.log(results, dataMap);

    return results;
};
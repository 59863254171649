import { Panel, PanelBody } from "../panel/panel";
import { Tab, Tabs, Button, Card } from "react-bootstrap";
import React, { useRef } from "react";
import { ApiInterfaces } from "../../Interfaces";
import { PlainChart } from "../Graphs/PlainChart";
import { IRedux } from "../../Interfaces/redux";
import { BindingHooks } from "../../Server/Binding/bidings/BindingHook";
import { GraphBuilder } from "../Graphs/Default";
import newPDF from "../Utils/PDF/Generic";
import { ReduxActions } from "../../redux/actions";

function StorageCurveGraph(props: { device: ApiInterfaces.Device } & IRedux.IAuth) {

    const { device, accessToken, user } = props
    const chartRef = useRef(null);
    const { data } = BindingHooks.LidStorage(accessToken, device.id || "", user.companyId, 'lidCurve')

    const title = () => {
        return <div className="d-flex justify-content-between align-items-center">
            <span className="mx-auto">{device.name} Storage Curve Graph</span>
            <Button variant={'outline-warning'} onClick={() => pdf()}>
                <i className="fas fa-download"></i>
            </Button>
        </div>
    }

    const pdf = async () => {
        const graph = chartRef.current
        if (!graph) return
        const svg = await GraphBuilder.getBase64(GraphBuilder.switchToLightModeAndExportSVG(graph))
        GraphBuilder.switchToBlack(graph)
        await newPDF({ ...data, name: device.name }, `StorageCurve`, undefined, `${device.name}`, svg)
    }

    return data ? <Card className="mb-4" style={{ border: '1px solid #555', borderRadius: '8px' }}>
        <Card.Header className="text-center" style={{ backgroundColor: '#000', color: '#fff', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
            <h5 className="mb-1" style={{ fontSize: '1.0rem' }}>{title()}</h5>
        </Card.Header>
        <Card.Body className="text-center">
            <PlainChart forwardedRef={chartRef} name={'Storage Curve'}
                series={GraphBuilder.StorageCurveSeries(data)}
                type={'bar'}
                plotlineY={GraphBuilder.StorageOptions(data.TopBank || 0, data.TopWaterHeight || 0)}
                noTime={true}
                labelY={"Water Elevation"} labelX={'Volume (ml)'} />
        </Card.Body>
    </Card>: <></>;
}

export default ReduxActions.connector(StorageCurveGraph)
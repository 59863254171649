import React, { Component } from 'react';
import { InputValue, OptionValue, Base as BaseProps, BaseFormAction } from './Interface';

export interface Props extends BaseProps {
  key: string,
  state: {
    valueChanged: (value: InputValue) => void,
    validChanged: (valid: boolean) => void,
    clicked: (action: any) => void,

    setValue: (cb: (value: InputValue) => void, inital: InputValue) => void,
    setValid: (cb: (valid: boolean, message?: string) => void, inital: boolean) => void,
    setDisabled: (cb: (disabled: boolean) => void) => void,
    setColor: (cb: (color: string) => void) => void,
    setLabel: (cb: (label: string) => void) => void,
    setFeedbackMessage: (cb: (feedback: string) => void) => void,
    setIsInvalid: (cb: (isInvalid: boolean) => void) => void,

    setHidden: (cb: (hidden: boolean) => void) => void,
    setOptional: (cb: (optional: boolean) => void) => void,
    setOptions: (cb: (options: { [key: string]: OptionValue }) => void) => void,
    setProcessing: (cb: (processing: boolean) => void) => void,
    isBound: () => boolean,
  },
  stateAccess: BaseFormAction,
  setBindingValue?: (value: InputValue) => void,
}

export interface State {
  value: InputValue,
  validationMessage?: string,
  valid: boolean,
  hidden: boolean,
  disabled: boolean,
  optional: boolean,
  color: string,
  label: string,
  feedback: string,
  isInvalid: boolean,
  options: { [key: string]: OptionValue },
}

export class Base<P extends Props, S extends State> extends Component<P, S> {

  public constructor(props: P, state: Omit<S, keyof State> & Partial<State>) {
    super(props);
    this.state = Object.assign({
      value: state.value,
      valid: this.valid(state.value),
      hidden: props.hidden === true,
      disabled: props.disabled === true,
      optional: props.optional === true,
      options: state.options,
      color: state.color,
      label: state.label,
      feedback: state.feedback,
      isInvalid: state.isInvalid,
    }, state) as S;
  }

  componentDidMount(): void {
    this.bindState();
  }


  protected bindState() {
    if (!this.props.state.isBound()) {
      this.props.state.setValue((value) => {
        if (this.props.setBindingValue) {
          this.props.setBindingValue(this.bindingValueTransform(value));
        }
        this.setState({ value });
      }, this.state.value);
      this.props.state.setValid((valid, message) => {
        this.setState({ valid, validationMessage: message });
      }, this.state.valid);
      this.props.state.setDisabled((disabled) => {
        this.setState({ disabled });
      });
      this.props.state.setColor((color) => {
        this.setState({ color })
      })
      this.props.state.setHidden((hidden) => {
        this.setState({ hidden });
      });
      this.props.state.setOptional((optional) => {
        this.setState({ optional });
      });
      this.props.state.setOptions((options) => {
        this.setState({ options });
      });
      this.props.state.setLabel((label) => {
        this.setState({ label });
      });
      this.props.state.setFeedbackMessage((feedback) => {
        this.setState({ feedback });
      });
      this.props.state.setIsInvalid((isInvalid) => {
        this.setState({ isInvalid });
      });
    }
  }

  protected bindingValueTransform(value: InputValue) {
    return value;
  }

  protected valid(value: InputValue) {
    return true;
  }

  protected shouldRender() {
    return this.state.hidden !== true;
  }

  public render() {
    return <></>;
  }
}
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { connect, useSelector } from 'react-redux'
import { ApiInterfaces } from '../../Interfaces'
import { IRedux } from '../../Interfaces/redux'
import ReportList from "../Reports/ReportList";
import { Calendar } from "primereact/calendar";
import { BindingSet } from "../../Server/Binding/Binding";
import { Bindings } from "../../Server/Binding/bidings";
import { HelperTime } from "../../Helper/Time";
import ConfirmModal from "../Utils/ConfirmModal";
import { ApiHelper } from "../../Server/Events/ApiOperations";
import { ReduxActions } from "../../redux/actions";
import { FaPlay, FaCalendarAlt, FaTrash, FaTimes } from 'react-icons/fa'; // Importing icons
import { Typeahead } from 'react-bootstrap-typeahead'; // Importing Typeahead

interface range {
    start: Date,
    finish: Date
}

const bindings: BindingSet = new BindingSet({});
const WaterTake: React.FC<{ device: ApiInterfaces.Device }> = ({ device }) => {
    const { accessToken, user  } = useSelector((state: IRedux.MapState) => state.auth);

    const [waterTake, setWaterTake] = useState<{ take: boolean, custom: boolean }>({ take: false, custom: false })
    const [range, setRange] = useState<range | undefined>(undefined)
    const [reports, setReports] = useState<ApiInterfaces.Report[] | undefined>(undefined)
    const [show, setShow] = useState(false);
    const [showc, setShowc] = useState(false);
    const [showDeleting, setShowDeleting] = useState(false);
    const [report, setReport] = useState<ApiInterfaces.Report | undefined>(undefined);
    const [confirmMessage, setConfirmMessage] = useState<string>('')
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [selectedReport, setSelectedReport] = useState<ApiInterfaces.Report | undefined>(undefined);

    useEffect(() => {
        bindings.bind()
        CreateBinding()
        return () => bindings.unbind()
    }, []);

    function CreateBinding() {
        const bind = (new Bindings.Report.Binding(accessToken, device.id, user.companyId, data => setReports(Object.values(data).sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()))))
        bindings.add('report', bind);
    }

    const handleCancel = () => {
        setWaterTake({ take: false, custom: false })
        setRange(undefined)
        setShowc(false)
        setShow(false)
        setDeleting(false)
        setShowDeleting(false)
        setSelectedReport(undefined)
    }

    const handleConfirmAction = async () => {
        setShow(false); // Close the modal
        if (report) {
            setLoading(true);
            const res = await ApiHelper.op("report.edit", { ...report, finished: new Date() }, user.companyId)
            setLoading(false);
            if (res && res.type === 'report.edit') setReport(undefined)
        }
    };

    const createReport = async () => {
        if (!range) return
        const report = {
            created_at: range.start,
            finished: range.finish,
            deviceId: device.id,
            template: "WaterTake",
            title: "Water Take Report"
        }

        if (HelperTime.ReportTimeConflict(report, reports || [])) {
            ReduxActions.ShowError("Time conflict, time overlaps with previous reports")
            return
        }

        setLoading(true);
        await ApiHelper.op('report.create', report, user.companyId)
        setLoading(false);
        handleCancel()
    }

    const deleteReport = async () => {
        if (!selectedReport) return;
        setLoading(true);
        await ApiHelper.op('report.delete', { id: selectedReport.id, deviceId: device.id }, user.companyId);
        setLoading(false);
        handleCancel();
    };

    const listDelete = async () => {

    }


    function waterTakeInput() {
        return (
            <div className='d-flex flex-xxl-row flex-column gap-4 w-100 justify-content-center p-l-25 p-r-25'>
                <Calendar dateFormat="dd/mm/yy" value={range?.finish} placeholder={"Select Finish Time"} showTime
                    hourFormat="24"
                    showIcon
                    onChange={(e: any) => setRange({ start: new Date(), finish: new Date(e.value) })}
                    minDate={new Date()} />

                <Button variant={range ? 'success' : 'outline-warning'} disabled={!range}
                    onClick={e => {
                        setConfirmMessage(`Attention: Please confirm if you would like to CREATE Water Take Report From ${HelperTime.DateBeautify(range?.start)} To ${HelperTime.DateBeautify(range?.finish)} `)
                        setShowc(true)
                    }}
                >
                    {range ? <><i className="far fa-check-circle"></i>
                        &nbsp; Confirm</> : "Select Finish Time"}
                </Button>
                <Button variant={'outline-danger'}
                    onClick={handleCancel}> <i className="fas fa-times-circle"></i>&nbsp; Cancel</Button>
            </div>
        )
    }

    function customWaterTakeInput() {
        return (
            <div className='d-flex flex-xxl-row flex-column gap-3 w-100 justify-content-center p-l-15 p-r-15'>
                <Calendar dateFormat="dd/mm/yy" value={range?.start} placeholder={"Select Start Time"} showTime
                    hourFormat="24"
                    onChange={(e: any) => setRange((prevState: range | undefined) => ({
                        ...prevState!,
                        start: new Date(e.target.value)
                    }))}
                    showIcon
                    maxDate={new Date()}
                />
                <Calendar dateFormat="dd/mm/yy" value={range?.finish} placeholder={"Select Finish Time"} showTime
                    hourFormat="24"
                    onChange={(e: any) => setRange((prevState: range | undefined) => ({
                        ...prevState!,
                        finish: new Date(e.target.value)
                    }))}
                    showIcon
                    minDate={range?.start} />
                <Button variant={'outline-info'} disabled={!range?.start || !range?.finish}
                    onClick={e => {
                        setConfirmMessage(`Attention: Please confirm if you would like to CREATE Water Take Report From ${HelperTime.DateBeautify(range?.start)} To ${HelperTime.DateBeautify(range?.finish)} `)
                        setShowc(true)
                    }}
                >
                    {range?.start && range?.finish ? (
                        <>
                            <i className="far fa-check-circle"></i>
                            &nbsp; Create
                        </>
                    ) : "Select Date Range"}
                </Button>
                <Button variant={'outline-danger'}
                    onClick={handleCancel}> <i className="fas fa-times-circle"></i>&nbsp; Cancel</Button>
            </div>
        )
    }

    function Body(reports: ApiInterfaces.Report[]) {
        const isRunning = HelperTime.isAnyReportRunning(reports)
        return (
            <>
                <div
                    className='d-flex flex-sm-row flex-column gap-4 w-100 justify-content-center p-l-25 p-r-25'>
                    {waterTake.take ? waterTakeInput() :
                        !isRunning ?
                            <Button variant={'outline-warning'}
                                onClick={e => {
                                    setWaterTake({ take: true, custom: false })
                                    setDeleting(false)
                                }
                                }>
                                <FaPlay /> &nbsp; {"Commence Water Take Report"}
                            </Button>
                            :
                            <Button variant={'outline-warning'}
                                onClick={e => {
                                    setConfirmMessage("Attention: The Water Take Report is currently being generated.\n" +
                                        "\n" +
                                        "By clicking \"Ok,\" you will terminate the report generation process. ")
                                    setShow(true)
                                    setReport(isRunning)
                                }}>
                                <FaTimes /> &nbsp; {"Water Take Report Running, click here to Terminate"}
                            </Button>
                    }
                </div>
                <hr />
                {waterTake.custom ? customWaterTakeInput() :
                    <Button variant={'outline-info'} className={"mx-auto d-bloc"}
                        onClick={e => {
                            setWaterTake({ take: false, custom: true })
                            setDeleting(false)
                        }}>
                        <FaCalendarAlt /> &nbsp; Custom Water Take Report
                    </Button>}

                <hr />
                <div className='d-flex flex-wrap gap-1 w-100 justify-content-center'>
                    {deleting ? (
                        <>
                            <div className="d-flex flex-grow-1">
                                <Typeahead
                                    id="delete-report-typeahead"
                                    labelKey="id"
                                    clearButton
                                    options={reports.map(report => ({ id: report.id }))}
                                    placeholder="Choose a report ID to delete..."
                                    onChange={(selected: any) => setSelectedReport(reports.find(report => report.id === selected[0]?.id))}
                                    selected={selectedReport ? [{ id: selectedReport.id }] : []}
                                    className="flex-grow-1"
                                />
                            </div>
                            <Button variant={'danger'} disabled={!selectedReport} onClick={() => {
                                setConfirmMessage(`Are you sure you want to delete this report?`);
                                setShowDeleting(true);
                            }}>
                                <FaTrash />
                            </Button>
                            <Button variant={'outline-secondary'} onClick={() => {
                                setDeleting(false)
                                setShowDeleting(false)
                            }
                            }>
                                <FaTimes />
                            </Button>
                        </>
                    ) : (
                        <Button variant={'danger'} onClick={() => setDeleting(true)}>
                            <FaTrash /> &nbsp; Delete Water Take Report
                        </Button>
                    )}
                </div>
                <hr />
            </>
        )
    }

    return (
        device && reports ? <>
            <ConfirmModal
                message={confirmMessage}
                action={show ? handleConfirmAction : createReport}
                show={show || showc}
                handleClose={() => {
                    setShow(false);
                    setShowc(false);
                }}
            />
            <ConfirmModal
                message="Are you sure you want to delete this report?"
                action={deleteReport}
                show={showDeleting}
                handleClose={() => {
                    setDeleting(false)
                    setShowDeleting(false);
                }}
            />
            <Col xs={12} md={12} lg={4} xl={4}>
                <Card className="mb-4" style={{ border: '1px solid #555', borderRadius: '8px' }}>
                    <Card.Header className="text-center" style={{ backgroundColor: '#000', color: '#fff', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                        <h5 className="mb-1" style={{ fontSize: '1.0rem' }}><strong>{device.name}</strong>Water Take Reports</h5>
                    </Card.Header>
                    <Card.Body className="text-center">
                        {loading ? <Spinner animation="border" /> : Body(reports)}
                        <ReportList key={device.id} device={device} expanded={false} reports={reports} />
                    </Card.Body>
                </Card>
            </Col>
        </>
            :
            <></>
    );
};
export default WaterTake
import axios from 'axios';
import React, { ComponentProps, useEffect, useState } from 'react';
import { Accordion, Badge, Tab, Tabs } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { HelperFunctions } from '../../Helper/functions';
import { HelperTime } from '../../Helper/Time';
import { ApiInterfaces } from '../../Interfaces';
import { Externals } from '../../Interfaces/Externals';
import { showError } from '../../redux/actions/alertsAction';
import { store } from '../../store';
import { Panel, PanelHeader, PanelBody } from '../panel/panel';
import Cloudily from './WeatherIcons/Cloudily';
import Others from './WeatherIcons/Other';
import Rainy from './WeatherIcons/Rain';
import Snow from './WeatherIcons/Snow';
import SunnyIcon from './WeatherIcons/Sunny';
import Windy from './WeatherIcons/Windy';

interface Props {
  reload: boolean,
  company: ApiInterfaces.Company,
  expanded?: boolean
}


interface IconsProps {
  smallIcon?: boolean;
}


function Forecast(props: Props) {
  const [index, setIndex] = useState<number>(0);
  const [reload, setReload] = useState<boolean>(false);
  const [forecast, setForecast] = useState<Externals.Forecast | undefined>(undefined);
  const [expand, setExpand] = useState<boolean>(props.expanded ? true : false);

  const handleSelect = (selectedIndex: React.SetStateAction<number>, e: any) => {
    setIndex(selectedIndex);
  };

  const getComponent = (key: string): React.ComponentType<IconsProps> => {

    let DynamicComponent: React.ComponentType<IconsProps>;
    switch (key) {
      case 'Clear':
        DynamicComponent = SunnyIcon
        break;
      case 'Rain':
        DynamicComponent = Rainy
        break;

      case 'Snow':
        DynamicComponent = Snow
        break;

      case 'Thunderstorm':
        DynamicComponent = Windy
        break;

      case 'Mist':
        DynamicComponent = Windy
        break;

      case 'Clouds':
        DynamicComponent = Cloudily
        break;

      default:
        DynamicComponent = Others
        break;
    }

    return DynamicComponent

  }

  useEffect(() => {

    const getForecast = async () => {
      try {
        const response = await axios.get<Externals.Forecast>(`https://api.openweathermap.org/data/2.5/onecall?lat=${props.company.lat}&lon=${props.company.lng}&exclude=hourly,minutely&units=metric&appid=20eb244d5b14b947f0689c4d2d3d8551`)
        setForecast(response.data)

      } catch (error) {
        store.dispatch(showError('Unable to get forecast'))
      }
    }

    getForecast()
    setReload(props.reload)

  }, []);






  function renderOverview(forecast: Externals.Forecast) {
    return Object.entries(forecast.daily).map(([k, v]) => {
      const DynamicComponent: React.ComponentType<IconsProps> = getComponent(v.weather[0].main);
      return (
        <div key={k} className="text-center">
          <h6>{HelperTime.getDayOfWeek(v.dt)}</h6>
          <DynamicComponent smallIcon={true} />
          <h5 className='w-100' style={{ display: 'flex', alignItems: 'center' }}>
            <Badge  bg='primary'>{Math.round(v.temp.night)}&nbsp;ºC</Badge>
            <Badge  bg='warning' >{Math.round(v.temp.day)}&nbsp;ºC</Badge>
          </h5>
        </div>
      )
    })
  }


  function renderMainPanel(forecast: Externals.Forecast, company: ApiInterfaces.Company) {

    const DynamicComponent: React.ComponentType<IconsProps> = getComponent(forecast.current.weather[0].main);
    return (
      <Carousel.Item >

        <div className="table-responsive">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th className="text-center">
                  <h3>Week Overview</h3>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">
                  <h5>{forecast.current.weather[0].description}</h5>
                  <DynamicComponent smallIcon={false} />
                  <h4 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Badge bg='danger'>{Math.round(forecast.current.temp)}&nbsp;ºC</Badge>
                    <Badge bg='primary' >{forecast.current.humidity}&nbsp;ºC</Badge>
                  </h4>
                  {/* <h6>{HelperTime.DateBeautify(new Date(forecast.current.dt * 1000))}</h6> */}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  <h5>DewPoint:&nbsp;
                    {forecast.current.dew_point}&nbsp;ºC&nbsp;&nbsp;&nbsp;&nbsp;P:&nbsp;
                    {forecast.current.pressure}&nbsp;hPa
                  </h5>
                  <h5>Wind:&nbsp;
                    {forecast.current.wind_speed}&nbsp;Km/h&nbsp;&nbsp;&nbsp;&nbsp;Gusts:&nbsp;
                    {forecast.current.wind_gust}&nbsp;Km/h
                  </h5>
                </td>
              </tr>

              <tr className="text-center">
                <td className="text-center">
                  <h4 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Badge bg='yellow' style={{ color: 'black' }}>Sunrise: {HelperTime.DateBeautifyTimeOnly(new Date(forecast.current.sunrise * 1000))}</Badge>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Badge bg='purple' >Sunset: {HelperTime.DateBeautifyTimeOnly(new Date(forecast.current.sunset * 1000))}</Badge>
                  </h4>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <hr />
      </Carousel.Item >

    )
  }


  function renderRest(forecast: Externals.Forecast, company: ApiInterfaces.Company) {

    return Object.entries(forecast.daily).map(([k, v]) => {
      const DynamicComponent: React.ComponentType<IconsProps> = getComponent(v.weather[0].main);
      return (
        <Carousel.Item key={k}>
          <div className="table-responsive">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th className="text-center">
                    <h3>{HelperTime.getDayOfWeek(v.dt)}</h3>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">
                    <h5>{v.weather[0].description}</h5>
                    <DynamicComponent smallIcon={false} />
                    <h4 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Badge bg='primary'>{Math.round(v.temp.night)}&nbsp;ºC</Badge>
                      <Badge bg='danger' >{Math.round(v.temp.day)}&nbsp;ºC</Badge>
                    </h4>
                  </td>
                </tr>
                <tr>
                  <td className="text-center">
                    <h5>DewPoint:&nbsp;
                      {v.dew_point}&nbsp;ºC&nbsp;&nbsp;&nbsp;&nbsp;P:&nbsp;
                      {v.pressure}&nbsp;hPa
                    </h5>
                    <h5>Wind:&nbsp;
                      {v.wind_speed}&nbsp;Km/h&nbsp;&nbsp;&nbsp;&nbsp;Gusts:&nbsp;
                      {v.wind_gust}&nbsp;Km/h
                    </h5>
                  </td>
                </tr>
                <tr className="text-center">
                  <td className="text-center">
                    <h4 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Badge bg='yellow' style={{ color: 'black' }}>Sunrise: {HelperTime.DateBeautifyTimeOnly(new Date(v.sunrise * 1000))}</Badge>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Badge bg='purple' >Sunset: {HelperTime.DateBeautifyTimeOnly(new Date(v.sunset * 1000))}</Badge>
                    </h4>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <hr />
        </Carousel.Item >

      )
    })
  }





  return (
    forecast && props.company ?
      <Panel theme={'inverse'} reload={reload} style={{ marginBottom: '0rem' }} >
        <Accordion activeKey={expand ? "0" : undefined}>
          <Accordion.Item eventKey="0">
            <Accordion.Header onClick={() => (setExpand(!expand))} style={{ padding: '1px' }}>
              <hr />
              <div className='d-flex w-100 gap-4 overflow-auto justify-content-lg-center'  >
                {renderOverview(forecast)}
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
                {renderMainPanel(forecast, props.company)}
                {renderRest(forecast, props.company)}
              </Carousel>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Panel > : <></>
  );
}

export default Forecast

import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

type ConfirmModalProps = {
    message: string;
    action: () => void;
    show: boolean;
    title?: string;
    handleClose: () => void;
};

const ConfirmModal: React.FC<ConfirmModalProps> = ({ message, action, show, handleClose, title }) => {

    const handleConfirm = () => {
        action(); // Trigger the action handler
        handleClose(); // Close the modal
    };

    return (
        <Modal show={show} onHide={handleClose} dialogClassName='confirm'>
            <Modal.Header closeButton>
                <Modal.Title>{title || "Confirm Action"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleConfirm} className="me-auto d-flex align-items-center">
                    <FaCheckCircle className="me-2" /> Confirm
                </Button>
                <Button variant="outline-danger" onClick={handleClose} className="ms-auto d-flex align-items-center">
                    <FaTimesCircle className="me-2" /> Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;
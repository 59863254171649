import React, {useEffect, useRef, useState} from 'react';
import {Button, Modal, Table, Spinner} from 'react-bootstrap';
import {ApiInterfaces} from '../../Interfaces';
import {IRedux} from '../../Interfaces/redux';
import {HelperTime} from "../../Helper/Time";
import DateBeautify = HelperTime.DateBeautify;
import {GraphBuilder} from "../Graphs/Default";
import {IoT} from "../../Helper/IoT";
import Highcharts from "highcharts";
import Chart from "../Graphs/Charts";
import newPDF from "../Utils/PDF/Generic";
import {HelperFunctions} from "../../Helper/functions";
import {ReduxActions} from "../../redux/actions";


type Props = ModalProps & IRedux.IAuth;


interface ModalProps {
    show: boolean;
    handleClose: () => void;
    device: ApiInterfaces.Device
    report: ApiInterfaces.Report | undefined
}

interface graph {
    data: StorageIoTReport[]
    graph: Highcharts.SeriesOptionsType[]
}

export interface CaptisStoragePayload1 {
    distanceToWaterLevel: number;
    storageCapacity: number;
    volume: number;
    waterLevel_AHD: number;
    area: number;
}

interface CaptisStoragePayload2{
    battery_voltage: string;
    avgTemp: string;
    tripped_state: string;
}

interface CaptisStoragePayload3 {
    signalStrength: string;
    serialNumber: string;
    hardware_model: string;
    hardware_revision: string;
    interval: string;
}

export type CaptisPayload = CaptisStoragePayload1 | CaptisStoragePayload2 | CaptisStoragePayload3;

export type StorageIoTData<T extends CaptisPayload> = Omit<ApiInterfaces.DeviceData, "payload"> & { payload: T }

// export type StorageIoTData = Omit<ApiInterfaces.DeviceData, "payload"> & { payload: CaptisStoragePayload1 | CaptisStoragePayload2 | CaptisStoragePayload3 }
export type StorageIoTReport = Omit<ApiInterfaces.DeviceData, "payload"> & { payload: CaptisStoragePayload1 }

function WaterTakeModal(props: Props) {
    const {show, handleClose, device, report} = props;
    const chartRef = useRef(null);
    const [data, setData] = useState<graph | undefined>(undefined)
    const [loading, setLoading] = useState(false);

    const customMetadata = [
        {
            "key": "Volume",
            "color": "#4a5dc6",
            "values": [],
            "disabled": false,
            "context": "volume",
            "tooltip": {
                "valueDecimals": 3,
                "valueSuffix": "ML"
            },
        },
        {
            "key": "Water Level",
            "color": "#fbd406",
            "values": [],
            "disabled": false,
            "type": "line",
            "context": "waterLevel_AHD",
            "tooltip": {
                "valueDecimals": 3,
                "valueSuffix": "AHD"
            },
        },
        {
            "key": "Storage Capacity",
            "color": "#ff0202",
            "values": [],
            "disabled": false,
            "type": "line",
            "context": "storageCapacity",
            "tooltip": {
                "valueDecimals": 3,
                "valueSuffix": "%"
            },
        }
    ]



    async function GetData() {
        if (report) {
            setLoading(true);
            const iotData = await IoT.getTimeSeriesData(device, "aa", report.created_at, report.finished || new Date())
            setLoading(false);
            if (iotData) {
                setData({
                    data: HelperFunctions.StorageReportFunction(iotData.data),
                    graph: GraphBuilder.generateHighChartsWithMetadata(iotData.data, customMetadata, 'column')
                })
            }
        }
    }

    useEffect(() => {
        GetData()
    }, []);


    const captureModalAsPdf = async () => {
        handleClose()
        const modal = chartRef.current as any
        if (!modal) return;
        if (report && data) {
            const pdf = {
                report,
                device,
                data: data.data
            }
            await newPDF(pdf, "Water Take", undefined, DateBeautify(report.created_at), await GraphBuilder.getBase64(GraphBuilder.switchToLightModeAndExportSVG(modal)))
        }
    };

    function fixRow(l1: string, l2: string, v1: string, v2: string) {

        const isMobile = HelperFunctions.isMobile()
        const colsPan = isMobile ? 2 : 3

        return !isMobile ?
            <tr>
                <td className={"text-center text-info"}> {l1}</td>
                <td className={"text-center "}> {v1}</td>
                <td className={"text-center text-info"}> {l2}</td>
                <td className={"text-center "}> {v2}</td>
            </tr> :
            <>
                <tr>
                    <td className={"text-center text-info"}> {l1}</td>
                    <td className={"text-center "} colSpan={colsPan}> {v1}</td>
                </tr>
                <tr>
                    <td className={"text-center text-info"}> {l2}</td>
                    <td className={"text-center "} colSpan={colsPan}> {v2}</td>
                </tr>
            </>

    }


    function summaryTable() {
        const d = data?.data
        const isMobile = HelperFunctions.isMobile()
        const colsPan = isMobile ? 2 : 3
        return (
            d &&

            <Table hover bordered={true}>
                <tbody>
                {!(report?.finished && new Date(report.finished).getTime() <= new Date().getTime()) && <tr>
                    <td className={"text-danger"} colSpan={colsPan + 1}>
                        Warning: The water take report is still in progress. Please review the intake snapshot provided
                        below.
                    </td>
                </tr>}
                <tr>
                    <td className={"text-center text-info"}> Report Id</td>
                    <td className={"text-center"} colSpan={colsPan}>  {report?.id}</td>
                </tr>
                <tr>
                    <td className={"text-center text-info"}> Report Title</td>
                    <td className={"text-center"} colSpan={colsPan}> {report?.title}</td>
                </tr>
                <tr>
                    <td className={"text-center text-info"}> Storage Name</td>
                    <td className={"text-center"} colSpan={colsPan}> {device.name}</td>
                </tr>
                <tr>
                    <td className={"text-center text-info"}> Storage Coordinates</td>
                    <td className={"text-center"} colSpan={colsPan}>
                        (Lat {device.lat?.toFixed(3)}) -
                        (Lng {device.lng?.toFixed(3)})
                    </td>
                </tr>
                {fixRow("Created At", "Finished", DateBeautify(report?.created_at), DateBeautify(report?.finished))}
                {d && d[0]?.payload && d[d.length - 1]?.payload && (
                    <>
                        {fixRow("Initial Volume", "Final Volume", `${d[0].payload.volume.toFixed(3)} ML`, `${d[d.length - 1].payload.volume.toFixed(3)} ML`)}
                        {fixRow("Initial Storage Level", "Final Storage Level", `${d[0].payload.storageCapacity.toFixed(3)} %`, `${d[d.length - 1].payload.storageCapacity.toFixed(3)} %`)}
                        {fixRow("Initial Water Level", "Final Water Level", `${d[0].payload.waterLevel_AHD.toFixed(3)} AHD`, `${d[d.length - 1].payload.waterLevel_AHD.toFixed(3)} AHD`)}
                        {fixRow("Initial Surface Area", "Final Surface Area", `${d[0].payload.area.toFixed(3)} Ha`, `${d[d.length - 1].payload.area.toFixed(3)} Ha`)}
                        <tr>
                            <td colSpan={4}><br/></td>
                        </tr>
                        {fixRow("Volume Gain", "Storage Delta", `${(d[d.length - 1].payload.volume - d[0].payload.volume).toFixed(3)} ML`, `${(d[d.length - 1].payload.storageCapacity - d[0].payload.storageCapacity).toFixed(3)} %`)}
                        {fixRow("Water Level Delta", "Surface Area Delta", `${(d[d.length - 1].payload.waterLevel_AHD - d[0].payload.waterLevel_AHD).toFixed(3)} AHD`, `${(d[d.length - 1].payload.area - d[0].payload.area).toFixed(3)} Ha`)}

                    </>
                )}
                </tbody>
            </Table>

        )
    }


    return <Modal show={show} onHide={handleClose} className="qc_modal">
        <Modal.Header closeButton>
            <Modal.Title>
                {report?.finished && new Date(report.finished).getTime() <= new Date().getTime() &&
                    <Button variant="outline-warning" onClick={captureModalAsPdf}>
                        <i className="fas fa-download"></i>
                    </Button>
                }
                &nbsp;&nbsp; {report?.title} - {DateBeautify(report?.created_at)}</Modal.Title>

        </Modal.Header>
        <Modal.Body>
            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" />
                </div>
            ) : (
                <div>
                    {summaryTable()}
                    <hr/>
                    {data &&
                        <Chart forwardedRef={chartRef} device={device} key={device.id} type={"line"}
                               customMetadata={customMetadata} shared={true}
                               series={data?.graph}/>}

                </div>
            )}
        </Modal.Body>
        <Modal.Footer>
            {report?.finished && new Date(report.finished).getTime() <= new Date().getTime() &&
                <Button variant="outline-warning" onClick={captureModalAsPdf}>
                    <i className="fas fa-download"></i> Export PDF
                </Button>}
            <Button variant='danger' onClick={handleClose}>  &nbsp; <i className="far fa-times-circle"></i> &nbsp;
            </Button>
        </Modal.Footer>
    </Modal>
}

export default ReduxActions.connector(WaterTakeModal)
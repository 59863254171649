import React, { useState, useEffect } from 'react';
import { Badge, Button, Col, Card, ListGroup } from 'react-bootstrap';
import { IRedux } from '../../Interfaces/redux';
import { ApiInterfaces } from '../../Interfaces';
import { HelperTime } from '../../Helper/Time';
import { SVG, SvgIcon } from '../Utils/SVG';
import { ReduxActions } from "../../redux/actions";
import TimeAgo from "react-timeago";
import TableHelper from '../Utils/GenericTable';

const NotificationPanel = (props: IRedux.IAuth & { table: ApiInterfaces.Notification[], fullwidth?: boolean }) => {
    const [table, setTable] = useState<ApiInterfaces.Notification[]>([])
    const [filter, setFilter] = useState<ApiInterfaces.Notification[]>([])
    const [expand, setExpand] = useState<boolean>(false);

    useEffect(() => {
        setTable(props.table)
        setFilter(props.table.reverse())
    }, [props.table]);

    function renderList() {
        return filter.map((v, k) => {
            if (k > 500) {
                return null;
            }
            return (
                <ListGroup.Item key={k} className="d-flex justify-content-between align-items-center">
                    <div>
                        <Badge bg={v.importance.toLocaleLowerCase() === 'high' ? 'danger' : v.importance.toLocaleLowerCase() === 'warning' ? 'warning' : 'secondary'}>{v.title}</Badge>
                        <span className="ms-2">{v.message}</span>
                    </div>
                    <div className="text-muted small">
                        {HelperTime.DateBeautify(v.created_at)}
                    </div>
                </ListGroup.Item>
            );
        });
    }

    const updateList = (filter: ApiInterfaces.Notification[]) => {
        setFilter(filter)
    }

    return (
        <>
            <Col xs={12} md={6} lg={4} xl={3}>
                <Card className="mb-4" style={{ border: '1px solid #555', borderRadius: '8px' }}>
                    <Card.Header className="text-center" style={{ backgroundColor: '#000', color: '#fff', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' }}>
                        <h5 className="mb-1" style={{ fontSize: '1.0rem' }}><strong>Notification Panel</strong></h5>
                    </Card.Header>
                    <Card.Body className="text-center">
                        <Button variant="outline-info" size="sm" className="w-75 mb-3">
                            <i className="fa-fw fas fa-sync"></i> - Refresh Notifications
                        </Button>
                        <div className="mb-3">
                            <SvgIcon height={100} width={100} element={SVG.Icon['Notification']} />
                        </div>
                        <Card className="mb-4" style={{ border: '1px solid #555', borderRadius: '8px' }}>
                            <Card.Body>
                                {table.length === 0 ? 'Your notifications feed is empty' :
                                    <div className="d-flex flex-wrap gap-1 w-100 justify-content-center align-items-center position-relative">
                                        <Badge
                                            bg={table[0].importance.toLocaleLowerCase() === 'high' ? 'danger' : table[0].importance.toLocaleLowerCase() === 'warning' ? 'warning' : 'secondary'}>{table[0].title} </Badge>
                                        &nbsp;&nbsp;{table[0].message}
                                        <Button variant="link" size="sm" onClick={() => setExpand(!expand)} style={{ border: 'none', boxShadow: 'none', position: 'absolute', right: 0 }}>
                                            {expand ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
                                        </Button>
                                    </div>
                                }
                                {expand && (
                                    <>
                                        <hr />
                                        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                            <TableHelper
                                                table={table}
                                                name="Notifications"
                                                updateList={updateList}
                                            />
                                            <ListGroup>
                                                {renderList()}
                                            </ListGroup>
                                        </div>
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                        <div className="d-flex justify-content-center mt-2 mb-1">
                            <cite className='text-muted small m-0 ms-0'>
                                {table.length === 0 ? ""
                                    :
                                    <>
                                        Last Notification <TimeAgo date={new Date(table[0].created_at || "")} />
                                    </>
                                }
                            </cite>
                        </div>
                    </Card.Body>
                </Card>

            </Col>
        </>
    );
};

export default ReduxActions.connector(NotificationPanel);
// Sidebar.tsx
import React, {useEffect, useState} from 'react';
import './Sidebar.css';
import {connect} from 'react-redux';
import {IRedux} from '../../Interfaces/redux';
import {ApiInterfaces} from '../../Interfaces';
import {SVG, SVG_Panel, SvgIcon} from '../Utils/SVG';
import {Button, Card,} from 'react-bootstrap';
import {GetMqtt} from '../../Server/Api/MqttManager';
import {IoT} from '../../Helper/IoT';
import {Subscription} from 'rxjs';
import {IoTGetLastResponse} from '../../Server/Events/base';
import DeviceEditModal from '../Devices/Edit'
import {HelperTime} from '../../Helper/Time';


const Settings: React.FC<IRedux.IAuth & { device: ApiInterfaces.Device }> =
    ({
         user,
         accessToken,
         device,
         mqttToken
     }) => {
        const [showModal, setShowModal] = useState(false);
        const [payload, setPayload] = useState<JSON | IoTGetLastResponse | undefined>(undefined);
        const [visual, setVisual] = useState<{ status: string, display: string }>({status: "", display: ""});

        // const instance = MqttManager.getInstance(mqttToken || '').getMqttService();

        useEffect(() => {
            const getLastValue = async (dev: ApiInterfaces.Device) => {
                const data = await IoT.getLastValue(dev, accessToken)
                if (data) payloadHandler(data)
            }
            const stream: Subscription | undefined = device.IO ? GetMqtt().getMessage(`${device.companyId}/${device.id}/Live/`).subscribe((message) => {
                payloadHandler(JSON.parse(message))
                stream?.unsubscribe();
            }) : undefined;

            !device.IO && getLastValue(device);


            return () => {
                stream?.unsubscribe();
            };
        }, []);

        const payloadHandler = (message: JSON | IoTGetLastResponse) => {
            let status = ''
            let display = ''


            if ('timestamp' in message) {
                const check = new Date().getTime() - (2 * 24 * 60 * 60 * 1000) //check if last data is older than 36 hours
                status = new Date(message.timestamp).getTime() < check ? 'Offline' : 'Online'
                display = HelperTime.formatRelativeTime(message.timestamp.toString())

            } else {
                status = 'Online'
                display = 'Online'
            }
            setPayload('timestamp' in message ? message.payload : message)
            setVisual({status, display})
        }


        return (
            <>
                {payload &&
                    <DeviceEditModal show={showModal} handleClose={()=> setShowModal(false)} device={device} key={device.id}
                                     payload={payload}/>}

                <Card>
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <div className="mx-auto">{device.name}
                        </div>
                        {payload && <Button size='sm' variant='primary' onClick={() => setShowModal(!showModal)}><i
                            className="far fa-edit"></i> </Button>}
                    </Card.Header>

                    <Card.Body>
                        <Card.Text>
                            <div className="d-flex justify-content-center p-4">
                                {SVG.Icon.hasOwnProperty(device.class) &&
                                    <SvgIcon height={70} width={70} element={SVG.Icon[device.class]}/>}
                            </div>
                            <hr/>
                            <div className="d-flex justify-content-center">
                                <>
                                    <SvgIcon height={30} width={30}
                                             element={SVG_Panel.Icon[visual.status === "" ? 'Sync' : visual.status]}/>  &nbsp;
                                    <p className='fw-bolder m-0 ms-2'>{visual.display === "" ? 'Syncing...' : visual.display}</p> &nbsp;
                                </>

                            </div>

                        </Card.Text>

                    </Card.Body>
                </Card>

            </>
        );
    };


const mapStateToProps = (state: IRedux.MapState) => {
    const {user, isLoggedIn, accessToken, refreshToken, mqttToken} = state.auth;
    return {
        user,
        isLoggedIn,
        accessToken,
        refreshToken,
        mqttToken
    };
};

const connector = connect(mapStateToProps);

export default connector(Settings);

import React, {createRef, useRef, useState} from "react";
import {FormAction, Section,  InputValue} from "../Utils/Forms/Interface";
import {DynamicForm} from "../Utils/Forms/Form";
import {IRedux} from "../../Interfaces/redux";
import {useSelector} from "react-redux";
import {FiPlus, FiTrash} from "react-icons/fi";
import {ApiInterfaces} from "../../Interfaces";
import {HelperFunctions} from "../../Helper/functions";
import {Button} from "react-bootstrap";
import {GiCancel} from "react-icons/gi";
import {ReduxActions} from "../../redux/actions";
import {SectionDataValidation} from "../Utils/Forms/Validation";

type formdata = Partial<ApiInterfaces.DataReport> & { id: string };

interface IProps {
    section: formdata;
    devices: ApiInterfaces.Device[]
    cancelSection:()=>void;
    saveSection:(newSection:ApiInterfaces.DataReport) =>void;
    newItem?:boolean

}

function DataTemplate(props: IProps): JSX.Element {
    const {section, devices, newItem, cancelSection,saveSection} = props
    const {user} = useSelector((state: IRedux.MapState) => state.auth);
    const DevOtions = HelperFunctions.OptionKeyMapGenerator(devices, "id","name", false)
    const formRef = useRef<FormAction<formdata> | undefined>(undefined);

    async function getForm() {
        const f = formRef.current;
        const data = f?.getFormData();
        const valid = await f?.getYupValidation(SectionDataValidation)
        if (data && valid?.isValid) {
            if(data?.include === undefined || data.include.length === 0) {
                ReduxActions.ShowError('Must include at least one data')
                return
            }
            saveSection(data as ApiInterfaces.DataReport)
        }
    }

    const getIncludeOptions = (deviceId: string | undefined) => {
        if (!deviceId) return {};
        const dev = devices.find(x => x.id === deviceId);
        return dev ? HelperFunctions.GraphMetadataOptions(dev.graph_metadata || []) : {};
    };


    const Template: Section = {
        type: "section",
        title: `${newItem ? 'Create' : 'Edit'} ${section.type && section.type.charAt(0).toUpperCase() + section.type.slice(1).toLowerCase()} Section`,
        id: "graphMain",
        items: [
            {
                type: "row",
                id: "mainRow",
                items: [
                    {type: "text", id: `title`, label: `${section.type === 'table' ? "Table" : "Graph"} Title`, value: "[title]", width: 12},
                    // {type: "button", id: `deleteSummary`, icon: <FiTrash/>, label: "Delete",  width: 2, style: "danger", value: "", onClick: () => deleteSection(section.id)},
                    {type: "dropdown", id: `deviceId`, label: "Device ID", value: `[deviceId]`, width: 4, options:DevOtions},
                    {type: "multiSelect", id: `include`, label: "Select Data", value: `[include]`, width: 8, options:getIncludeOptions(section.deviceId), hidden:newItem},

                ],
            },
        ],
    };


    return (
        <>
            <hr/>
            <DynamicForm<formdata>
                actionTriggered={(id, action, form) => {
                    formRef.current = form;
                    console.log(id, action)
                    return true;
                }}
                valueChanged={(id, value, valid, form) => {
                    // console.log(id, value)
                    if (id === 'deviceId' && value) {

                        const dev = devices.find(x => x.id === value)
                        if (dev) form.setOptions(`include`, HelperFunctions.GraphMetadataOptions(dev.graph_metadata || []))
                        console.log(dev, HelperFunctions.GraphMetadataOptions(dev?.graph_metadata || []))
                        form.setHidden('include', false)
                        // form.setMultipleHidden(false, `summaryRowData_${rowId}`, `summaryRowOperation_${rowId}`);
                    }
                    return true;
                }}
                id="Template"
                inputData={section}
                content={[Template]}
            />
            <hr/>
            <div className="d-flex">
                <Button variant="outline-success" className="d-flex align-items-center mx-2" onClick={getForm}>
                    <FiPlus size={23}/> Save
                </Button>
                <Button variant="outline-secondary" className="d-flex align-items-center mx-2" onClick={cancelSection}>
                    <GiCancel size={23}/>  &nbsp;Cancel
                </Button>
            </div>
        </>


    );
}

export default DataTemplate;

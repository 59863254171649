import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

type ConfirmModalProps = {
    action: () => void;
    show: boolean;
    handleClose: () => void;
};

const UnsavedModal: React.FC<ConfirmModalProps> = ({ action, show, handleClose }) => {


    const handleConfirm = () => {
        action(); // Trigger the action handler
        handleClose(); // Close the modal
    };

    return (
        <Modal show={show} onHide={handleClose} dialogClassName='confirm'>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Action</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you really want to close this? Unsaved settings will be lost.</Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleConfirm} className="me-auto d-flex align-items-center">
                    <FaCheckCircle className="me-2" /> Confirm
                </Button>
                <Button variant="outline-warning" onClick={handleClose} className="ms-auto d-flex align-items-center">
                    <FaTimesCircle className="me-2" /> Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UnsavedModal;
import { KeyMap } from "../../../Helper/Core/interface";
import { Events } from "../../Events/Events";
import { SubDataSet } from "../SubDataSet";
import * as Company from "../bidings/Company"

const type = 'notification.company.list'
export type DataType = Events['notification.company.list']['response']['data'][number];


export class Binding extends SubDataSet<DataType, Company.DataType> {
    public constructor(private skip:boolean, private eventSetData: Company.Binding, cb?: (data: KeyMap<DataType>) => void, parentSetFilter?: (item: Company.DataType) => boolean, awaitFirstLoad?: boolean) {
        super(eventSetData, false, i => i.id, i => i.id, cb, parentSetFilter, awaitFirstLoad);
    }
    protected async bindToBaseId(companyId: string, storeData: (data: DataType[] | undefined) => void) {
        const simRequest: Events['notification.company.list']['response'] = {type, data: this.eventSetData.getData()[companyId].Notifications || []}
        return await this.manager.bind(type, { companyId, topic: `${companyId}/notification` , skipBinding: this.skip ? simRequest : undefined }, (res) => {
            if (res.type === type) {
                storeData(res.data);
            } else {
                storeData(undefined);
            }
        });
    }
}

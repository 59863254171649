import { showError } from '../redux/actions/alertsAction';
import { store } from '../store'

export const validator = (fieldtype: string, value: any, inputName: string) => {
    let Errors: any = new Array();
    if (!value || value === "") {
        Errors[inputName] = "Field Cant be Empty";
        return Errors;
    }
    return;
}

export function isEmpty(value: string) {
    if (value.length === 0) return false
    return true
}

export function ValidatePassword(Password: string, ConfirmPassword: string): boolean {
    if (Password === ConfirmPassword && isEmpty(Password) && isEmpty(ConfirmPassword)) return true
    else {
        store.dispatch(showError("Passwords Dont Match!"))
        return false
    }
}



export function validateLogin(u: string, p: string): boolean {
    if (u.length === 0) {
        store.dispatch(showError("Username Field cannot be empty!"))
        return false;
    }
    if (p.length === 0) {
        store.dispatch(showError("Password Field cannot be empty!"))
        return false;
    }

    return true
}




import { KeyMap } from "../../../Helper/Core/interface";
import { Events } from "../../Events/Events";
import { DataSet } from "../DataSet";


const type = "user.expo.notification.list"
export type DataType = Events['user.expo.notification.list']['response']['data'][number];

export class Binding extends DataSet<DataType> {
    public constructor(private userId: string, private companyId: string, cb?: (data: KeyMap<DataType>) => void) {
        super(i => i.id, cb);
    }
    public async bind() {
        super.bind();
        this.registerBindingKey(await this.manager.bind(type, {
            topic: `${this.companyId}/${this.userId}/notification`,
            userId: this.userId
        }, async (res) => {
            if (res.type === type) {
                this.setData(res.data);
            } else {
                this.setLoaded();
            }
        }));
    }
}
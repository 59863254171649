import {KeyMap} from '../../../Helper/Core/interface';
import React, {Component, useRef} from 'react';

import {InputValue, Input, Group, Row, Section, List, Collection, deepCopy, BaseFormAction, FormScaffold, FormAction} from './Interface';
import {generateFormElement, StateControl, isGroupType} from './helper';
import {Form} from 'react-bootstrap';
import * as Yup from 'yup';


export interface ValidationResult {
    isValid: boolean;
    data?: { [key: string]: any };
    errors?: { [id: string]: string };
}


export type Content = (Input | Group | Row | Section | List | Collection<Input | Group | Row | Section | List>);

export interface Props<T> {
    id: string,
    inputData: T,

    valueChanged: (inputId: string, value: InputValue, valid: boolean, form: FormAction<T>) => (Promise<boolean> | PromiseLike<boolean> | boolean),
    actionTriggered: (inputId: string, action: any, form: FormAction<T>) => (Promise<boolean> | PromiseLike<boolean> | boolean),

    content: Content[],
}

export interface State {
    form: FormScaffold[],
    nodes: KeyMap<FormScaffold>,
    validation: boolean,
    changed: boolean;
}

function generateForm<T>(
    content: Content[],
    ids: KeyMap<StateControl>,
    input: T,
    oldValues: KeyMap<StateControl>,
    idPrefix: string | undefined,
    valueChanged: (inputId: string, value: InputValue, valid: boolean, form: FormAction<T>) => (Promise<boolean> | PromiseLike<boolean> | boolean),
    actionTriggered: (inputId: string, action: string, form: FormAction<T>) => (Promise<boolean> | PromiseLike<boolean> | boolean),
    actions: FormAction<T>,
): FormScaffold[] {
    const bindValues = (content: Content) => {
        const id = content.id ? (idPrefix ? `${idPrefix}.${content.id}` : content.id) : undefined;
        if (isGroupType(content)) {
            content.items.forEach((i: any) => {
                bindValues(i);
            });
        } else if (id !== undefined && oldValues[id] !== undefined) {
            if (typeof content.value === 'string' && content.value[0] === '[' && content.value[content.value.length - 1] === ']') {
                const key = content.value.slice(1, content.value.length - 1).split('.');
                let p: any = undefined;
                let pk = '';
                let v = input as any;
                key.forEach((k: any) => {
                    if (v === undefined) {
                        v = {};
                        p[pk] = v;
                    }
                    pk = k;
                    p = v;
                    v = v[k];
                });
                p[pk] = oldValues[id].value;
            } else {
                //override the value with the old cached value
                content.value = oldValues[id].value;
            }
        }
    }

    return content.map((i, index) => {
        bindValues(i);
        return generateFormElement(
            i,
            {
                valueChanged: (id: any, value: any) => {
                    ids[id].setProcessing(true);
                    ids[id].value = value;
                    Promise.resolve(valueChanged(id, value, ids[id].valid, actions))
                        .then(() => {
                            ids[id].setProcessing(false);
                        });
                },
                validChanged: (id: any, valid: any) => {
                    ids[id].setProcessing(true);
                    ids[id].valid = valid;
                    Promise.resolve(valueChanged(id, ids[id].value, valid, actions))
                        .then(() => {
                            ids[id].setProcessing(false);
                        });
                },
                clicked: (id: any, action: any) => {
                    ids[id].setProcessing(true);
                    Promise.resolve(actionTriggered(id, action, actions))
                        .then(() => {
                            if (ids[id]) {
                                ids[id].setProcessing(false);
                            }
                        });
                }
            },
            ids,
            (key: any) => {

                // pull data out of the input data and provide a callback to update that data inplace
                let p: any = undefined;
                let pk = '';
                let v = input as any;
                key.forEach((k: any) => {
                    if (v === undefined) {
                        v = {};
                        p[pk] = v;
                    } else if (k.includes('AKey')) {
                        let index = k.split('-');
                        pk = index[1];
                        p = v;
                        v = v[index[1]];


                    } else {
                        pk = k;
                        p = v;
                        v = v[k];
                    }
                });
                return {
                    value: v as InputValue, changeValue: (newValue: any) => {
                        p[pk] = newValue
                    }
                }
            },
            actions,
            idPrefix,
            index,
        );
    });
}

export class DynamicForm<T> extends Component<Props<T>, State> {

    public constructor(props: Props<T>) {
        super(props);

        const inputCopy = deepCopy(this.props.inputData || {}) as T;
        // const inputCopy = this.props.inputData as T;
        const ids: { [key: string]: StateControl } = {};


        const actions: FormAction<T> = {
            getValue: (id: string) => ids[id].value,
            getValid: (id: string) => ids[id].valid,

            getFormData: () => inputCopy,

            setFormValidation: (validated: boolean) => {
                this.setState({
                    validation: validated,
                });
            },

            getFormChanged: () => {
                return this.state.changed
            },

            getFormValidation: () => {
                return this.inputRef.current?.checkValidity()
            },


            getYupValidation: async (schema: Yup.Schema<any>) => {
                const result: ValidationResult = {
                    isValid: false,
                };

                try {
                    // Create a new object containing only the 'value' properties from dataToValidate
                    const valuesToValidate: { [key: string]: any } = Object.keys(ids).reduce((acc, key) => {
                        acc[key] = ids[key].value;
                        return acc;
                    }, {} as { [key: string]: any }); // Cast acc as { [key: string]: any }


                    Object.keys(ids).forEach((id) => {
                        if (ids[id]) {
                            ids[id].setIsInvalid(false);
                            ids[id].setFeedbackMessage('');
                        }
                    });
                    // Validate the 'valuesToValidate' object using the provided schema
                    const validatedData = await schema.validate(valuesToValidate, {abortEarly: false});


                    result.isValid = true;
                    result.data = validatedData;
                } catch (validationError) {
                    // Explicitly cast the validationError to Yup.ValidationError
                    const errors: { [id: string]: string } = (validationError as Yup.ValidationError).inner.reduce((acc, error) => {
                        const path = error.path as string;
                        const id = path.split('.')[0]; // Get the ID from the error path
                        acc[id] = error.message;
                        if (ids[id]) {
                            ids[id].setIsInvalid(true);
                            ids[id].setFeedbackMessage(error.message);
                        }
                        return acc;
                    }, {} as { [id: string]: string }); // Cast acc as { [id: string]: string }

                    result.errors = errors;
                }

                return result;
            },

            setValue: (id: string, value: InputValue) => ids[id].setValue(value),
            setValid: (id: string, valid: boolean, message?: string) => ids[id].setValid(valid, message),
            setDisabled: (id: string, disabled: boolean) => ids[id].setDisabled(disabled),
            setMultipleDisabled: (disabled: boolean, ...elements: string[]) => elements.forEach(id => {
                ids[id].setDisabled(disabled)
            }),
            setColor: (id: string, color: string) => ids[id].setColor(color),
            setLabel: (id: string, label: string) => ids[id].setLabel(label),
            setFeedbackMessage: (id: string, feedback: string) => ids[id].setFeedbackMessage(feedback),
            setIsInvalid: (id: string, isInvalid: boolean) => ids[id].setIsInvalid(isInvalid),

            setHidden: (id: string, hidden: boolean) => ids[id].setHidden(hidden),
            setMultipleHidden: (hidden: boolean, ...elements: string[]) => elements.forEach(id => {
                ids[id].setHidden(hidden)
            }),
            setOptions: (id: string, options: { [key: string]: InputValue }) => ids[id].setOptions(options),
            replaceFormContent: (content: (Input | Group | Row | Section | List | Collection<Input | Group | Row | Section | List>)[], nodeId?: string | undefined) => {
                if (nodeId !== undefined) {
                    const newNodes = {...this.state.nodes};
                    const oldValues: KeyMap<any> = {};
                    const removeIds = (node: FormScaffold) => {
                        oldValues[node.id] = ids[node.id];
                        ids[node.id].setValue(undefined)
                        delete ids[node.id];
                        delete newNodes[node.id];
                        node.children.forEach((c: any) => removeIds(c));
                    }
                    const target = this.state.nodes[nodeId];
                    removeIds(target);
                    const processNode = (node: FormScaffold) => {
                        newNodes[node.id] = node;
                        node.children.forEach((c: any) => processNode(c));
                    }
                    if (target.parent) {
                        const index = target.parent.children.indexOf(target);
                        const newForm = generateForm(content, ids, inputCopy, oldValues, undefined, props.valueChanged, props.actionTriggered, actions);
                        newForm.forEach(c => processNode(c));
                        target.parent.children = [
                            ...target.parent.children.slice(0, index),
                            ...newForm,
                            ...target.parent.children.slice(index + 1)
                        ];
                        let r = target.parent;
                        let form = this.state.form;
                        while (r) {
                            r = {
                                ...r,
                                node: React.cloneElement(r.node, {}, r.children.map((c: any) => c.node))
                            };
                            newNodes[r.id] = r;
                            if (r.parent === undefined) {
                                break;
                            }
                            r = r.parent;
                        }
                        form = form.map(f => f.id === r.id ? r : f);
                        this.setState({nodes: newNodes, form});
                    } else {
                        const index = this.state.form.indexOf(target);
                        const newForm = generateForm(content, ids, inputCopy, oldValues, undefined, props.valueChanged, props.actionTriggered, actions);
                        newForm.forEach(c => processNode(c));
                        this.setState({
                            form: [
                                ...this.state.form.slice(0, index),
                                ...newForm,
                                ...this.state.form.slice(index + 1)
                            ], nodes: newNodes
                        });
                    }
                } else {
                    const newNodes = {...this.state.nodes};
                    const processNode = (node: FormScaffold) => {
                        newNodes[node.id] = node;
                        node.children.forEach((c: any) => processNode(c));
                    }
                    const newIds: { [key: string]: StateControl } = {};
                    const newForm = generateForm(content, newIds, inputCopy, ids, undefined, props.valueChanged, props.actionTriggered, actions);
                    newForm.forEach(c => processNode(c));
                    this.setState({form: newForm, nodes: newNodes});
                }
            },
            removeFormNode: (nodeId: string): KeyMap<any> => {
                const oldValues: KeyMap<any> = {};
                const newNodes = this.state.nodes;
                const newForm = this.state.form;

                console.count("HOW MANY TIMES")
                const removeIds = (node: FormScaffold) => {
                    console.log(node)
                    if (node === undefined) {
                        return;
                    }
                    oldValues[node.id] = ids[node.id];
                    ids[node.id].setValue(undefined)

                    delete ids[node.id];
                    delete newNodes[node.id];
                    node.children.forEach((c: any) => removeIds(c));
                }

                removeIds(newNodes[nodeId]);

                // Remove node from form array
                const index = newForm.findIndex((item) => item.id === nodeId);
                if (index !== -1) {
                    newForm.splice(index, 1);
                }


                const allElements: Content[]= [];

                newForm.forEach(formScaffold => {
                    if (formScaffold.element) {
                        allElements.push(formScaffold.element);
                    }
                });

                console.log(allElements)

                const updateForm = generateForm(allElements, ids, inputCopy, {}, undefined, props.valueChanged, props.actionTriggered, actions);
                updateForm.forEach(c => processNode(c));



                this.setState({
                    nodes: newNodes,
                    form: updateForm,
                });

                return oldValues;
            },

            // removeFormNode: (nodeId: string): KeyMap<any> => {
            //     const oldValues: KeyMap<any> = {};
            //     const newNodes = { ...this.state.nodes };
            //     const newForm = [...this.state.form]; // Assuming form is an array of form elements
            //
            //     const removeIds = (node: FormScaffold) => {
            //         if (!node) {
            //             return;
            //         }
            //         oldValues[node.id] = ids[node.id]; // Assuming ids is initialized somewhere
            //         delete ids[node.id];
            //         delete newNodes[node.id];
            //         node.children.forEach((c: any) => removeIds(c));
            //     };
            //
            //     removeIds(newNodes[nodeId]);
            //
            //     // Remove node from form array if needed
            //     const index = newForm.findIndex((item) => item.id === nodeId);
            //     if (index !== -1) {
            //         newForm.splice(index, 1);
            //     }
            //     newForm.forEach(c => processNode(c));
            //
            //     this.setState({ nodes: newNodes, form: newForm });
            //
            //     return oldValues;
            // },

            // addFormContent: (content: (Input | Group | Row | Section | List | Collection<Input | Group | Row | Section | List>)[], nodeId: string) => {
            //     const newNodes = {...this.state.nodes};
            //     const oldValues: KeyMap<any> = {};
            //     const removeIds = (node: FormScaffold) => {
            //         oldValues[node.id] = ids[node.id];
            //         delete ids[node.id];
            //         delete newNodes[node.id];
            //         node.children.forEach((c: any) => removeIds(c));
            //     }
            //     const target = this.state.nodes[nodeId];
            //
            //
            //     target.children.forEach((child: any) => {
            //         removeIds(child);
            //     });
            //
            //     const processNode = (node: FormScaffold) => {
            //         newNodes[node.id] = node;
            //         node.children.forEach((c: any) => processNode(c));
            //     }
            //
            //
            //     const newForm = generateForm(content, ids, inputCopy, oldValues, undefined, props.valueChanged, props.actionTriggered, actions);
            //     newForm.forEach(c => processNode(c));
            //     target.children = newForm;
            //     let r = target;
            //     while (r) {
            //         r = {
            //             ...r,
            //             node: React.cloneElement(r.node, {}, r.children.map((c: any) => c.node))
            //         };
            //         newNodes[r.id] = r;
            //         console.log(r)
            //         if (r.parent === undefined) {
            //             break;
            //         }
            //         r = r.parent;
            //     }
            //     const form = this.state.form.map(f => f.id === r.id ? r : f);
            //     this.setState({nodes: newNodes, form});
            // },

            addFormContentLast: (content: (Input | Group | Row | Section | List | Collection<Input | Group | Row | Section | List>)[], bindingData?: InputValue) => {
                const newNodes = {...this.state.nodes};
                const oldValues: KeyMap<any> = {};
                // Function to recursively process nodes and update newNodes
                const processNode = (node: FormScaffold) => {
                    newNodes[node.id] = node;
                    node.children.forEach((c: any) => processNode(c));
                };
                // Generate new form elements and process them
                const newForm = generateForm(content, ids, (bindingData as any as T) || inputCopy, oldValues, undefined, props.valueChanged, props.actionTriggered, actions);
                newForm.forEach(c => processNode(c));

                // Update form state and nodes
                this.setState({nodes: newNodes, form: [...this.state.form, ...newForm]});
            },

            addFormContent: (content: (Input | Group | Row | Section | List | Collection<Input | Group | Row | Section | List>)[], nodeId: string, bindingData?: InputValue) => {
                const newNodes = {...this.state.nodes};
                const oldValues: KeyMap<any> = {};

                // Function to recursively process nodes and update newNodes
                const processNode = (node: FormScaffold) => {
                    newNodes[node.id] = node;
                    node.children.forEach((c: any) => processNode(c));
                };

                // Find the target node to append new content
                const target = newNodes[nodeId];

                // Generate new form elements and process them
                const newForm = generateForm(content, ids, (bindingData as any as T) || inputCopy, oldValues, undefined, props.valueChanged, props.actionTriggered, actions);
                newForm.forEach(c => processNode(c));

                // Function to insert new elements after a specified node ID
                const insertAfterNodeId = (nodes: FormScaffold[], insertAfterId: string, newElements: FormScaffold[]): FormScaffold[] => {
                    const result = [...nodes];
                    let found = false;
                    const traverse = (arr: FormScaffold[]) => {
                        for (let i = 0; i < arr.length; i++) {
                            const node = arr[i];
                            if (node.id === insertAfterId) {
                                result.splice(i + 1, 0, ...newElements);
                                found = true;
                                break;
                            }
                            if (node.children) {
                                traverse(node.children);
                                if (found) break;
                            }
                        }
                    };
                    traverse(result);
                    return result;
                };

                // Insert newForm elements after the target node within the form structure
                target.children = insertAfterNodeId(target.children, nodeId, newForm);

                // Update the form array in state to reflect the changes
                const updatedForm = insertAfterNodeId(this.state.form, nodeId, newForm);
                // Update state with new nodes and updated form
                this.setState({nodes: newNodes, form: updatedForm});
            },


            generateFormNode: (content: (Input | Group | Row | Section | List | Collection<Input | Group | Row | Section | List>)[], idPrefix: string, bindingData?: InputValue, state?: KeyMap<any>): FormScaffold[] => {
                const newNodes = {...this.state.nodes};
                const processNode = (node: FormScaffold) => {
                    newNodes[node.id] = node;
                    node.children.forEach((c: any) => processNode(c));
                }
                const newForm = generateForm(content, ids, (bindingData as any as T) || inputCopy, state || {...ids}, idPrefix, props.valueChanged, (id, action, form) => {
                    return props.actionTriggered(id, action, form);
                }, actions);
                newForm.forEach(c => processNode(c));
                this.setState({nodes: newNodes, form: newForm});
                return newForm;
            }
        }
        const form = generateForm(props.content, ids, inputCopy, {}, undefined, props.valueChanged, (id, action, form) => {
            return props.actionTriggered(id, action, form);
        }, actions);

        const allNodes: KeyMap<FormScaffold> = {};
        const processNode = (node: FormScaffold) => {
            allNodes[node.id] = node;
            node.children.forEach((c: any) => processNode(c));
        }
        form.forEach(c => processNode(c));
        this.state = {
            validation: false,
            form,
            nodes: allNodes,
            changed: false
        }
        if (this.props.actionTriggered) {
            this.props.actionTriggered('_FORM', 'load', actions);
        }
    }

    inputRef = React.createRef<HTMLInputElement>();

    public render() {

        return <Form ref={this.inputRef as any} key={this.props.id} noValidate validated={this.state.validation} onChange={() => this.setState({changed: true})} onSubmit={() => {
        }}>
            {this.state.form.map(n => n.node)}
        </Form>;
    }
}



import { IRedux } from '../../Interfaces/redux';
import { SHOW_LOADER, HIDE_LOADER } from '../actions/actionInterface'

const initialState: IRedux.Loader = {
    loading: false,
};


export default function load(state = initialState, action: IRedux.Action): IRedux.Loader {
    switch (action.type) {
        case 'SHOW_LOADER':
            return {
                loading: true,
            };
        case 'HIDE_LOADER':
            return {
                loading: false,
            };
        default:
            return state;
    }
};

